<!-- @format -->

<template>
  <header class="header">
    <div class="container">
      <div
        class="header-category"
        v-if="categoryHover"
        @mouseleave="categoryHover = false">
        <div class="header-category__box">
          <div class="header-category__btns">
            <button
              @mouseover="
                {
                  item.hover = true;
                  sub_category = item.sub_category;
                  category_id = item.id;
                }
              "
              @mouseleave="
                {
                  item.hover = false;
                }
              "
              class="header-category__btn"
              v-for="item in category"
              :key="item.id">
              {{ $t("lang") === "ru" ? item.name_rus : item.name_kaz }}
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  v-if="!item.hover"
                  d="M5 1L12 7.5L5 14"
                  stroke="#979EA6"
                  stroke-width="2"
                  stroke-linecap="round" />
                <path
                  v-else-if="item.hover"
                  d="M5 1L12 7.5L5 14"
                  stroke="#3ecd79"
                  stroke-width="2"
                  stroke-linecap="round" />
              </svg>
            </button>
          </div>
        </div>
        <div class="header-category__box">
          <div class="header-category__btns">
            <router-link
              :to="'/catalog/' + category_id + '/' + item.id"
              @click.native="$router.go()"
              @mouseover="item.hover = true"
              @mouseleave="item.hover = false"
              class="header-category__btn"
              v-for="item in sub_category"
              :key="item.id">
              {{ $t("lang") === "ru" ? item.name_rus : item.name_kaz }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="header-box">
        <router-link to="/">
          <img src="@/assets/icons/Logo.svg" alt="" />
        </router-link>
        <form action="" for="search" class="search" @submit.prevent="search">
          <search />
          <input
            type="text"
            :placeholder="$t('find')"
            class="search-input"
            v-model="searchText" />
        </form>
        <div class="header-right">
          <router-link to="/cart" class="header-right__btn">
            <cart :color="'color-orange'" />
            <p
              v-if="$store.state.cartCount > 0"
              class="header-right__btn__info">
              {{ $store.state.cartCount }}
            </p>
          </router-link>
          <router-link
            @click.native="$router.go()"
            to="/wishlist"
            @mouseover="bookmarkFill = '#F9AB50'"
            @mouseleave="bookmarkFill = 'none'"
            class="header-right__btn">
            <bookmark v-bind:fill="bookmarkFill" />
          </router-link>
          <button @click="user" class="header-right__btn">
            <user-icon />
          </button>
          <button class="header-right__geo">
            <pin />
            Алматы
          </button>
          <div class="header-border"></div>
          <div style="position: relative">
            <button
              @click="changeLang = !changeLang"
              class="header-right__lang"
              style="text-transform: uppercase">
              {{ $cookie.get("lang") || "ru" }}
            </button>
            <div class="lang" v-if="changeLang">
              <button
                @click="
                  {
                    $i18n.locale = 'ru';
                    $cookie.set('lang', 'ru');
                    $router.go();
                  }
                "
                class="lang-item">
                RU
              </button>
              <button
                @click="
                  {
                    $i18n.locale = 'kz';
                    $cookie.set('lang', 'kz');
                    $router.go();
                  }
                "
                class="lang-item">
                KZ
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="header-box">
        <div class="header-menu__btns">
          <button
            @mouseover="categoryHover = true"
            :class="
              categoryHover ? 'header-menu__btn hover' : 'header-menu__btn'
            ">
            <category />
            {{ $t("catalog") }}
          </button>
          <div class="header-border"></div>
          <router-link to="/shops" class="header-menu__btn">
            <shop />
            {{ $t("market") }}
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Search from "@/icon_components/Search.vue";
import Cart from "@/icon_components/Cart.vue";
import Bookmark from "@/icon_components/Bookmark.vue";
import UserIcon from "@/icon_components/UserIcon.vue";
import Pin from "@/icon_components/Pin.vue";
import Category from "@/icon_components/Category.vue";
import Shop from "@/icon_components/Shop.vue";
import api from "@/api/api";

export default {
  name: "AppHeader",
  components: {
    Search,
    Cart,
    Bookmark,
    UserIcon,
    Pin,
    Category,
    Shop,
  },
  data() {
    return {
      category_id: 0,
      sub_category: null,
      changeLang: false,
      bookmarkFill: "none",
      chevronHover: false,
      categoryHover: false,
      category: [],
      count: this.$store.state.cartCount,
      searchText: this.$route.query.search || "",
      searchRes: "",
    };
  },
  methods: {
    user() {
      if (this.$cookie.get("access")) {
        this.$router.push("/cabinet");
      } else {
        this.$router.push("/login");
      }
    },
  },
  watch: {
    searchText: {
      handler() {
        this.$router.push({ path: "/", query: { search: this.searchText } });
      },
    },
  },
  created() {
    api.getCart().then((res) => {
      localStorage.setItem("cart", JSON.stringify(res.data.cartitems));
      localStorage.setItem("count", res.data.cartitems.length);
      this.$store.dispatch("increment");
    });
    if (this.$cookie.get("searchText")) {
      this.searchText = this.$cookie.get("searchText");
    }
    api.getCategory().then((res) => {
      this.category = res.data.results;
      this.sub_category = this.category[0].sub_category;
    });
  },
};
</script>

<style>
.header {
  padding: 20px 0;
  width: 100%;
  box-shadow: 0px 4px 58px 15px rgba(139, 158, 186, 0.1);
}
.header-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  column-gap: 10px;
}
.header-box:last-child {
  margin-bottom: 0px;
}
.search {
  padding: 13px 26px;
  border: 1px solid #d9d9d9;
  border-radius: 30px;
  display: flex;
  align-items: center;
  column-gap: 15px;
  max-width: 659px;
  width: 100%;
}
.search-input::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #979ea6;
}
.search-input {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
  width: 100%;
}
.header-right {
  display: flex;
  align-items: center;
  column-gap: 19px;
}
.header-right__btn {
  background: #f2f2f2;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.header-right__geo {
  display: flex;
  align-items: flex-end;
  column-gap: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
}
.header-border {
  height: 33px;
  width: 1px;
  background-color: #d5dde3;
}
.header-right__lang {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
  margin-right: 15px;
}
.header-menu__btns {
  display: flex;
  align-items: center;
  column-gap: 23px;
}
.header-menu__btn {
  display: flex;
  align-items: flex-end;
  column-gap: 14px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
  transition: all;
  transition-duration: 200ms;
}
.hover {
  color: #3ecd79;
}
.color-orange {
  stroke: #f9ab50;
}
.header-category {
  position: absolute;
  top: 110%;
  left: 0;
  z-index: 15;
  display: flex;
  column-gap: 1px;
}
.header-category__box {
  padding: 28px 27px 18px 27px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.2);
  border-radius: 20px;
}
.header-category__btns {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 341px;
}
.header-category__btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 170%;
  color: #3a4753;
  transition: all;
  transition-duration: 200ms;
}
.header-category__btn:hover {
  color: #3ecd79;
}
.header-right__btn__info {
  width: 19px;
  height: 19px;
  position: absolute;
  background: #f9ab50;
  border-radius: 50%;
  top: 2px;
  right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.lang {
  position: absolute;
  left: 30%;
  top: 130%;
  transform: translateX(-50%);
}

.lang-item {
  width: 100%;
  padding: 10px;
  border: 1px solid #f4f4f4;
  z-index: 20;
}

.lang-item:last-child {
  border-top: none;
}
</style>
