<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 5L4.77143 7.57143M4.77143 7.57143L7.64335 17.1445C7.77024 17.5675 8.15957 17.8571 8.60117 17.8571H20C21.1046 17.8571 22 16.9617 22 15.8571V10.1429C22 8.7227 20.8487 7.57143 19.4286 7.57143H4.77143ZM19.4286 23C18.7185 23 18.1429 22.4244 18.1429 21.7143C18.1429 21.0042 18.7185 20.4286 19.4286 20.4286C20.1387 20.4286 20.7143 21.0042 20.7143 21.7143C20.7143 22.4244 20.1387 23 19.4286 23ZM9.14286 21.7143C9.14286 21.0042 9.71849 20.4286 10.4286 20.4286C11.1387 20.4286 11.7143 21.0042 11.7143 21.7143C11.7143 22.4244 11.1387 23 10.4286 23C9.71849 23 9.14286 22.4244 9.14286 21.7143Z"
      stroke="#F9AB50"
      stroke-width="2"
      stroke-linecap="round"
      :class="color"
    />
  </svg>
</template>

<script>
export default {
  name: "AppCartIcon",
  props: ["color"],
};
</script>

<style></style>
