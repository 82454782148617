<!-- @format -->

<template>
  <div>
    <div v-if="!isSms">
      <div class="cabinet-info">
        <div class="cabinet-info__half">
          <label for="phone" class="cabinet-info__label">
            <p class="cabinet-info__p">{{ $t("nowPhone") }}</p>
            <input
              disabled
              type="text"
              id="phone"
              name="phone"
              class="cabinet-info__input__full"
              placeholder="+7 | _ _ _  -  _ _  -  _ _"
              v-mask="'+7 (###) ###-##-##'"
              required
              v-model="old_phone" />
          </label>
        </div>
        <div class="cabinet-info__half">
          <label for="phone" class="cabinet-info__label">
            <p class="cabinet-info__p">{{ $t("newPhone") }}</p>
            <input
              type="text"
              id="phone"
              name="phone"
              class="cabinet-info__input__full"
              placeholder="+7 | _ _ _  -  _ _  -  _ _"
              v-mask="'+7 (###) ###-##-##'"
              required
              v-model="new_phone" />
          </label>
        </div>
      </div>
      <button @click="handleSms" class="cabinet-info__submit">
        {{ $t("save") }}
      </button>
      <span class="error" v-if="errorEqual" style="text-align: end">{{
        errorEqual
      }}</span>
      <span class="error" v-if="errorNumber" style="text-align: end">{{
        errorNumber
      }}</span>
    </div>
    <div class="cabinet-change__phone" v-if="isSms">
      <div class="login-forget">
        <div class="login-forget__texts">
          <p class="login-sms__text">{{ $t("noCode") }}</p>
          <button class="login-sms__timer" v-if="timer > 0">
            {{ $t("codeTimer") }} {{ timer }}
          </button>
          <button @click="handleSms" class="login-sms__timer" v-else>
            {{ $t("codeAgain") }}
          </button>
        </div>
        <label for="sms" class="login-label login-forget__label">
          <input
            type="text"
            id="sms"
            :placeholder="$t('sms')"
            class="login-input"
            v-model="smsInput" />
        </label>
        <button @click="handleChange" class="login-sms__btn">
          {{ $t("confirm") }}
        </button>
        <span class="error" style="text-align: center">
          {{ smsFalse }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AppCabinetChange",
  props: ["data"],
  data() {
    return {
      old_phone: "",
      new_phone: "",
      isSms: false,
      errorEqual: "",
      smsInput: "",
      smsFalse: "",
      timer: 0,
      errorNumber: "",
    };
  },
  mounted() {
    this.old_phone = this.data.username;
  },
  watch: {
    new_phone: {
      handler() {
        this.errorEqual = "";
        this.errorNumber = "";
        this.smsFalse = "";
      },
    },
    timer: {
      handler() {
        if (this.timer > 0) {
          setTimeout(() => {
            this.timer--;
          }, 1000);
        }
      },
    },
  },
  methods: {
    handleSms() {
      if (this.old_phone === this.new_phone) {
        this.errorEqual = this.$t("newPhoneError");
      } else if (this.new_phone) {
        api.sendSMS(this.old_phone).then(() => {
          this.isSms = !this.isSms;
          this.timer = 60;
        });
      } else {
        this.errorEqual = this.$t("newPhoneError2");
      }
    },
    handleChange() {
      api
        .verifySMS({ phone: this.old_phone, code: this.smsInput })
        .then((res) => {
          if (res.data.success) {
            api
              .changeUsername({ new_phone: this.new_phone })
              .then(() => {
                this.$emit("reload");
                this.isSms = false;
              })
              .catch((err) => {
                this.isSms = false;
                if (err.response.data.error) {
                  this.errorNumber = err.response.data.error;
                }
              });
          } else {
            this.smsFalse = this.$t("codeError");
          }
        });
    },
  },
};
</script>

<style scoped>
.cabinet-change__phone {
  padding-bottom: 30px;
  border-bottom: 2px solid #d9d9d9;
}

.login-forget {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}
</style>
