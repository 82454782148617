<!-- @format -->

<template>
  <div>
    <cabinet-modal
      :data="changeAddress"
      :id="user_id"
      :modalData="modalData"
      @isModal="changeModal" />
    <div @click="isLeave = !isLeave" class="modal-back" v-if="isLeave">
      <div @click.stop class="modal">
        <div class="leave-block">
          <div class="leave-text">{{ $t("areYouOk") }}</div>
          <div class="leave-btns">
            <button @click="handleLeave" class="leave-btn">
              {{ $t("yes") }}
            </button>
            <button @click="isLeave = !isLeave" class="leave-btn">
              {{ $t("no") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <p class="path">{{ $t("mainPage") }}/ {{ $t("cabinet") }}</p>
      <div class="mobile-cart__header">
        <h1 class="mobile-h1">{{ $t("profile") }}</h1>
      </div>
      <div class="box">
        <h2 class="h2">{{ $t("cabinet") }}</h2>
        <div class="cabinet">
          <div class="cabinet-tabs">
            <input
              type="radio"
              name="tabs"
              id="tab1"
              :checked="tabs === 1"
              style="width: 0; display: none" />
            <label @click="tabs = 1" for="tab1" class="cabinet-tab">
              {{ $t("profile") }}
            </label>
            <input
              type="radio"
              name="tabs"
              id="tab5"
              :checked="tabs === 5"
              style="width: 0; display: none" />
            <label @click="tabs = 5" for="tab5" class="cabinet-tab">
              {{ $t("changePhone") }}
            </label>
            <input
              type="radio"
              name="tabs"
              id="tab2"
              :checked="tabs === 2"
              style="width: 0; display: none" />
            <label @click="tabs = 2" for="tab2" class="cabinet-tab">
              {{ $t("storyOrders") }}
            </label>
            <input
              type="radio"
              name="tabs"
              id="tab3"
              :checked="tabs === 3"
              style="width: 0; display: none" />
            <label @click="tabs = 3" for="tab3" class="cabinet-tab">
              {{ $t("myAddresses") }}
            </label>
            <input
              type="radio"
              name="tabs"
              id="tab4"
              style="width: 0; display: none"
              :checked="tabs === 4" />
            <label @click="tabs = 4" for="tab4" class="cabinet-tab">
              {{ $t("supportPodderjka") }}
            </label>
            <input
              type="radio"
              name="tabs"
              id="tab6"
              style="width: 0; display: none"
              :checked="tabs === 6" />
            <label @click="tabs = 6" for="tab6" class="cabinet-tab">
              {{ $t("storyReturns") }}
            </label>
            <input
              type="radio"
              name="tabs"
              id="tab6"
              style="width: 0; display: none" />
            <label @click="isLeave = !isLeave" for="tab6" class="cabinet-tab">
              {{ $t("leave") }}
            </label>
          </div>
          <info :data="data" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Info from "@/components/cabinet/Info.vue";
import api from "@/api/api";
export default {
  name: "AppCabinet",
  components: {
    Info,
  },
  data() {
    return {
      tabs: 1,
      data: null,
      user_id: "",
      changeAddress: null,
      isLeave: false,
      modalData: {
        modal: false,
        type: "",
      },
    };
  },
  watch: {
    tabs: {
      handler() {
        this.$router.push(`/cabinet?tab=${this.tabs}`);
      },
    },
  },
  methods: {
    page() {
      this.$router.push("/");
    },
    changeModal(data) {
      this.modalData.modal = data.modal;
      this.modalData.type = data.type;
      if (data) {
        this.changeAddress = data;
      }
      if (data.status) {
        this.getUserInformation();
      }
    },
    getUserInformation() {
      api.getUser().then((res) => {
        console.log(res);
        this.data = res.data;
        this.user_id = res.data.id;
      });
    },
    handleLeave() {
      this.$cookie.delete("access");
      this.$cookie.delete("refresh");
      this.$cookie.delete("favourite");
      localStorage.removeItem("cart");
      localStorage.removeItem("count");
      location.reload();
    },
    reload() {
      this.getUserInformation();
    },
  },
  created() {
    if (this.$route.query.tab) {
      this.tabs = parseInt(this.$route.query.tab);
    }
    if (!this.$cookie.get("access")) {
      this.$router.push("/login");
    }
    this.getUserInformation();
    let temp = new Date();
    console.log(temp, "date");
  },
};
</script>
