<!-- @format -->

<template>
  <div>
    <div class="modal-back" v-if="isModal" @click="isModal = false">
      <div class="modal" @click.stop>
        <button @click="isModal = false" class="modal-close">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 1.5L13.5 13.5M1.5 13.5L13.5 1.5" stroke="black" />
          </svg>
        </button>
        <h2 class="modal-h2">{{ $t("weWillAnswer") }}</h2>
      </div>
    </div>
    <div class="container">
      <p class="path">{{ $t("mainPage") }}/ {{ $t("vacancy") }}</p>
      <div class="mobile-cart__header">
        <button @click="page" class="mobile-arrow">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: rotate(180deg)">
            <path
              d="M5 1L12 7.5L5 14"
              stroke="#F9AB50"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </button>
        <h1 class="mobile-h1">{{ $t("footer.contacts") }}</h1>
      </div>
      <div class="box">
        <div class="contacts">
          <div class="contacts-left">
            <h2 class="h2">{{ $t("footer.contacts") }}</h2>
            <p class="contacts-left__text">
              {{ $t("aLotText3") }}
            </p>
            <div class="contacts-left__flex">
              <div class="contacts-left__box">
                <h4 class="contacts-left__h4">Телефоны</h4>
                <p
                  class="contacts-left__p"
                  v-for="phone in contacts.phone_numbers"
                  :key="phone.phone_number">
                  {{ phone.phone_number }}
                </p>
              </div>
              <div class="contacts-left__box">
                <h4 class="contacts-left__h4">{{ $t("workHours") }}</h4>
                <p class="contacts-left__p">
                  {{ contacts.date_from.split("T")[1].split(":")[0] }}:{{
                    contacts.date_from.split("T")[1].split(":")[1]
                  }}
                  -
                  {{ contacts.date_to.split("T")[1].split(":")[0] }}:{{
                    contacts.date_to.split("T")[1].split(":")[1]
                  }}
                </p>
              </div>
              <div class="contacts-left__box">
                <h4 class="contacts-left__h4">Email</h4>
                <p class="contacts-left__p">{{ contacts.email }}</p>
              </div>
              <div class="contacts-left__box">
                <h4 class="contacts-left__h4">Адрес</h4>
                <p class="contacts-left__p">
                  {{ contacts.address }}
                </p>
              </div>
            </div>
          </div>
          <form @submit.prevent="postWriteUs" class="contacts-right">
            <h2 class="h2" style="margin-bottom: 20px">Написать нам</h2>
            <h2 class="mobile-h2">Написать нам</h2>
            <label for="name" class="contacts-right__label">
              <span class="contacts-right__label__name"> Ваше Имя </span>
              <input
                type="text"
                id="name"
                class="contacts-right__input"
                v-model="form.name" />
            </label>
            <label for="email" class="contacts-right__label">
              <span class="contacts-right__label__name"> Email </span>
              <input
                type="email"
                id="email"
                required
                class="contacts-right__input"
                v-model="form.email" />
            </label>
            <label for="email" class="contacts-right__label">
              <span class="contacts-right__label__name"> Сообщение </span>
              <textarea
                v-model="form.message"
                type="text"
                id="email"
                class="contacts-right__input"
                cols="30"
                rows="5" />
            </label>
            <button type="submit" class="contacts-right__btn">
              {{ $t("sendSms") }}
            </button>
            <span v-if="formError" class="error" style="text-align: start">
              {{ $t("empty") }}
            </span>
          </form>
        </div>
      </div>
    </div>
    <Map :shops="shops" />
  </div>
</template>

<script>
import Map from "@/components/map/Map.vue";
import api from "@/api/api";
export default {
  components: { Map },
  name: "AppContacts",
  data() {
    return {
      isModal: false,
      contacts: "",
      shops: "",
      formError: false,
      form: {
        name: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    postWriteUs() {
      api
        .postWriteUs(this.form)
        .then((res) => {
          this.form = {
            name: "",
            email: "",
            message: "",
          };
          if (res.status) {
            this.isModal = true;
          }
        })
        .catch(() => {
          this.formError = true;
        });
    },
    page() {
      this.$router.go(-1);
    },
  },
  created() {
    api.getContacts().then((res) => {
      this.contacts = res.data.results[0];
    });
    api.getMarket().then((res) => {
      this.shops = res.data.results;
    });
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.formError = false;
      },
    },
  },
};
</script>

<style>
.contacts {
  display: flex;
  justify-content: space-between;
  column-gap: 58px;
  margin-bottom: 80px;
}

.contacts-left {
  max-width: 45%;
}

.contacts-left__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 195%;
  color: #3a4753;
}

.contacts-left__box {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin: 35px 0px;
}

.contacts-left__h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 170%;
  text-transform: uppercase;
  color: #3a4753;
}

.contacts-left__p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 232.4%;
  color: #3a4753;
}

.contacts-left__flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contacts-right {
  max-width: 594px;
  width: 100%;
  padding: 35px;
  background: #f2f2f2;
  border-radius: 20px;
}

.contacts-right__label {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 20px;
}

.contacts-right__input {
  width: 100%;
  border: 1px solid #979ea6;
  border-radius: 25px;
  outline: none;
  background: transparent;
  padding: 15px 10px;
  max-width: 100%;
  min-width: 100%;
}

.contacts-right__input {
  color: #3a4753;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.contacts-right__label__name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-left: 10px;
  color: #3a4753;
}

.contacts-right__btn {
  margin-top: 20px;
  padding: 15px 95px;
  background: #3ecd79;
  border-radius: 25px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.mobile-h2 {
  display: none;
}

@media screen and (min-width: 640px) and (max-width: 1024px) {
  .contacts {
    margin-bottom: 50px;
    flex-direction: column;
    row-gap: 42px;
  }
  .contacts-left {
    max-width: 100%;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .contacts {
    margin-bottom: 30px;
  }
  .contacts {
    margin-bottom: 50px;
    flex-direction: column;
    row-gap: 42px;
  }
  .contacts-left {
    max-width: 100%;
  }
  .contacts-right {
    border-radius: 10px;
    padding: 16px;
  }
  .mobile-h2 {
    display: block;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #3a4753;
  }
  .contacts-right__btn {
    padding: 12px 27px;
    margin: 0 auto;
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #ffffff;
  }
  .contacts-left__text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #363845;
  }
  .contacts-left__h4 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #3a4753;
  }

  .contacts-left__p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #3a4753;
  }
}
</style>
