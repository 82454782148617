<!-- @format -->

<template>
  <div @click="$router.push('/card/' + itemMenu.id)">
    <div class="menu-box__item" v-if="isMobile">
      <div
        class="menu-box__img"
        :style="`background:url(${itemMenu.product_image}) center no-repeat;background-size: cover;
        `">
        <div class="menu-box__btns">
          <div
            v-if="itemMenu.status_text"
            class="menu-box__status"
            :style="'background-color:' + itemMenu.status_color">
            {{ itemMenu.status_text }}
          </div>
          <div v-else class="menu-box__status">
            {{ itemMenu.status_text }}
          </div>
          <button
            @click.stop
            @click="handleFavourite(itemMenu.id)"
            @mouseleave="bookmarkFill = 'none'"
            class="menu-box__bookmark">
            <bookmark :fill="fill ? fill : bookmarkFill" />
          </button>
        </div>
      </div>
      <div class="menu-box__info">
        <div class="menu-box__name">
          {{ $t("lang") === "kz" ? itemMenu.name_kaz : itemMenu.name_rus }}
        </div>
        <div class="menu-box__weight">
          {{
            itemMenu.weight > 1000
              ? Math.round(itemMenu.weight / 1000)
              : itemMenu.weight
          }}
          {{ itemMenu.weight > 1000 ? "Кг" : "г" }}
        </div>
        <div class="menu-box__container">
          <div class="menu-box__price">
            <div v-if="itemMenu.discount_price > 0">
              <p class="menu-box__old__price">{{ itemMenu.price }} ₸</p>
              <p class="menu-box__new__price">
                {{ itemMenu.discount_price }} ₸
              </p>
            </div>
            <p v-else class="menu-box__price__default">
              {{ itemMenu.price }} ₸ за кг
            </p>
          </div>
          <button class="menu-box__cart">
            <cart v-bind:color="'color-white'" />
          </button>
        </div>
        <div class="menu-box__container__mobile">
          <p class="menu-box__price__mobile another_back">
            {{ itemMenu.price }} ₸/кг
          </p>
          <button
            v-if="!isCart"
            @click.stop
            @click="handleCart"
            class="menu-box__btn__mobile">
            {{ $t("toCart") }}
          </button>
        </div>
      </div>
      <div class="menu-box__container__cart" v-if="isCart">
        <button
          @click.stop
          @click="handleSet('minus')"
          class="menu-box__container__counter">
          <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8H14" stroke="#3A4753" />
          </svg>
        </button>
        <p class="menu-box__container__count">{{ count }} шт</p>
        <button
          @click.stop
          @click="handleSet('plus')"
          class="menu-box__container__counter">
          <svg
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 0.5V13.5M0 7H13" stroke="#3A4753" />
          </svg>
        </button>
      </div>
    </div>
    <div class="menu-box__item" v-else-if="!isMobile">
      <div
        class="menu-box__img"
        :style="`background:url(${itemMenu.product_image}) center no-repeat; 
        background-size: cover;
        `">
        <div class="menu-box__btns">
          <div
            v-if="itemMenu.status_text"
            class="menu-box__status"
            :style="'background-color:' + itemMenu.status_color">
            {{ itemMenu.status_text }}
          </div>
          <div v-else class="menu-box__status">
            {{ itemMenu.status_text }}
          </div>
          <button
            @click.stop
            @click="handleFavourite(itemMenu.id)"
            @mouseleave="bookmarkFill = 'none'"
            class="menu-box__bookmark">
            <bookmark :fill="fill ? fill : bookmarkFill" />
          </button>
        </div>
      </div>
      <div class="menu-box__info">
        <div class="menu-box__name">
          {{ $t("lang") === "kz" ? itemMenu.name_kaz : itemMenu.name_rus }}
        </div>
        <div class="menu-box__weight">
          {{
            itemMenu.weight > 1000
              ? Math.round(itemMenu.weight / 1000)
              : itemMenu.weight
          }}
          {{ itemMenu.weight > 1000 ? "Кг" : "г" }}
        </div>
        <div class="menu-box__container" v-if="!isCart">
          <div class="menu-box__price">
            <div v-if="itemMenu.discount_price > 0">
              <p class="menu-box__old__price">{{ itemMenu.price }} ₸</p>
              <p class="menu-box__new__price">
                {{ itemMenu.discount_price }} ₸
              </p>
            </div>
            <p v-else class="menu-box__price__default">
              {{ itemMenu.price }} ₸ за кг
            </p>
          </div>
          <button class="menu-box__cart">
            <cart v-bind:color="'color-white'" />
          </button>
        </div>
        <div class="menu-box__container__mobile">
          <p class="menu-box__price__mobile another_back">
            {{ itemMenu.price }} ₸/кг
          </p>
          <button
            v-if="!isCart"
            @click.stop
            @click="handleCart"
            class="menu-box__btn__mobile">
            {{ $t("toCart") }}
          </button>
        </div>
      </div>
      <div class="menu-box__container__cart" v-if="isCart">
        <button
          @click.stop
          @click="handleSet('minus')"
          class="menu-box__container__counter">
          <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8H14" stroke="#3A4753" />
          </svg>
        </button>
        <p class="menu-box__container__count">{{ count }} шт</p>
        <button
          @click.stop
          @click="handleSet('plus')"
          class="menu-box__container__counter">
          <svg
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 0.5V13.5M0 7H13" stroke="#3A4753" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Cart from "@/icon_components/Cart.vue";
import Bookmark from "@/icon_components/Bookmark.vue";
import api from "@/api/api";
export default {
  components: { Cart, Bookmark },
  name: "AppOneOfList",
  props: ["itemMenu", "favourite"],
  data() {
    return {
      fill: "none",
      id: 1,
      bookmarkFill: "none",
      isMobile: false,
      count: 1,
      isCart: false,
      cartId: -1,
    };
  },
  watch: {
    itemMenu: {
      handler() {},
    },
  },
  mounted() {
    this.checkFavourite();
    this.checkCart();
    this.resize();
    window.addEventListener("resize", this.resize, { passive: true });
  },
  methods: {
    async checkFavourite() {
      let temp = await JSON.parse(this.$cookie.get("favourite"));
      if (temp) {
        temp.forEach((element) => {
          if (element === this.itemMenu.id) {
            this.fill = "#F9AB50";
            console.log(element, this.itemMenu.id, "ideas");
          }
        });
      }
    },
    async checkCart() {
      const temp = await JSON.parse(localStorage.getItem("cart"));
      if (temp) {
        temp.forEach((item) => {
          if (item.product.id === this.itemMenu.id) {
            this.isCart = true;
            this.count = item.quantity;
            this.cartId = item.id;
          }
        });
      }
    },
    handleSet(type) {
      if (type === "plus") {
        api.putCart(this.cartId, { quantity: this.count + 1 }).then(() => {
          this.count++;
          api.getCart().then((res) => {
            localStorage.setItem("cart", JSON.stringify(res.data.cartitems));
            localStorage.setItem("count", res.data.cartitems.length);
            this.$store.dispatch("increment");
          });
        });
      } else if (type === "minus" && this.count > 1) {
        api.putCart(this.cartId, { quantity: this.count - 1 }).then(() => {
          this.count--;
          api.getCart().then((res) => {
            localStorage.setItem("cart", JSON.stringify(res.data.cartitems));
            localStorage.setItem("count", res.data.cartitems.length);
            this.$store.dispatch("increment");
          });
        });
      }
    },
    handleCart() {
      console.log(this.itemMenu.id, "id");
      api.addCart({ product: this.itemMenu.id, quantity: 1 }).then(() => {
        this.isCart = true;
        api.getCart().then((res) => {
          localStorage.setItem("cart", JSON.stringify(res.data.cartitems));
          localStorage.setItem("count", res.data.cartitems.length);
          this.$store.dispatch("increment");
          this.checkCart();
        });
      });
    },
    resize() {
      if (window.innerWidth < 640) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    handleFavourite() {
      if (this.bookmarkFill === "none" && this.fill === "none") {
        api
          .postFavourite({ product_id: this.itemMenu.id })
          .then((res) => {
            this.bookmarkFill = "#F9AB50";
            this.fill = "#F9AB50";
            this.$emit("reload");
            if (res.status === 200) {
              alert(this.$t("addFavorite"));
            }
            this.checkFavourite();
          })
          .catch(() => {
            this.$emit("reload");
            this.bookmarkFill = "none";
            this.checkFavourite();
            alert(this.$t("needRegistr"));
          });
      } else {
        api.deleteFavourite(this.itemMenu.id).then(() => {
          this.$emit("reload");
          this.fill = "none";
          alert(this.$t("deletefavorite"));
        });
      }
    },
  },
};
</script>

<style>
.menu-box__item {
  border-radius: 20px;
  position: relative;
  width: 219px;
  height: 248px;
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.1);
  background: #ffffff;
  overflow: hidden;
}
.menu-box__img {
  height: 50%;
  width: 100%;
  background: url("../assets/images/image 115.png") center;
  padding: 8px 13px 8px 11px;
}
.menu-box__btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.new {
  background: #ffcc51;
}
.bio {
  background: #70e87c;
}
.menu-box__status {
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #3a4753;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-box__bookmark {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 10px;
}
.menu-box__info {
  height: 50%;
  padding: 9px 16px 15px 12px;
  position: relative;
}
.menu-box__name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3a4753;
}
.menu-box__weight {
  margin-top: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #979ea6;
}
.menu-box__container {
  position: absolute;
  bottom: 15px;
  left: 16px;
  width: 87%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu-box__price__default {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a4753;
}
.menu-box__cart {
  width: 38px;
  height: 36px;
  border-radius: 20px;
  background: #3ecd79;
}
.menu-box__old__price {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: line-through;
  color: #3a4753;
}
.menu-box__new__price {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #e76c6e;
}
.menu-box__price__mobile {
  background: #3fa7da;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  padding: 4px 3px;
  width: fit-content;
  margin-top: 8px;
}
.menu-box__container__mobile {
  display: none;
}
.another_back {
  background: #f0f0f4;
  color: #3a4753;
}
@media screen and (min-width: 0px) and (max-width: 640px) {
  .menu-box__item {
    width: 100%;
    height: 266px;
  }
  .menu-box__name {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 32px;
  }
  .menu-box__container {
    display: none;
  }
  .menu-box__img {
    height: 70px;
  }
  .menu-box__status {
    width: 28px;
    height: 28px;
    font-size: 10px;
    border-radius: 5px;
  }
  .menu-box__info {
    padding: 8px;
  }
  .menu-box__bookmark {
    width: 28px;
    height: 28px;
    border-radius: 5px;
  }
  .menu-box__container__mobile {
    display: block;
  }
  .menu-box__status__mobile {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    margin: 4px 0px 8px 0px;
    color: #3fa7da;
  }
  .menu-box__btn__mobile_2 {
    padding: 8px 0px;
    background: #3ecd79;
    border-radius: 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    height: fit-content;
    margin-top: 40%;
    width: 100%;
  }
  .menu-box__info {
    width: 100%;
  }
}
</style>
