<!-- @format -->

<template>
  <div>
    <header class="header-faq">
      <p class="path">{{ $t("faq") }}</p>
      <div class="mobile-cart__header">
        <button @click="page" class="mobile-arrow">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: rotate(180deg)">
            <path
              d="M5 1L12 7.5L5 14"
              stroke="#F9AB50"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </button>
        <h1 class="mobile-h1">{{ $t("faq") }}</h1>
      </div>
    </header>
    <div class="container">
      <div class="faqs-flex">
        <router-link
          :to="'/support/subfaq/?faq=' + JSON.stringify(faq)"
          class="faqs-one"
          v-for="faq in faqs"
          :key="faq.id">
          {{ faq.subtitle }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AppFaq",
  data() {
    return {
      faqs: "",
    };
  },
  created() {
    api.getQuestionById(this.$route.params.id).then((res) => {
      console.log(res.data);
      this.faqs = res.data.subfaqs;
    });
  },
  methods: {
    page() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.header-faq {
  background: #e2e7ee;
  padding: 15px;
  padding-top: 0px;
}

.faqs-flex {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
}

.faqs-one {
  width: 100%;
  color: #3a4753;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  padding: 8px 5px;
  border-radius: 10px;
  background: #f0f4f9;
  text-align: start;
}
</style>
