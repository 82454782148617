<!-- @format -->

<template>
  <div>
    <div v-if="reviewsMobile">
      <div class="container"></div>
    </div>
    <div v-else>
      <div class="container">
        <h1 class="mobile-category__h1">
          {{ $t("lang") === "kz" ? product.name_kaz : product.name_rus }}
        </h1>
        <p class="mobile-category__mass">{{ product.weight }} гр</p>
        <p class="mobile-category__price">{{ product.price }} ₸ /шт</p>
        <div class="mobile-category__flex">
          <div class="mobile-category__basket">
            <button @click="handleSet('minus')">
              <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M1 8H14" stroke="#3ecd79" />
              </svg>
            </button>
            {{ count === 0 ? "В корзину" : count }}
            <button @click="handleSet('plus')">
              <svg
                width="13"
                height="14"
                viewBox="0 0 13 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 0.5V13.5M0 7H13" stroke="#3ecd79" />
              </svg>
            </button>
          </div>
          <button @click="handleFavourite()" class="mobile-category__like">
            {{ $t("pickNice") }}
          </button>
        </div>
        <div
          class="mobile-category__info"
          v-for="item in product.product_characteristic"
          :key="item.id">
          <h3 class="mobile-category__h3">
            {{ $t("lang") === "kz" ? item.name_kaz : item.name_rus }}:
          </h3>
          <p class="mobile-category__p">
            {{ $t("lang") === "kz" ? item.value_kaz : item.value_rus }}
            {{ $t("lang") === "kz" ? item.measure_kaz : item.measure_rus }}
          </p>
        </div>
      </div>
      <button class="mobile-category__comments" @click="$emit('reviewsOpen')">
        {{ $t("allReviews") }}
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 1L12 7.5L5 14"
            stroke="#3ecd79"
            stroke-width="2"
            stroke-linecap="round" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AppCardMobile",
  props: ["product", "reviewsMobile", "reviewUsed"],
  data() {
    return {
      count: 0,
      cartId: -1,
    };
  },
  methods: {
    async checkCart() {
      const temp = await JSON.parse(localStorage.getItem("cart"));
      if (temp) {
        temp.forEach((item) => {
          if (item.product.id === this.product.id) {
            this.count = item.quantity;
            this.cartId = item.id;
          }
        });
      }
    },
    handleCart() {
      if (this.count > 0) {
        api
          .addCart({ quantity: this.count, product: this.product.id })
          .then(() => {
            this.isCart = true;
            api.getCart().then((res) => {
              localStorage.setItem("cart", JSON.stringify(res.data.cartitems));
              localStorage.setItem("count", res.data.cartitems.length);
              this.$store.dispatch("increment");
              this.checkCart();
            });
          });
      } else {
        this.countErr = this.$t("countError");
      }
    },
    handleSet(type) {
      if (type === "plus") {
        api.putCart(this.cartId, { quantity: this.count + 1 }).then(() => {
          this.count++;
          api.getCart().then((res) => {
            localStorage.setItem("cart", JSON.stringify(res.data.cartitems));
            localStorage.setItem("count", res.data.cartitems.length);
            this.$store.dispatch("increment");
          });
        });
      } else if (type === "minus" && this.count > 1) {
        api.putCart(this.cartId, { quantity: this.count - 1 }).then(() => {
          this.count--;
          api.getCart().then((res) => {
            localStorage.setItem("cart", JSON.stringify(res.data.cartitems));
            localStorage.setItem("count", res.data.cartitems.length);
            this.$store.dispatch("increment");
          });
        });
      }
    },
    handleFavourite() {
      api
        .postFavourite({ product_id: this.product.id })
        .then((res) => {
          this.bookmarkFill = "#F9AB50";
          this.fill = "#F9AB50";
          if (res.status === 200) {
            alert(this.$t("addFavorite"));
          }
          this.checkFavourite();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.bookmarkFill = "none";
            alert(this.$t("needRegistr"));
          }
        });
    },
  },
  created() {
    this.checkCart();
  },
};
</script>

<style>
.mobile-category__h1 {
  margin-top: 32px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 170%;
  text-transform: uppercase;
  color: #3a4753;
}
.mobile-category__mass {
  margin-top: 2px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #979ea6;
}
.mobile-category__price {
  margin: 21px 0;
  width: fit-content;
  padding: 3px 9px;
  background: #3fa7da;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.mobile-category__flex {
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin: 0 auto;
  margin-bottom: 32px;
}
.mobile-category__like {
  background: #f7931e;
  border-radius: 25px;
  padding: 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  height: 48px;
  max-width: 173px;
  width: 100%;
}
.mobile-category__info {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #979ea6;
}
.mobile-category__basket {
  max-width: 173px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 21px;
  border: 1px solid #3ecd79;
  border-radius: 25px;
  height: 48px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3ecd79;
}
.mobile-category__h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 170%;
  text-transform: uppercase;
  color: #3a4753;
}
.mobile-category__p {
  margin-top: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 148.4%;
  color: #3a4753;
}
.mobile-category__comments {
  margin-top: 32px;
  width: 100%;
  text-align: start;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 32px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #3ecd79;
}

.header-mobile__bg {
  background: #e2e7ee;
  height: 200px;
}
</style>
