<!-- @format -->

<template>
  <div class="main">
    <div
      v-if="isMobile"
      class="modal-back"
      style="background: rgba(33, 38, 45, 0.73)"
      :style="isModal ? 'display:block; z-index:15;' : 'display:none'"
      @click="isModal = !isModal"></div>
    <div
      v-if="isMobile"
      class="modal-down"
      @click.stop
      :style="
        isModal ? 'transform:translate(0)' : 'transform:translateY(100%)'
      ">
      <div class="modal-down__scroll">
        <div v-if="isMobile">
          <div
            v-for="item in category.sub_category"
            :key="item.id"
            class="category-list">
            <input
              type="radio"
              name="category"
              :checked="item.id == subId"
              :id="item.name_rus"
              style="display: none" />
            <label
              @click="
                {
                  getSubcategory(item.id);
                  subId = item.id;
                  isModal = false;
                }
              "
              :for="item.name_rus"
              class="category-list__btn"
              >{{ $t("lang") === "kz" ? item.name_kaz : item.name_rus }}</label
            >
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="category-header">
        <div class="relative">
          <button @click="page" class="category-header__btn">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="transform: rotate(180deg)">
              <path
                d="M5 1L12 7.5L5 14"
                stroke="#F9AB50"
                stroke-width="2"
                stroke-linecap="round" />
            </svg>
          </button>
          <p class="category-header__name">
            {{ category.name_kaz || category.name_rus }}
          </p>
        </div>
        <div class="mobile-wishlist__flex">
          <input
            type="text"
            :placeholder="$t('findProduct')"
            class="mobile-catalog__flex__input" />
          <button
            @click="isModal = !isModal"
            class="mobile-wishlist__flex__btn">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect y="2" width="18" height="2" rx="1" fill="#F7931E" />
              <rect y="8" width="18" height="2" rx="1" fill="#F7931E" />
              <rect y="14" width="18" height="2" rx="1" fill="#F7931E" />
              <rect x="12" y="2" width="2" height="2" fill="white" />
              <circle cx="13" cy="3" r="2" stroke="#F7931E" stroke-width="2" />
              <rect x="4" y="8" width="2" height="2" fill="white" />
              <circle cx="5" cy="9" r="2" stroke="#F7931E" stroke-width="2" />
              <rect x="12" y="14" width="2" height="2" fill="white" />
              <circle cx="13" cy="15" r="2" stroke="#F7931E" stroke-width="2" />
            </svg>
          </button>
        </div>
      </div>
      <p class="path">
        {{ $t("mainPage") }}/ Каталог /
        {{ $t("lang") === "kz" ? category.name_kaz : category.name_rus }}
      </p>
      <div class="category-info">
        <h2 class="category-name">
          {{ $t("lang") === "kz" ? category.name_kaz : category.name_rus }}
        </h2>
        <p class="category-count">{{ products.length }} {{ $t("tovary") }}</p>
        <div class="category-list" v-if="!isMobile">
          <div v-for="item in category.sub_category" :key="item.id">
            <input
              type="radio"
              name="category"
              :checked="item.id == subId"
              :id="item.name_rus"
              style="display: none" />
            <label
              @click="
                {
                  getSubcategory(item.id);
                  subId = item.id;
                }
              "
              :for="item.name_rus"
              class="category-list__btn"
              >{{ $t("lang") === "kz" ? item.name_kaz : item.name_rus }}</label
            >
          </div>
        </div>
      </div>
      <div class="category-item">
        <one-of-list-2
          v-for="itemMenu in productsSplice"
          :key="itemMenu.id"
          :itemMenu="itemMenu" />
      </div>
    </div>
    <button
      @click="splice()"
      class="category-show"
      v-if="count < products.length">
      {{ $t("showMore") }}
      {{ products.length - count >= 5 ? "5" : products.length - count }}
    </button>
    <router-link to="/cart" class="category-btn__cart">
      <div class="category-cart">
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4 5L4.77143 7.57143M4.77143 7.57143L7.64335 17.1445C7.77024 17.5675 8.15957 17.8571 8.60117 17.8571H20C21.1046 17.8571 22 16.9617 22 15.8571V10.1429C22 8.7227 20.8487 7.57143 19.4286 7.57143H4.77143ZM19.4286 23C18.7185 23 18.1429 22.4244 18.1429 21.7143C18.1429 21.0042 18.7185 20.4286 19.4286 20.4286C20.1387 20.4286 20.7143 21.0042 20.7143 21.7143C20.7143 22.4244 20.1387 23 19.4286 23ZM9.14286 21.7143C9.14286 21.0042 9.71849 20.4286 10.4286 20.4286C11.1387 20.4286 11.7143 21.0042 11.7143 21.7143C11.7143 22.4244 11.1387 23 10.4286 23C9.71849 23 9.14286 22.4244 9.14286 21.7143Z"
            stroke="#fff"
            stroke-width="2"
            stroke-linecap="round" />
        </svg>
        <p class="category-cart__count">{{ this.$store.state.cartCount }}</p>
      </div>
      {{ $t("cart") }}
    </router-link>
    <!-- <recomendation /> -->
  </div>
</template>

<script>
import OneOfList2 from "@/components/OneOfList2.vue";
// import Recomendation from "@/components/Recomendation.vue";
import api from "@/api/api";
export default {
  components: { OneOfList2 },
  name: "AppCatalog",
  data() {
    return {
      isMobile: false,
      isModal: false,
      category: "",
      products: "",
      productsSplice: "",
      subId: this.$route.params.sub_categoryId,
      paramId: "",
      count: 5,
    };
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize, { passive: true });
    this.subId = this.$route.params.sub_categoryId;
  },
  methods: {
    splice() {
      this.productsSplice = this.products;
      this.count = this.count + 5;
      this.productsSplice = this.productsSplice.slice(0, this.count);
    },
    page() {
      this.$router.go(-1);
    },
    resize() {
      if (window.innerWidth < 640) {
        this.isMobile = true;
        this.isModal = true;
      } else {
        this.isMobile = false;
      }
    },
    getSubcategory(id) {
      this.subId = id;
      api.getSubcategoryById(id).then((res) => {
        this.products = res.data.products;
        this.productsSplice = res.data.products;
        if (!this.isMobile) {
          this.productsSplice = this.productsSplice.slice(0, this.count);
        }
        this.subId = id;
        console.log(res.data, "itemMenu");
      });
    },
    getCategoryById(id) {
      api.getCategoryById(id).then((res) => {
        this.category = res.data;
        this.getSubcategory(this.$route.params.sub_categoryId);
      });
    },
  },
  created() {
    this.getCategoryById(this.$route.params.categoryId);
  },
};
</script>

<style>
.category-name {
  margin-top: 40px;
  margin-bottom: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 170%;
  text-transform: uppercase;
  color: #3a4753;
}
.category-count {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a4753;
}
.category-list {
  margin-top: 32px;
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 30px;
}

.category-list input[type="radio"] + label {
  cursor: pointer;
  padding: 10px 21px;
  background: #f9e8c1;
  font-weight: 600;
  border-radius: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #3a4753;
  transition: all;
  transition-duration: 500ms;
}

.category-list input[type="radio"]:checked + label {
  background: #ffcc51;
  font-weight: 600;
}

.category-list__btn:hover {
  background: #ffcc51;
  font-weight: 600;
}
.category-show {
  width: fit-content;
  padding: 16px 43px;
  border: 2px solid #3ecd79;
  border-radius: 25px;
  margin: 0 auto;
  margin-top: 30px;
  display: block;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3ecd79;
  transition: all;
  transition-duration: 400ms;
}
.category-show:hover {
  background: #3ecd79;
  color: white;
}
.category-btn__cart {
  margin: 0 auto;
  margin-top: 32px;
  padding: 10px 35px;
  background: #3ecd79;
  border-radius: 25px;
  column-gap: 15px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  display: none;
}
.category-cart {
  position: relative;
  display: flex;
  align-items: center;
}
.category-cart__count {
  position: absolute;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  top: 0;
  right: -5px;
  color: #3ecd79;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
}

.category-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}
.category-header {
  display: none;
}
.relative {
  position: relative;
}
@media screen and (min-width: 1024px) {
  .category-list {
    display: flex;
  }
}
@media screen and (min-width: 0px) and (max-width: 640px) {
  .category-list {
    margin: 15px 0;
    width: 100%;
  }
  .category-list input[type="radio"] + label {
    width: 100%;
    text-align: center;
    font-size: 16px;
    padding: 13px;
  }
  .category-show {
    display: none;
  }
  .category-btn__cart {
    display: flex;
    align-items: center;
  }
  .category-item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  .category-info {
    display: none;
  }
  .category-header {
    display: block;
    padding: 15px 0;
    position: relative;
  }
  .category-header__btn {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  .category-header__name {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 149.4%;
    text-align: center;
    color: #39424b;
    margin-bottom: 18px;
  }
}

.mobile-catalog__flex__input {
  width: 100%;
  padding: 8px 12px;
  background: #f2f2f2;
  border-radius: 10px;
  height: 38px;
}
</style>
