/** @format */

import axios from "axios";
import Vue from "vue";

const baseURL = "https://api.damdala.zoom-app.kz/";
// const baseURL = "http://109.233.108.122:8001/";

const headers = {
  "Content-Type": "application/json",
};

const instance = axios.create({
  baseURL,
  headers,
});

instance.interceptors.request.use(
  (config) => {
    if (Vue.cookie.get("access")) {
      const token = Vue.cookie.get("access");
      const lang = Vue.cookie.get("lang");
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }
      config.headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Accept-Language": lang,
      };
    } else {
      const lang = Vue.cookie.get("lang");
      config.headers = {
        Accept: "application/json",
        "Accept-Language": lang,
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

class UserService {
  async RefreshToken() {
    let refresh = {
      refresh: Vue.cookie.get("refresh"),
    };
    return await axios
      .post(baseURL + "api/user/token/refresh/", refresh)
      .then((res) => {
        console.log(res, "oirngoierngiong");
        Vue.cookie.set("access", res.data.access);
        Vue.cookie.set("refresh", res.data.refresh);
        Vue.cookie.set("chat_token", res.data.chat_token);
        location.reload();
      })
      .catch(() => {
        if (Vue.cookie.get("access") && Vue.cookie.get("refresh")) {
          Vue.cookie.delete("access");
          Vue.cookie.delete("refresh");
          location.reload();
        }
      });
  }
}

async function RefreshToken() {
  let refresh = {
    refresh: Vue.cookie.get("refresh"),
  };
  return await axios
    .post(baseURL + "api/user/token/refresh/", refresh)
    .then((res) => {
      console.log(res, "oirngoierngiong");
      Vue.cookie.set("access", res.data.access);
      Vue.cookie.set("refresh", res.data.refresh);
      location.reload();
    })
    .catch(() => {
      if (Vue.cookie.get("access") && Vue.cookie.get("refresh")) {
        Vue.cookie.delete("access");
        Vue.cookie.delete("refresh");
        location.reload();
      }
    });
}

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }
    RefreshToken();
  }
);

export default new UserService();

export { instance };
