<!-- @format -->

<template>
  <div>
    <div class="modal-back" @click="isModal = !isModal" v-if="isModal">
      <div class="modal-small" @click.stop>
        <h2 class="modal-small__h2">
          {{ $t("areYouOkOtmena") }}
        </h2>
        <button
          @click="handleChangeOrderStatus(cancelId)"
          class="modal-small__btn bg-red">
          {{ $t("cancel") }}
        </button>
        <button @click="isModal = !isModal" class="modal-close">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 1.5L13.5 13.5M1.5 13.5L13.5 1.5" stroke="black" />
          </svg>
        </button>
      </div>
    </div>
    <div class="container">
      <div class="mobile-cart__header">
        <button @click="page" class="mobile-arrow">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: rotate(180deg)">
            <path
              d="M5 1L12 7.5L5 14"
              stroke="#F9AB50"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </button>
        <h1 class="mobile-h1">{{ $t("myOrders") }}</h1>
      </div>
      <div class="cabinet-address">
        <div class="history__block" v-if="history.active.length > 0">
          <h3 class="history__block-h3">{{ $t("activeOrders") }}</h3>
          <div v-for="item in history.active" :key="item.id">
            <div
              @click="$router.push('/history/' + item.id)"
              class="history__block-one">
              <div class="history__block-up">
                <p class="history__block-text">
                  {{ $t("deliveryOn") }}
                  {{
                    item.time_delivery_to &&
                    item.time_delivery_to.split("T")[1].split(":")[0]
                  }}:{{
                    item.time_delivery_to &&
                    item.time_delivery_to.split("T")[1].split(":")[1]
                  }}
                  <br />
                  Ул.
                  {{ item.delivery_address && item.delivery_address.city }}, д.
                  {{ item.delivery_address && item.delivery_address.apartment }}
                </p>
                <p class="history__block-text">{{ $t("active") }} <br /></p>
              </div>
              <div class="history__block-down">
                <p class="history__block-text">
                  {{ $t("order") }} {{ item.order_id }} <br />
                  {{ item.total_sum }} ₸
                </p>
                <p class="history__block-status">
                  {{ item.is_paid ? $t("payed") : $t("notPayed") }}
                </p>
              </div>
            </div>
            <div class="history__block-flex">
              <button
                @click="handleRepeatOrder(item.id)"
                class="history__block-btn">
                {{ $t("repeatOrder") }}
              </button>
              <button
                v-if="
                  item.status === 'CREATED' ||
                  item.status === 'IN WORK' ||
                  item.status === 'COLLECTED' ||
                  item.status === 'COURIER_RESERVED' ||
                  item.status === 'ON DELIVERY'
                "
                @click="(isModal = !isModal), (cancelId = item.id)"
                class="history__block-otmena">
                {{ $t("otmenaZakaz") }}
              </button>
              <button
                v-else-if="item.status === 'COMPLETED'"
                @click="$router.push('/return/' + $route.params.id)"
                class="history__block-otmena">
                {{ $t("doVozvrat") }}
              </button>
            </div>
          </div>
        </div>
        <div class="history__block" v-if="history.completed.length > 0">
          <h3 class="history__block-h3">{{ $t("completed") }}</h3>
          <div v-for="item in history.completed" :key="item.id">
            <div class="history__block-one">
              <div class="history__block-up">
                <p class="history__block-text">
                  Доставка на сегодня к
                  {{ item.time_delivery_to.split("T")[1].split(":")[0] }}:{{
                    item.time_delivery_to.split("T")[1].split(":")[1]
                  }}
                  <br />
                  Ул. {{ item.delivery_address.city }}, д.
                  {{ item.delivery_address.apartment }}
                </p>
                <p class="history__block-text">Активный <br /></p>
              </div>
              <div class="history__block-down">
                <p class="history__block-text">
                  Заказ {{ item.order_id }} <br />
                  {{ item.total_sum }} ₸
                </p>
                <p class="history__block-status">
                  {{ item.is_paid ? $t("payed") : $t("notPayed") }}
                </p>
              </div>
            </div>
            <div class="history__block-flex">
              <button
                @click="handleRepeatOrder(item.id)"
                class="history__block-btn">
                {{ $t("repeatOrder") }}
              </button>
              <button
                v-if="
                  item.status === 'CREATED' ||
                  item.status === 'IN WORK' ||
                  item.status === 'COLLECTED' ||
                  item.status === 'COURIER_RESERVED' ||
                  item.status === 'ON DELIVERY'
                "
                @click="(isModal = !isModal), (cancelId = item.id)"
                class="history__block-otmena">
                {{ $t("otmenaZakaz") }}
              </button>
              <button
                v-else-if="item.status === 'COMPLETED'"
                @click="$router.push('/return/' + $route.params.id)"
                class="history__block-otmena">
                {{ $t("doVozvrat") }}
              </button>
            </div>
          </div>
        </div>
        <div class="history__block" v-if="history.canceled.length > 0">
          <h3 class="history__block-h3">{{ $t("canceled") }}</h3>
          <div v-for="item in history.canceled" :key="item.id">
            <div class="history__block-one">
              <div class="history__block-up">
                <p class="history__block-text">
                  Доставка на сегодня к
                  {{ item.time_delivery_to.split("T")[1].split(":")[0] }}:{{
                    item.time_delivery_to.split("T")[1].split(":")[1]
                  }}
                  <br />
                  Ул. {{ item.delivery_address.city }}, д.
                  {{ item.delivery_address.apartment }}
                </p>
                <p class="history__block-text">Активный <br /></p>
              </div>
              <div class="history__block-down">
                <p class="history__block-text">
                  Заказ {{ item.order_id }} <br />
                  {{ item.total_sum }} ₸
                </p>
                <p class="history__block-status">
                  {{ item.is_paid ? $t("payed") : $t("notPayed") }}
                </p>
              </div>
            </div>
            <div class="history__block-flex">
              <button
                @click="handleRepeatOrder(item.id)"
                class="history__block-btn">
                {{ $t("repeatOrder") }}
              </button>
              <button
                v-if="
                  item.status === 'CREATED' ||
                  item.status === 'IN WORK' ||
                  item.status === 'COLLECTED' ||
                  item.status === 'COURIER_RESERVED' ||
                  item.status === 'ON DELIVERY'
                "
                @click="(isModal = !isModal), (cancelId = item.id)"
                class="history__block-otmena">
                {{ $t("otmenaZakaz") }}
              </button>
              <button
                v-else-if="item.status === 'COMPLETED'"
                @click="$router.push('/return/' + $route.params.id)"
                class="history__block-otmena">
                {{ $t("doVozvrat") }}
              </button>
            </div>
          </div>
        </div>
        <div class="history__block" v-if="history.refund.length > 0">
          <h3 class="history__block-h3">{{ $t("refund") }}</h3>
          <div v-for="item in history.refund" :key="item.id">
            <div class="history__block-one">
              <div class="history__block-up">
                <p class="history__block-text">
                  Доставка на сегодня к
                  {{ item.time_delivery_to.split("T")[1].split(":")[0] }}:{{
                    item.time_delivery_to.split("T")[1].split(":")[1]
                  }}
                  <br />
                  Ул. {{ item.delivery_address.city }}, д.
                  {{ item.delivery_address.apartment }}
                </p>
                <p class="history__block-text">Активный <br /></p>
              </div>
              <div class="history__block-down">
                <p class="history__block-text">
                  Заказ {{ item.order_id }} <br />
                  {{ item.total_sum }} ₸
                </p>
                <p class="history__block-status">
                  {{ item.is_paid ? $t("payed") : $t("notPayed") }}
                </p>
              </div>
            </div>
            <div class="history__block-flex">
              <button
                @click="handleRepeatOrder(item.id)"
                class="history__block-btn">
                {{ $t("repeatOrder") }}
              </button>
              <button
                v-if="
                  item.status === 'CREATED' ||
                  item.status === 'IN WORK' ||
                  item.status === 'COLLECTED' ||
                  item.status === 'COURIER_RESERVED' ||
                  item.status === 'ON DELIVERY'
                "
                @click="(isModal = !isModal), (cancelId = item.id)"
                class="history__block-otmena">
                {{ $t("otmenaZakaz") }}
              </button>
              <button
                v-else-if="item.status === 'COMPLETED'"
                @click="$router.push('/return/' + $route.params.id)"
                class="history__block-otmena">
                {{ $t("doVozvrat") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AppHistory",
  data() {
    return {
      history: "",
      isModal: false,
      cancelId: 0,
    };
  },
  created() {
    api.getOrderMobile().then((res) => {
      console.log(res.data);
      this.history = res.data;
    });
  },
  watch: {
    isModal: {
      handler() {
        if (this.isModal === false) {
          this.cancelId = 0;
        }
      },
    },
  },
  methods: {
    page() {
      this.$router.go(-1);
    },
    handleRepeatOrder(id) {
      api.resetToNew({ id: id }).then(() => {
        this.$router.push("/cart");
      });
    },
  },
};
</script>

<style scoped>
.history__block {
  margin-top: 20px;
}

.history__block:first-child {
  margin-top: 0px;
}

.history__block-h3 {
  color: var(--font, #3a4753);
  font-size: 17px;
  font-style: normal;
  font-weight: 800;
  line-height: 170%; /* 28.9px */
  text-transform: uppercase;
}

.history__block-one {
  margin: 15px 0;
  padding: 15px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 20px 5px rgba(105, 129, 134, 0.1);
}

.history__block-up {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.history__block-text {
  color: var(--font, #3a4753);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 148.4%;
}

.history__block-down {
  padding-top: 15px;
  border-top: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.history__block-status {
  color: #3ecd79;
  text-align: right;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 148.4%;
}

.history__block-btn {
  padding: 6px 11px;
  border-radius: 20px;
  border: 1px solid #3ecd79;
  color: #3ecd79;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 148.4%;
}

.history__block-otmena {
  padding: 6px 11px;
  border-radius: 25px;
  background: #f7931e;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 148.4%;
}

.history__block-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
