<!-- @format -->

svg
<!-- @format -->

<template>
  <section class="main">
    <div class="modal-back" v-if="modal" @click="modal = !modal">
      <form
        @submit.prevent="handleReview()"
        class="modal"
        style="max-width: 800px"
        @click.stop>
        <h3 class="modal-review__h3">{{ $t("star") }}</h3>
        <div class="modal-review__stars">
          <svg
            class="modal-review__star"
            v-for="i in 5"
            :key="i"
            width="48"
            height="46"
            viewBox="0 0 48 46"
            :fill="star >= i && '#F9AB50'"
            xmlns="http://www.w3.org/2000/svg"
            @mouseenter="star = i"
            @mouseleave="star--"
            @click="starCount = i"
            style="cursor: pointer">
            <path
              d="M24 0L31.9351 14.0783L47.7764 17.2746L36.8393 29.1717L38.6946 45.2254L24 38.5L9.30537 45.2254L11.1607 29.1717L0.223587 17.2746L16.0649 14.0783L24 0Z"
              :fill="
                star >= i ? '#F9AB50' : starCount >= i ? '#F9AB50' : '#979EA6'
              " />
          </svg>
        </div>
        <p class="modal-review__label__text">{{ $t("comment") }}</p>
        <textarea
          v-model="reviewSend.content_review"
          name="review"
          id=""
          cols="30"
          rows="8"
          placeholder="Отзыв..."
          class="modal-review__textarea"></textarea>
        <div class="modal-review__btns">
          <button type="submit" class="modal-review__btn">
            {{ $t("addReview") }}
          </button>
          <button
            type="button"
            class="modal-review__btn"
            @click="modal = false">
            {{ $t("cancel") }}
          </button>
        </div>
      </form>
    </div>
    <div
      :class="
        reviewsMobile
          ? 'mobile-reviews__page'
          : 'mobile-reviews__page right-full'
      ">
      <div class="header-mobile__btns pt-15" v-if="isMobile">
        <button @click="reviewsMobile = !reviewsMobile">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: rotate(180deg)">
            <path
              d="M5 1L12 7.5L5 14"
              stroke="#F9AB50"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </button>
      </div>
      <header class="mobile-review__header">
        <h1 class="mobile-review__h1">
          {{ $t("review") }}
        </h1>
      </header>
      <div class="mobile-review__page">
        <div class="container">
          <div class="mobile-review__statistic">
            {{ product.rating }} ({{ product.product_reviews.length }}
            {{
              product.product_reviews.length === 1
                ? "оценка"
                : product.product_reviews.length > 1 &&
                  product.product_reviews.length <= 4
                ? "оценки"
                : "оценок"
            }})
            <div class="mobile-review__mean">
              <p>Супер</p>
              <div class="mobile-review__stars">
                <svg
                  v-for="i in 5"
                  :key="i"
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.59375 0L10.004 4.27627L14.8158 5.24715L11.4937 8.86091L12.0572 13.7372L7.59375 11.6944L3.13026 13.7372L3.69382 8.86091L0.371665 5.24715L5.18346 4.27627L7.59375 0Z"
                    fill="#F9AB50" />
                </svg>
              </div>
            </div>
            <div class="mobile-review__mean">
              <p>
                {{ $t("good") }}
              </p>
              <div class="mobile-review__stars">
                <svg
                  v-for="i in 5"
                  :key="i"
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.59375 0L10.004 4.27627L14.8158 5.24715L11.4937 8.86091L12.0572 13.7372L7.59375 11.6944L3.13026 13.7372L3.69382 8.86091L0.371665 5.24715L5.18346 4.27627L7.59375 0Z"
                    :fill="i <= 4 ? '#F9AB50' : '#979EA6'" />
                </svg>
              </div>
            </div>
            <div class="mobile-review__mean">
              <p>
                {{ $t("notBad") }}
              </p>
              <div class="mobile-review__stars">
                <svg
                  v-for="i in 5"
                  :key="i"
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.59375 0L10.004 4.27627L14.8158 5.24715L11.4937 8.86091L12.0572 13.7372L7.59375 11.6944L3.13026 13.7372L3.69382 8.86091L0.371665 5.24715L5.18346 4.27627L7.59375 0Z"
                    :fill="i <= 3 ? '#F9AB50' : '#979EA6'" />
                </svg>
              </div>
            </div>
            <div class="mobile-review__mean">
              <p>
                {{ $t("canGood") }}
              </p>
              <div class="mobile-review__stars">
                <svg
                  v-for="i in 5"
                  :key="i"
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.59375 0L10.004 4.27627L14.8158 5.24715L11.4937 8.86091L12.0572 13.7372L7.59375 11.6944L3.13026 13.7372L3.69382 8.86091L0.371665 5.24715L5.18346 4.27627L7.59375 0Z"
                    :fill="i <= 2 ? '#F9AB50' : '#979EA6'" />
                </svg>
              </div>
            </div>
            <div class="mobile-review__mean">
              <p>
                {{ $t("bad") }}
              </p>
              <div class="mobile-review__stars">
                <svg
                  v-for="i in 5"
                  :key="i"
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.59375 0L10.004 4.27627L14.8158 5.24715L11.4937 8.86091L12.0572 13.7372L7.59375 11.6944L3.13026 13.7372L3.69382 8.86091L0.371665 5.24715L5.18346 4.27627L7.59375 0Z"
                    :fill="i <= 1 ? '#F9AB50' : '#979EA6'" />
                </svg>
              </div>
            </div>
          </div>
          <button
            @click="modal = !modal"
            v-if="!reviewUsed"
            class="card-info__comment__btn mx-auto">
            {{ $t("addReview") }}
          </button>
          <div
            class="mobile-review__one"
            v-for="item in product.product_reviews"
            :key="item.id">
            <p class="mobile-review__person">{{ item.name }}</p>
            <div class="mobile-review__flex">
              <div class="mobile-review__stars">
                <svg
                  v-for="i in 5"
                  :key="i"
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.59375 0L10.004 4.27627L14.8158 5.24715L11.4937 8.86091L12.0572 13.7372L7.59375 11.6944L3.13026 13.7372L3.69382 8.86091L0.371665 5.24715L5.18346 4.27627L7.59375 0Z"
                    :fill="item.rating >= i ? '#F9AB50' : '#979EA6'" />
                </svg>
              </div>
              <p class="mobile-review__date">{{ item.date_of_publish }}</p>
            </div>
            <p class="mobile-review__comment">{{ item.content_review }}</p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="header-mobile__btns" v-if="isMobile">
        <button @click="page">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: rotate(180deg)">
            <path
              d="M5 1L12 7.5L5 14"
              stroke="#3A4753"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </button>
        <div class="header-mobile__btns__right">
          <button>
            <svg
              width="20"
              height="23"
              viewBox="0 0 20 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle cx="16.5" cy="3.5" r="3.5" fill="#3A4753" />
              <circle cx="3.5" cy="11.5" r="3.5" fill="#3A4753" />
              <circle cx="16.5" cy="19.5" r="3.5" fill="#3A4753" />
              <rect
                x="3.76172"
                y="10.2168"
                width="16"
                height="1.5"
                transform="rotate(-33.5674 3.76172 10.2168)"
                fill="#3A4753" />
              <rect
                x="3.6875"
                y="12"
                width="16"
                height="1.5"
                transform="rotate(27.3244 3.6875 12)"
                fill="#3A4753" />
            </svg>
          </button>
          <router-link to="/wishlist" class="wishlist-btn">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.4233 23.8871C22.0857 24.3546 23 23.8809 23 23.0701V7.00001C23 5.34316 21.6569 4.00001 20 4.00001L9 4C7.34315 4 6 5.34315 6 7V23.0701C6 23.8809 6.91427 24.3546 7.57668 23.887L13.9233 19.4071C14.269 19.163 14.731 19.163 15.0767 19.4071L21.4233 23.8871Z"
                stroke="#3A4753"
                stroke-width="2"
                stroke-linecap="square" />
            </svg>
          </router-link>
        </div>
      </div>
      <img :src="product.product_image" alt="" class="category-img" />
      <div class="container">
        <p class="path">
          {{ $t("mainPage") }} / Каталог / {{ product.category_name }} /
          {{ product.subcategory_name }} /
          {{ product.name_rus }}
        </p>
        <card-desktop
          v-if="!isMobile"
          :product="product"
          :modal="modal"
          @modal="modal = !modal"
          :reviewUsed="reviewUsed" />
      </div>
      <card-mobile
        v-if="isMobile"
        @reviewsOpen="reviewsMobile = !reviewsMobile"
        :product="product"
        :reviewsMobile="reviewsMobile"
        :reviewUsed="reviewUsed" />
      <recomendation :recomendation="recomendation" />
    </div>
  </section>
</template>
<script>
import CardDesktop from "@/components/CardDesktop.vue";
import CardMobile from "@/components/CardMobile.vue";
import Recomendation from "@/components/Recomendation.vue";
import api from "@/api/api";
export default {
  components: { CardDesktop, CardMobile, Recomendation },
  name: "AppCard",
  data() {
    return {
      product: "",
      comments: 5,
      isMobile: false,
      reviewsMobile: false,
      modal: false,
      star: 0,
      recomendation: "",
      starCount: 0,
      reviewSend: {
        content_review: "",
        rating: 0,
        product_group: 0,
      },
      reviewUsed: false,
    };
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize, { passive: true });
  },
  methods: {
    async checkReview() {
      const temp = await JSON.parse(localStorage.getItem("userId"));
      if (temp && this.product.product_reviews) {
        this.product.product_reviews.forEach((el) => {
          if (el.user == temp) {
            this.reviewUsed = true;
          }
        });
      }
    },
    getRecomended(id) {
      api.getRecomendationsSubCategory(id).then((res) => {
        this.recomendation = res.data;
      });
    },
    getProductById() {
      api.getProductById(this.$route.params.id).then((res) => {
        this.product = res.data;
        console.log(res.data, "asdasdasd");
        this.checkReview();
        this.getRecomended(res.data.subcategory_link);
      });
    },
    handleReview() {
      this.reviewSend.rating = this.starCount;
      this.reviewSend.product_group = this.product.id;
      api
        .addReview(this.reviewSend)
        .then((res) => {
          console.log(res.data);
          this.modal = false;
          this.getProductById();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    page() {
      this.$router.go(-1);
    },
    resize() {
      console.log(this.isMobile);
      if (window.innerWidth < 640) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  created() {
    this.getProductById();
  },
};
</script>

<style>
.card-info {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 40px;
}
.card-info__left {
  max-width: 55%;
  column-gap: 15px;
}
.card-info__h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 170%;

  text-transform: uppercase;

  color: #3a4753;
}
.card-info__mass {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #979ea6;
}
.card-info__price {
  margin: 24px 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  color: #3a4753;
}
.card-info__flex {
  display: flex;
  align-items: center;
  column-gap: 18px;
}
.card-info__btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 10px;
  border: 1px solid #979ea6;
  border-radius: 25px;
  width: 100px;
  height: auto;
}
.card-info__count {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3a4753;
}
button {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-info__basket {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  padding: 10px 36px;

  background: #3ecd79;
  border-radius: 25px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.card-info__h3 {
  margin-top: 60px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 170%;
  text-transform: uppercase;
  color: #3a4753;
}
.card-info__description {
  margin-top: 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 148.4%;
  color: #3a4753;
}
.card-info__p {
  margin: 27px 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 170%;
  color: #3a4753;
}
.card-info__span {
  margin-left: 14px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 148.4%;
  color: #3a4753;
}
.card-info__comment__btn {
  margin-top: 27px;
  padding: 15px 30px;
  background: #f9ab50;
  border-radius: 25px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.card-info__comments {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;
  padding: 20px 27px;
  background: #f2f2f2;
  border-radius: 20px;
}
.card-info__name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 148.4%;
  color: #3a4753;
}
.card-info__comment__date {
  margin-top: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 148.4%;
  color: #979ea6;
}
.card-info__comment {
  margin-top: 16px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 148.4%;
  color: #3a4753;
}
.card-info__stars {
  display: flex;
  align-items: center;
  column-gap: 2px;
}
.card-info__img {
  width: 455px;
  height: 365px;
  object-fit: cover;
}
.comment-show {
  width: fit-content;
  padding: 16px 43px;
  border: 2px solid #3ecd79;
  border-radius: 25px;
  margin: 0 auto;
  margin-top: 30px;
  display: block;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3ecd79;
  transition: all;
  transition-duration: 400ms;
}
.comment-show:hover {
  background: #3ecd79;
  color: white;
}
.category-img {
  display: none;
}

.wishlist-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-review__page {
  min-height: 100vh;
}

.mobile-review__header {
  background: #e2e7ee;
  height: 50px;
  text-align: center;
  padding: 13px 0;
}

.mobile-review__h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a4753;
}

.pt-15 {
  padding: 17px 10px !important;
}

.mobile-reviews__page {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  transition: all;
  transition-duration: 300ms;
  z-index: 2;
  min-height: 100vh;
  background: white;
}

.right-full {
  right: -100%;
}

.mobile-review__statistic {
  padding: 12px;
  background: #f0f4f9;
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #3a4753;
  margin: 15px 0;
}

.mobile-review__mean {
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-review__stars {
  display: flex;
  align-items: center;
  gap: 5px;
}

.mobile-review__one {
  padding: 15px;
  border-bottom: 1px solid rgba(58, 71, 83, 0.2);
}

.mobile-review__person {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #3a4753;
}

.mobile-review__flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.mobile-review__date {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #979ea6;
}

.mobile-review__comment {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #3a4753;
}

.mx-auto {
  margin: 0 auto;
  display: block;
}

.modal-review__h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #3a4753;
  text-align: center;
}

.modal-review__stars {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  margin-top: 20px;
}

svg,
path {
  transition: all;
  transition-duration: 150ms;
}

.modal-review__label__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #3a4753;
  margin: 20px 0;
}

.modal-review__textarea {
  width: 100%;
  padding: 15px;
  background: #f2f2f2;
  border-radius: 10px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #3a4753;
}

.modal-review__textarea::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #7d8286;
}

textarea:focus {
  outline: none;
}

.modal-review__btns {
  display: flex;
  gap: 10px;
  margin: 20px 0;
  align-items: center;
  justify-content: center;
}

.modal-review__btn:first-child {
  padding: 15px 30px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  background: #f9ab50;
  border-radius: 25px;
  transition: all;
  transition-duration: 150ms;
}

.modal-review__btn:last-child {
  padding: 15px 40px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3a4753;
  border: 1px solid #3a4753;
  border-radius: 25px;
  transition: all;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
}

.modal-review__btn:first-child:hover {
  opacity: 0.8;
}

.modal-review__btn:last-child:hover {
  background: #3a4753;
  color: white;
}

.mobile-reviews__header {
  background: #e2e7ee;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .modal-review__star {
    height: 38px;
    width: 38px;
  }

  .modal-review__btn:first-child {
    padding: 12px 14px;
  }

  .modal-review__btn:last-child {
    padding: 12px 25px;
  }

  .modal-review__h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #3a4753;
  }

  .modal-review__label__text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #3a4753;
  }

  .modal-review__textarea {
    padding: 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }

  .modal-review__textarea::placeholder {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #7d8286;
  }

  .card-info {
    flex-direction: column-reverse;
  }
  .category-path {
    display: none;
  }
  .card-info__img {
    display: none;
  }
  .category-img {
    display: block;
    object-fit: cover;
    width: 100%;
  }
  .card-info__left {
    max-width: 100%;
    height: 351px;
  }
  .header-mobile__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    padding: 10px;
  }
  .header-mobile__btns__right {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }
}
@media screen and (min-width: 640px) and (max-width: 1024px) {
  .card-info {
    flex-direction: column-reverse;
    row-gap: 30px;
  }
  .card-info__left {
    max-width: 100%;
  }
  .card-info__img {
    margin: 0 auto;
  }
}
</style>
