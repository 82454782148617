<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.0151 23.8543C22.4834 22.3659 21.3116 21.0506 19.6816 20.1125C18.0516 19.1745 16.0544 18.666 13.9998 18.666C11.9453 18.666 9.94808 19.1745 8.31807 20.1125C6.68805 21.0506 5.5163 22.3658 4.98453 23.8543"
      stroke="#F9AB50"
      stroke-width="2"
      stroke-linecap="round"
    />
    <circle
      cx="14.0002"
      cy="9.33268"
      r="4.66667"
      stroke="#F9AB50"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "AppUserIcon",
};
</script>

<style></style>
