<!-- @format -->

<template>
  <div class="modal-back" v-if="isShow" @click="closeModal">
    <div class="modal-block modal" @click.stop>
      <button class="modal-close" @click="closeModal">
        <img src="@/assets/icons/x.svg" alt="" />
      </button>
      <div class="modal__item" v-if="isSuccess === true">
        <img src="@/assets/icons/check-circle.svg" class="modal-img" alt="" />
        <p>Оплата прошла удачно.</p>
      </div>
      <div class="modal__item" v-if="isSuccess === false">
        <img src="@/assets/icons/minus-circle.svg" class="modal-img" alt="" />
        <p>Оплата не прошла.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentModal",
  data() {
    return {
      isShow: false,
      isSuccess: false,
    };
  },
  methods: {
    closeModal() {
      this.isShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  //   position: fixed;
  //   z-index: 10;
  //   height: 100%;
  //   width: 100%;
  //   background: black;
  //   opacity: 0.8;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  &-block {
    background: #fff;
    width: 592.912px;
    height: 327px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
  }
  &-img {
    width: 131px;
    height: 131px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 45px;
  }
  @media (max-width: 640px) {
    width: 100%;
    height: 100%;
    font-size: 20px;
    text-align: center;
  }
}
</style>
