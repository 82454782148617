<!-- @format -->

<template>
  <div>
    <div
      v-if="isMobile"
      class="modal-back"
      style="background: rgba(33, 38, 45, 0.73)"
      :style="isModal ? 'display:block' : 'display:none'"
      @click="isModal = !isModal"></div>
    <div
      v-if="isMobile"
      class="modal-down"
      @click.stop
      :style="
        isModal ? 'transform:translate(0)' : 'transform:translateY(100%)'
      ">
      <div class="modal-between">
        <p class="modal-pay">
          {{ $t("payType") }}
        </p>
        <button @click="isModal = !isModal" class="modal-close__relative">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke-width="2">
            <path d="M1.5 1.5L13.5 13.5M1.5 13.5L13.5 1.5" stroke="black" />
          </svg>
        </button>
      </div>
      <button class="modal-add__btn mt-20">
        <div class="modal-add__btn__left">
          <button class="modal-add__btn__plus">
            <svg
              width="13"
              height="14"
              viewBox="0 0 13 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke-width="1.5">
              <path d="M6.5 0.5V13.5M0 7H13" stroke="#3ECD7B" />
            </svg>
          </button>
          {{ $t("addCard") }}
        </div>
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 1L12 7.5L5 14"
            stroke="#414141"
            stroke-width="2"
            stroke-linecap="round" />
        </svg>
      </button>
      <div class="modal-choice-pay">
        <input
          type="radio"
          name="pay"
          id="pay1"
          class="address-input__date"
          style="display: none"
          checked />
        <label
          @click="price > 0 && $router.push('/order')"
          for="pay1"
          class="address-form__pay"
          style="padding: 15px; width: 90%">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
              x="3"
              y="6.5"
              width="18"
              height="13"
              rx="2"
              stroke="#3A4753"
              stroke-width="2" />
            <path
              d="M7 15.5H7.01"
              stroke="#3A4753"
              stroke-width="2"
              stroke-linecap="round" />
            <path
              d="M4 11.5H21"
              stroke="#3A4753"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
          {{ $t("payCard") }}
        </label>
        <input
          type="radio"
          name="pay"
          id="pay2"
          class="address-input__date"
          style="display: none" />
        <label
          @click="price > 0 && $router.push('/order')"
          for="pay2"
          class="address-form__pay"
          style="padding: 15px; width: 90%">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.8381 8.40946H15M8.8381 11.3571H9M11.8463 11.3571H12M14.8341 11.3571H15M8.8381 13.9286H9M11.8463 13.9286H12M14.8341 13.9286H15M7 16.5H17C17.5523 16.5 18 16.1162 18 15.6429V6.35714C18 5.88376 17.5523 5.5 17 5.5H7C6.44772 5.5 6 5.88376 6 6.35714V15.6429C6 16.1162 6.44772 16.5 7 16.5Z"
              stroke="#3A4753"
              stroke-width="2"
              stroke-linecap="round" />
            <mask id="path-2-inside-1_2387_80274" fill="white">
              <rect x="7" y="15.5" width="10" height="5" rx="1" />
            </mask>
            <rect
              x="7"
              y="15.5"
              width="10"
              height="5"
              rx="1"
              stroke="#3A4753"
              stroke-width="4"
              mask="url(#path-2-inside-1_2387_80274)" />
          </svg>
          {{ $t("payCura") }}
        </label>
        <input
          type="radio"
          name="pay"
          id="pay3"
          class="address-input__date"
          style="display: none" />
        <label
          @click="price > 0 && $router.push('/order')"
          for="pay3"
          class="address-form__pay"
          style="padding: 15px; width: 90%">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.25 4.5H9.75C6.56802 4.5 4.97703 4.5 3.98851 5.50421C3 6.50841 3 8.12465 3 11.3571V13.6429C3 16.8753 3 18.4916 3.98851 19.4958C4.97703 20.5 6.56802 20.5 9.75 20.5H14.25C17.432 20.5 19.023 20.5 20.0115 19.4958C21 18.4916 21 16.8753 21 13.6429V11.3571C21 8.12465 21 6.50841 20.0115 5.50421C19.023 4.5 17.432 4.5 14.25 4.5Z"
              stroke="#3A4753"
              stroke-width="2" />
            <path
              d="M7 8.5H10"
              stroke="#3A4753"
              stroke-width="2"
              stroke-linecap="round" />
            <path
              d="M19 16.5H17C16.0572 16.5 15.5858 16.5 15.2929 16.2071C15 15.9142 15 15.4428 15 14.5C15 13.5572 15 13.0858 15.2929 12.7929C15.5858 12.5 16.0572 12.5 17 12.5H19C19.9428 12.5 20.4142 12.5 20.7071 12.7929C21 13.0858 21 13.5572 21 14.5C21 15.4428 21 15.9142 20.7071 16.2071C20.4142 16.5 19.9428 16.5 19 16.5Z"
              stroke="#3A4753"
              stroke-width="2" />
          </svg>
          {{ $t("payCuraNalom") }}
        </label>
      </div>
    </div>
    <div class="container">
      <p class="path">{{ $t("mainPage") }}/ {{ $t("cart") }}</p>
      <div class="mobile-cart__header">
        <h1 class="mobile-h1">{{ $t("cart") }}</h1>
        <button @click="page" class="mobile-close">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.5 1.5L13.5 13.5M1.5 13.5L13.5 1.5"
              stroke="#F9AB50"
              stroke-width="2px" />
          </svg>
        </button>
      </div>
      <div class="nothing-box" v-if="price === 0">
        <p class="nothing-text">
          {{ $t("emptyCart") }}
        </p>
      </div>
      <div class="box" v-else>
        <h2 class="h2">{{ $t("cart") }}</h2>
        <div class="cart-flex">
          <div class="cart-left">
            <div class="cart-left__info">
              <p class="cart-left__text">{{ $t("product") }}</p>
              <p class="cart-left__text">{{ $t("price") }}</p>
              <p class="cart-left__text">{{ $t("count") }}</p>
              <p class="cart-left__text">{{ $t("sum") }}</p>
              <cart-info
                v-for="item in items"
                :item="item"
                :key="item.id"
                @sum="sum"
                @refresh="refresh" />
            </div>
          </div>
          <div class="cart-right">
            <p class="cart-right__info">{{ $t("sumToPay") }}</p>
            <div class="cart-right__flex">
              <p class="cart-right__text">{{ $t("productPrice") }}</p>
              <p class="cart-right__sum">{{ price }} ₸</p>
            </div>
            <div class="cart-right__flex">
              <p class="cart-right__text">
                {{ $t("delivery") }}
                <span class="cart-right__span">{{ $t("from1000") }}</span>
                <br />
                <span class="cart-right__sale">{{ $t("from1000") }} </span>
              </p>
              <p class="cart-right__sum">{{ $t("free") }}</p>
            </div>
            <div class="cart-border"></div>
            <div class="cart-right__flex">
              <p class="cart-right__sum">{{ $t("itogo") }}:</p>
              <p class="cart-right__sum">{{ price }} ₸</p>
            </div>
            <button @click="handleClear" class="mobile-cart__clear">
              {{ $t("cleanCart") }}
            </button>
            <button
              @click="price > 0 && $router.push('/order')"
              :disabled="price < minSum"
              class="cart-right__btn mobile-hidden"
              v-if="!isMobile">
              {{ $t("oformit") }} {{ price }} т.
            </button>
            <p class="error" v-if="price < minSum">
              {{ $t("minSum") }} {{ minSum }}тг
            </p>
          </div>
          <button
            @click="isModal = !isModal"
            :disabled="price < minSum"
            class="cart-right__btn"
            v-if="isMobile">
            {{ $t("oformit") }} {{ price }} т.
          </button>
        </div>
      </div>
      <!-- <recomendation /> -->
    </div>
  </div>
</template>

<script>
// import Recomendation from "@/components/Recomendation.vue";
import CartInfo from "@/components/CartInfo.vue";
import api from "@/api/api";
export default {
  components: { CartInfo },
  name: "AppCart",
  data() {
    return {
      count: 0,
      clone: 2,
      isMobile: false,
      isModal: false,
      items: "",
      price: 0,
      bonus: 0,
      minSum: 0,
    };
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize, { passive: true });
  },
  methods: {
    handleClear() {
      api.clearCart().then(() => {
        this.refresh();
      });
    },
    sum(e) {
      if (e.type === "minus") {
        this.price -= e.price;
      } else {
        this.price += e.price;
      }
    },
    page() {
      this.$router.go(-1);
    },
    refresh() {
      api.getCart().then((res) => {
        console.log(res, "cart");
        this.items = res.data.cartitems;
        this.price = res.data.total_price;
        localStorage.setItem("cart", JSON.stringify(res.data.cartitems));
        this.$store.state.cartCount = res.data.cartitems.length;
        localStorage.setItem("count", res.data.cartitems.length);
        this.$store.dispatch("increment");
      });
    },
    resize() {
      if (window.innerWidth < 640) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  created() {
    api.getMinSum().then((res) => {
      this.minSum = res.data.results[0].order_summ;
    });
    if (!this.$cookie.get("access")) {
      this.$router.push("/login");
    }
    this.refresh();
  },
};
</script>

<style>
.box {
  margin-top: 40px;
  margin-bottom: 19px;
}
.cart-flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 36px;
}
.cart-left {
  width: 65%;
}
.cart-left__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #979ea6;
}
.cart-left__text:first-child {
  grid-column: span 2;
}
.cart-left__text {
  grid-column: span 1;
}
.cart-left__info {
  width: 100%;
  position: relative;
  grid-column: span 5;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 15px;
  align-items: center;
}
.cart-left__products {
  grid-column: span 2;
  display: flex;
  align-items: center;
  column-gap: 18px;
}
.cart-left__img {
  width: 85px;
  height: 68px;
  object-fit: cover;
}
.cart-left__name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  box-orient: vertical;
}
.cart-left__mass {
  grid-column: span 1;
  margin-top: 7px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #979ea6;
}
.cart-left__prices {
  grid-column: span 1;
}
.cart-left__old__price {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: line-through;
  color: #979ea6;
}
.cart-left__new__price {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a4753;
}
.cart-left__counts {
  grid-column: span 1;
  border: 1px solid #f9ab50;
  border-radius: 25px;
  height: 48px;
  width: 112px;
  padding: 0px 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cart-left__sum {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a4753;
}
.cart-left__delete {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.cart-right {
  padding: 36px 27px;
  background: #f2f2f2;
  border-radius: 20px;
  width: 30%;
}
.cart-right__info {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 170%;
  text-transform: uppercase;
  color: #3a4753;
}
.cart-right__flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;
}
.cart-right__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
}
.cart-right__sum {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
}
.cart-right__sale {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #979ea6;
}
.cart-border {
  width: 100%;
  background: #979ea6;
  height: 1px;
  margin-top: 20px;
}
.cart-right__btn {
  margin-top: 20px;
  width: 100%;
  padding: 16px 0;
  text-align: center;
  background: #3ecd79;
  border-radius: 25px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.cart-right__btn:disabled {
  opacity: 0.4;
  cursor: auto;
}

.mobile-cart__info {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: none;
}
.cart-info {
  width: 100%;
}
.cart-right__span {
  display: none;
}
.mobile-cart__clear {
  display: none;
}
.mobile-cart__header {
  display: none;
}

.nothing-box {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nothing-text {
  margin: 20px 0;
  text-align: center;
  font-size: 28px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #3a4753;
}

@media screen and (min-width: 640px) and (max-width: 1024px) {
  .cart-flex {
    flex-direction: column;
    row-gap: 24px;
  }
  .cart-left {
    width: 95%;
  }
  .cart-right {
    width: 50%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 0px) and (max-width: 640px) {
  .path {
    display: none;
  }
  .cart-left__text {
    display: none;
  }
  .cart-flex {
    flex-direction: column;
    row-gap: 24px;
  }
  .cart-left {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .cart-right {
    width: 100%;
    margin: 0 auto;
  }
  .cart-left__img {
    width: 100px;
    height: 80px;
    border-radius: 10px;
  }
  .cart-left__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 8px;
  }
  .cart-left__prices {
    display: none;
  }
  .cart-left__counts {
    display: none;
  }
  .cart-left__sum {
    display: none;
  }
  .cart-left__delete {
    display: none;
  }
  .cart-left__name {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 170%;
    text-transform: uppercase;
    color: #3a4753;
  }
  .mobile-cart__price {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #3a4753;
  }
  .cart-left__products {
    width: 100%;
  }
  .mobile-cart__info {
    display: flex;
  }
  .mobile-cart__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
    border: 1px solid #f7931e;
    border-radius: 25px;
    padding: 5px 9px;
    width: 112px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #3a4753;
  }
  .mobile-h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 149.4%;
    text-align: center;
    color: #39424b;
    text-transform: uppercase;
  }

  .mobile-cart__header {
    position: relative;
    padding-top: 15px;
  }
  .mobile-close {
    position: absolute;
    top: 50%;
    right: 0;
  }
  .box {
    margin-top: 25px;
    margin-bottom: 48px;
  }
  .cart-right {
    padding: 16px;
  }
  .cart-right__info {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 148.4%;
    color: #3a4753;
  }
  .cart-right__flex {
    margin-top: 16px;
    align-items: center;
  }
  .cart-right__text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 148.4%;
    color: #979ea6;
  }
  .cart-right__sale {
    display: none;
  }
  .cart-right__span {
    display: inline-block;
  }
  .cart-right__sum {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 148.4%;
    color: #3a4753;
  }
  .mobile-cart__clear {
    padding: 6px 11px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 148.4%;
    text-align: center;
    color: #e76c6e;
    display: block;
    border: 1px solid #e76c6e;
    border-radius: 20px;
    width: fit-content;
    margin-top: 16px;
  }
  .mobile-hidden {
    display: none;
  }
  .cart-right__btn {
    width: fit-content;
    padding: 16px 28px;
    margin: 0 auto;
    margin-top: 17px;
  }
  .mobile-cart__header {
    display: block;
  }

  .modal-down {
    transition: all;
    transition-duration: 500ms;
    position: fixed;
    bottom: 0;
    z-index: 2000;
    border-radius: 20px 20px 0px 0px;
    background: white;
    padding: 33px 15px;
    height: 65%;
    width: 100%;
    left: 0;
  }

  .modal-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .modal-pay {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #3a4753;
  }

  .modal-close__relative {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8f8f8;
    border-radius: 50%;
  }

  .modal-add__btn {
    padding-right: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(58, 71, 83, 0.2);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #3ecd7b;
  }

  .modal-add__btn__left {
    display: flex;
    align-items: center;
    gap: 14px;
  }

  .modal-add__btn__plus {
    padding: 8px 18px;
    border: 1.5px solid #3ecd7b;
    border-radius: 5px;
  }

  .modal-choice-pay {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 17px;
  }

  .modal-choice-pay input[type="radio"] + .address-form__pay {
    transition: all;
    transition-duration: 500ms;
  }

  .modal-choice-pay input[type="radio"]:checked + .address-form__pay {
    background: #3ecd79;
    color: white;
  }

  .modal-choice-pay input[type="radio"]:checked + .address-form__pay svg path {
    stroke: #fff;
  }
  .modal-choice-pay input[type="radio"]:checked + .address-form__pay svg rect {
    stroke: #fff;
  }
}
</style>
