<!-- @format -->

<template>
  <div>
    <div class="container">
      <p class="path">{{ $t("mainPage") }}/ {{ $t("cabinet") }}</p>
      <div class="mobile-cart__header">
        <h1 class="mobile-h1">{{ $t("support") }}</h1>
      </div>
      <div class="box">
        <h2 class="h2">{{ $t("support") }}</h2>
      </div>
      <div class="cabinet-address">
        <div class="support-flex">
          <router-link to="/profile/chat" class="support-flex__btn__up"
            >Чат</router-link
          >
          <button class="support-flex__btn__up">{{ $t("mail") }}</button>
          <a
            :href="links.telegram_link"
            target="_blank"
            class="support-flex__btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="22"
              viewBox="0 0 26 22"
              fill="none">
              <path
                d="M1.7874 9.47086C8.7667 6.36626 13.4206 4.31946 15.7493 3.33046C22.3979 0.506958 23.7795 0.016458 24.68 0.000157995C24.878 -0.003242 25.3208 0.0468578 25.6077 0.284458C25.8499 0.485158 25.9165 0.756258 25.9484 0.946458C25.9803 1.13676 26.02 1.57016 25.9884 1.90876C25.6281 5.77396 24.0692 15.1539 23.276 19.483C22.9404 21.3148 22.2796 21.929 21.6399 21.9891C20.2496 22.1197 19.1938 21.051 17.8473 20.1497C15.7401 18.7395 14.5498 17.8615 12.5044 16.4854C10.1407 14.895 11.673 14.0209 13.0201 12.5923C13.3726 12.2185 19.4983 6.52946 19.6169 6.01346C19.6317 5.94886 19.6455 5.70826 19.5055 5.58126C19.3655 5.45426 19.1589 5.49766 19.0098 5.53216C18.7985 5.58116 15.4323 7.85286 8.9113 12.3473C7.9558 13.0172 7.0904 13.3435 6.315 13.3264C5.4602 13.3076 3.8159 12.833 2.5935 12.4273C1.0942 11.9296 -0.0974619 11.6666 0.00629814 10.8215C0.0603481 10.3813 0.654028 9.93106 1.7874 9.47086Z"
                fill="white" /></svg
            >Telegram
          </a>
          <a
            :href="links.whatsapp_link"
            target="_blank"
            class="support-flex__btn whatsapp">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none">
              <g clip-path="url(#clip0_7793_193234)">
                <path
                  d="M11.0028 0H10.9973C4.93214 0 1.44333e-05 4.9335 1.44333e-05 11C-0.00375183 13.3169 0.729657 15.5749 2.09414 17.4474L0.723264 21.5339L4.95139 20.1823C6.7449 21.3721 8.85044 22.0046 11.0028 22C17.0679 22 22 17.0651 22 11C22 4.93488 17.0679 0 11.0028 0Z"
                  fill="white" />
                <path
                  d="M17.4037 15.5335C17.1384 16.2829 16.0851 16.9044 15.245 17.0859C14.6702 17.2083 13.9195 17.3059 11.3922 16.2581C8.15962 14.9189 6.07788 11.634 5.91563 11.4209C5.76025 11.2077 4.60938 9.6815 4.60938 8.103C4.60938 6.5245 5.411 5.75588 5.73413 5.42588C5.9995 5.155 6.43812 5.03125 6.85887 5.03125C6.995 5.03125 7.11738 5.03813 7.22738 5.04363C7.5505 5.05738 7.71275 5.07663 7.92587 5.58675C8.19125 6.22613 8.8375 7.80463 8.9145 7.96688C8.99287 8.12913 9.07125 8.34913 8.96125 8.56225C8.85813 8.78225 8.76737 8.87987 8.60512 9.06687C8.44287 9.25387 8.28887 9.39687 8.12662 9.59763C7.97812 9.77225 7.81037 9.95925 7.99738 10.2824C8.18438 10.5986 8.83063 11.6532 9.78212 12.5002C11.01 13.5934 12.0055 13.9426 12.3616 14.0911C12.627 14.2011 12.9432 14.175 13.1371 13.9688C13.3832 13.7034 13.6871 13.2634 13.9965 12.8303C14.2165 12.5195 14.4943 12.481 14.7858 12.591C15.0828 12.6941 16.6544 13.471 16.9775 13.6319C17.3006 13.7941 17.5137 13.8711 17.5921 14.0073C17.6691 14.1434 17.6691 14.7828 17.4037 15.5335Z"
                  fill="#3ECD79" />
              </g>
              <defs>
                <clipPath id="clip0_7793_193234">
                  <rect width="22" height="22" fill="white" />
                </clipPath>
              </defs></svg
            >Telegram
          </a>
        </div>
      </div>
    </div>
    <router-link to="/support/settings" class="support-router border-top">
      {{ $t("settings") }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="20"
        viewBox="0 0 12 20"
        fill="none">
        <path
          d="M11.4219 9.93896C11.4219 9.55713 11.2759 9.23145 10.9727 8.93945L2.21289 0.370605C1.97705 0.123535 1.6626 0 1.30322 0C0.573242 0 0.000488281 0.561523 0.000488281 1.30273C0.000488281 1.66211 0.146484 1.98779 0.393555 2.23486L8.28857 9.93896L0.393555 17.6431C0.146484 17.9014 0.000488281 18.2271 0.000488281 18.5864C0.000488281 19.3164 0.573242 19.8779 1.30322 19.8779C1.6626 19.8779 1.97705 19.7544 2.21289 19.5073L10.9727 10.9385C11.2759 10.6465 11.4106 10.3208 11.4219 9.93896Z"
          fill="#3ECD79" />
      </svg>
    </router-link>
    <div class="container my-15">
      <h2>{{ $t("faq") }}</h2>
    </div>
    <router-link
      :to="'/support/faq/' + item.id"
      :class="index === 0 ? 'support-router border-top' : 'support-router'"
      v-for="(item, index) in faqs"
      :key="item.id">
      <div class="support-router__flex">
        <img :src="item.icon" alt="" class="support-router__img" />
        <div>
          <p class="support-router__title">{{ item.title }}</p>
          <p class="support-router__text">
            {{ item.text }}
          </p>
        </div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="20"
        viewBox="0 0 12 20"
        fill="none">
        <path
          d="M11.4219 9.93896C11.4219 9.55713 11.2759 9.23145 10.9727 8.93945L2.21289 0.370605C1.97705 0.123535 1.6626 0 1.30322 0C0.573242 0 0.000488281 0.561523 0.000488281 1.30273C0.000488281 1.66211 0.146484 1.98779 0.393555 2.23486L8.28857 9.93896L0.393555 17.6431C0.146484 17.9014 0.000488281 18.2271 0.000488281 18.5864C0.000488281 19.3164 0.573242 19.8779 1.30322 19.8779C1.6626 19.8779 1.97705 19.7544 2.21289 19.5073L10.9727 10.9385C11.2759 10.6465 11.4106 10.3208 11.4219 9.93896Z"
          fill="#3ECD79" />
      </svg>
    </router-link>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AppSupport",
  data() {
    return {
      links: "",
      faqs: "",
    };
  },
  created() {
    api.getQuestion().then((res) => {
      console.log(res.data.results);
      this.faqs = res.data.results;
    });
    api.getSupport().then((res) => {
      this.links = res.data.results[0];
    });
  },
};
</script>
<style scoped>
.support-flex {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.support-flex__btn__up {
  border-radius: 25px;
  border: 1px solid #3ecd79;
  width: calc((100% - 15px) / 2);
  padding: 13px;
  color: #3ecd79;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.support-flex__btn {
  border-radius: 25px;
  width: calc((100% - 15px) / 2);
  padding: 13px;
  color: white;
  background: #75c2e7;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.support-router {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #d9d9d9;
  color: #3ecd79;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  gap: 10px;
}

.border-top {
  border-top: 1px solid #d9d9d9;
}

.support-router__text {
  margin-top: 5px;
  color: #3a4753;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.support-router__img {
  max-width: 30px;
  height: auto;
}

.support-router__flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.whatsapp {
  background: var(--dark-green, #3ecd79);
}

.my-15 {
  margin: 15px 0;
}

@media screen and (max-width: 640px) {
  .box {
    display: none;
  }
}
</style>
