<!-- @format -->

<template>
  <div class="container">
    <p class="path">{{ $t("mainPage") }}/{{ $t("cabinet") }}</p>
    <div class="mobile-cart__header">
      <button @click="page" class="mobile-arrow">
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style="transform: rotate(180deg)">
          <path
            d="M5 1L12 7.5L5 14"
            stroke="#F9AB50"
            stroke-width="2"
            stroke-linecap="round" />
        </svg>
      </button>
      <h1 class="mobile-h1">ЧАТ</h1>
    </div>
    <div class="box">
      <h2 class="h2">Чат</h2>
    </div>
    <div class="chat">
      <div class="chat-overflow">
        <div class="chat-box" id="inner-scroll">
          <div
            v-for="item in messages"
            :key="item.date_sent"
            :class="
              item.user === 'admin'
                ? 'chat-left'
                : item.user === 'bot'
                ? 'chat-left'
                : 'chat-right'
            ">
            <div
              :class="
                item.user === 'admin'
                  ? 'chat-left__block'
                  : item.user === 'bot'
                  ? 'chat-left__block'
                  : 'chat-right__block'
              ">
              <p
                class="chat-role"
                v-if="item.user === 'admin' || item.user === 'bot'">
                Администратор
              </p>
              <p class="chat-text">{{ item.message }}</p>
            </div>
            <p class="chat-time">{{ item.date_sent }}</p>
          </div>
          <!-- <div class="chat-right">
          <p class="chat-text">Здравствуйте!</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="9"
            viewBox="0 0 18 9"
            fill="none">
            <path
              d="M12.6376 1.45966L4.63674 7.99995L1 5.02709"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M16.9969 1L8.99611 7.54029L5.35938 4.56743"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div> -->
        </div>
      </div>
      <form @submit.prevent="messagePush" class="chat-form">
        <input
          type="text"
          :placeholder="$t('writeText')"
          class="chat-form__input"
          v-model="text" />
        <button type="submit" class="chat-form__btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="33"
            viewBox="0 0 33 33"
            class="chat-form__svg"
            fill="none">
            <g clip-path="url(#clip0_8734_193987)">
              <mask
                id="mask0_8734_193987"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="33"
                height="33">
                <path
                  d="M32.1313 0.677429H0.679688V32.129H32.1313V0.677429Z"
                  fill="white" />
              </mask>
              <g mask="url(#mask0_8734_193987)">
                <path
                  d="M4.71888 18.5351L13.8934 16.7001C14.7126 16.5364 14.7126 16.2701 13.8934 16.1064L4.71888 14.2714C4.17241 14.1623 3.64019 13.6296 3.53109 13.0836L1.69608 3.90909C1.53195 3.08938 2.01502 2.70852 2.77428 3.05891L30.7706 15.9801C31.2763 16.2135 31.2763 16.5929 30.7706 16.8264L2.77428 29.7476C2.01502 30.098 1.53195 29.7171 1.69608 28.8974L3.53109 19.7229C3.64019 19.1769 4.17241 18.6442 4.71888 18.5351Z"
                  fill="white" />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_8734_193987">
                <rect
                  width="31.4516"
                  height="31.4516"
                  fill="white"
                  transform="translate(0.679688 0.677429)" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name: "AppChat",
  data() {
    return {
      text: "",
      messages: [],
      connection: null,
    };
  },
  created() {
    if (!this.$cookie.get("access")) {
      this.$router.push("/login");
    }
    this.connection = new WebSocket(
      `wss://api.damdala.zoom-app.kz/ws/chats/?order=${
        this.$route.params.id
      }&token=A${this.$cookie.get("chat_token")}z`
    );

    this.connection.onconnect = function (event) {
      console.log(event, "onconnect");
    };

    this.connection.onmessage = (event) => {
      console.log(JSON.parse(event.data));
      this.messages = JSON.parse(event.data);
    };

    this.connection.onopen = function (event) {
      console.log(event, "onopen");
    };
    this.connection.onerror = function (event) {
      console.log(event, "onerror");
    };
  },
  methods: {
    messagePush() {
      this.connection.send(this.text);
      this.text = "";
    },
    page() {
      this.$router.go(-1);
    },
  },
  mounted() {},
};
</script>

<style scoped>
.chat {
  max-width: 1000px;
  display: block;
  margin: 0 auto;
}

.chat-date {
  color: #262f39;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
}

.chat-overflow {
  height: 65vh;
  padding: 10px;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  overflow-x: hidden;
}

.chat-box {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  gap: 10px;
}

.chat-overflow::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.chat-overflow::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.chat-overflow::-webkit-scrollbar-thumb {
  background: #f9ab50;
}

/* Handle on hover */
.chat-overflow::-webkit-scrollbar-thumb:hover {
  background: #f9ab50;
}

.chat-left,
.chat-right {
  width: 40%;
}

.chat-right {
  margin-left: auto;
}

.chat-left__block {
  border-radius: 20px;
  background: #b0bfd0;
  padding: 8px 13px;
  width: 100%;
}

.chat-right__block {
  padding: 8px 13px;
  margin-left: auto;
  border-radius: 20px;
  background: #f9ab50;
}

.chat-role {
  color: #262f39;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.chat-text {
  color: #fff;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.chat-time {
  margin-left: auto;
  margin-top: 5px;
  width: fit-content;
  display: block;
  color: #3a4753;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.chat-form {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  background: var(--light, #f2f2f2);
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.chat-form__input {
  width: 100%;
  padding: 12px 5px;
  border-radius: 10px;
}

.chat-form__input::placeholder {
  color: #8b8c92;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.chat-form__btn {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9ab50;
  border-radius: 50%;
}

@media screen and (max-width: 640px) {
  .chat {
    margin-top: 20px;
  }
  .chat-left,
  .chat-right {
    width: 80%;
  }

  .box {
    display: none;
  }

  .chat-form {
    padding: 5px;
  }

  .chat-form__input {
    padding: 7px;
  }

  .chat-form__btn {
    width: 30px;
    height: 30px;
  }
  .chat-form__svg {
    width: 20px;
    height: 20px;
  }
  .chat-text {
    font-size: 15px;
  }
}
</style>
