<!-- @format -->

<template>
  <div v-if="modalData.modal" class="modal-back" @click="AdressModal">
    <div v-if="modalData.modal" class="modal" @click.stop>
      <button @click="AdressModal" class="modal-close">
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M1.5 1.5L13.5 13.5M1.5 13.5L13.5 1.5" stroke="black" />
        </svg>
      </button>
      <h2 class="modal-h2">{{ modalData.type }}</h2>
      <label for="city">
        <input
          type="text"
          name="city"
          :placeholder="$t('city')"
          class="address-form__input"
          v-model="info.city"
          @focus="isCity = true" />
        <button
          :class="isCity ? 'address-form__svg' : 'address-form__svg__hidden'"
          @click="isCity = !isCity">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            :style="
              !isCity ? 'transform: rotate(90deg)' : 'transform: rotate(-90deg)'
            ">
            <path
              d="M5 1L12 7.5L5 14"
              stroke="#979EA6"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </button>
        <div :class="isCity ? 'select-city' : 'select-city__hidden'">
          <button
            v-for="city in cities"
            :key="city.id"
            :class="isCity ? 'select-option' : 'select-option__hidden'"
            @click="(info.city = city.city_name), (isCity = !isCity)">
            {{ city.city_name }}
          </button>
        </div>
      </label>
      <div class="address-form__inputs">
        <label class="address-form__long" for="street">
          <input
            type="text"
            name="street"
            :placeholder="$t('street')"
            class="address-form__small__input"
            v-model="info.street" />
        </label>
        <label class="address-form__long" for="building">
          <input
            type="text"
            name="building"
            :placeholder="$t('apartment')"
            class="address-form__small__input"
            v-model="info.building" />
        </label>
      </div>
      <div class="address-form__inputs">
        <label class="address-form__long" for="kv">
          <input
            type="number"
            name="kv"
            :placeholder="$t('flat')"
            class="address-form__small__input"
            v-model="info.apartment" />
        </label>
        <label class="address-form__long" for="floor">
          <input
            type="number"
            name="floor"
            :placeholder="$t('floor')"
            class="address-form__small__input"
            v-model="info.floor" />
        </label>
        <label class="address-form__long" for="entrance">
          <input
            type="number"
            name="entrance"
            :placeholder="$t('building')"
            class="address-form__small__input"
            v-model="info.intrance" />
        </label>
        <label class="address-form__long" for="domofon">
          <input
            type="number"
            name="domofon"
            :placeholder="$t('intercom')"
            class="address-form__small__input"
            v-model="info.intercom" />
        </label>
      </div>
      <div class="modal-default">
        <input
          type="checkbox"
          name="default"
          id="default"
          style="display: none"
          v-model="info.is_main" />
        <label for="default" class="modal-default__label">
          <div class="modal-default__label__div"></div>
        </label>
        <label
          for="default"
          class="modal-default__label__text"
          style="cursor: pointer">
          {{ $t("mainAddress") }}
        </label>
      </div>
      <button @click="handle" class="modal-default__btn">
        {{ modalData.type.split(" ")[0] }}
      </button>
      <span
        v-if="error"
        style="
          color: red;
          font-weight: 500;
          text-align: center;
          margin: 10px auto;
          display: block;
        ">
        {{ $t("empty") }}
      </span>
      <span
        v-if="cityError"
        style="
          color: red;
          font-weight: 500;
          text-align: center;
          margin: 10px auto;
          display: block;
        ">
        {{ $t("emptyCity") }}
      </span>
      <span
        v-if="addressError"
        style="
          color: red;
          font-weight: 500;
          text-align: center;
          margin: 10px auto;
          display: block;
        ">
        {{ $t("addressError") }}
      </span>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import axios from "axios";
export default {
  name: "AppCabinetModal",
  props: ["modalData", "data", "id"],
  data() {
    return {
      addressError: false,
      error: false,
      cities: "",
      isCity: false,
      cityError: false,
      info: {
        apartment: "",
        building: "",
        city: "",
        floor: "",
        intercom: "",
        intrance: "",
        is_main: false,
        street: "",
        longitude: 0,
        latitude: 0,
      },
    };
  },
  watch: {
    info: {
      deep: true,
      handler() {
        this.addressError = false;
      },
    },
    data: {
      handler() {
        if (this.modalData.type === this.$t("deleteAddress")) {
          this.info = this.data.address;
          this.info.city = this.data.address.city;
        }
      },
    },
    "info.city": {
      handler() {
        if (this.info.city) {
          this.searchAddress(this.info.city);
        } else {
          this.getCity();
        }
      },
    },
  },
  methods: {
    searchAddress(e) {
      api
        .searchCity(e)
        .then((res) => {
          this.cities = res.data.results;
          if (res.data.results.length > 0) {
            this.cityError = false;
          } else {
            this.cityError = true;
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    getCity() {
      api.getCity().then((res) => {
        this.cities = res.data.results;
        if (res.data.results.length > 0) {
          this.cityError = false;
        } else {
          this.cityError = true;
        }
      });
    },
    AdressModal(status) {
      this.error = false;
      if (this.modalData.type === this.$t("deleteAddress")) {
        status = "ok";
      }
      this.$emit("isModal", {
        modal: false,
        type: this.modalData.type,
        address: "",
        status: status,
      });
    },
    async handle() {
      const coords = await axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyAIYQFT5nn-WfO6XfYmaO5_2b1omfzqtFg&address=" +
          this.info.street +
          this.info.building
      );
      this.info.latitude = coords.data.results[0].geometry.location.lng;
      this.info.longitude = coords.data.results[0].geometry.location.lat;
      if (this.modalData.type === this.$t("addAddress")) {
        if (this.info && this.cityError === false) {
          this.error = false;
          this.info.user = this.id;
          api
            .addAddress(this.info)
            .then(() => {
              this.AdressModal("ok");
            })
            .catch((err) => {
              if (err.response.status === 400) this.addressError = true;
            });
        } else {
          this.error = true;
        }
      } else {
        if (this.info) {
          this.error = false;
          api
            .updateAddress(this.data.address.id, this.info)
            .then(() => {
              this.AdressModal("ok");
            })
            .catch((err) => {
              if (err.response.status === 400) this.addressError = true;
            });
        } else {
          this.error = true;
        }
      }
    },
  },
  mounted() {
    if (this.modalData.type === this.$t("deleteAddress")) {
      this.info = this.data.address;
    }
  },
  created() {
    this.getCity();
    this.isCity = false;
  },
};
</script>

<style scoped>
.select-city__hidden {
  height: 0;
  transition: all;
  transition-duration: 300ms;
}

.select-city {
  max-height: 300px;
  overflow-y: scroll;
  position: absolute;
  top: 130%;
  left: 0;
  height: fit-content;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(59, 74, 103, 0.25);
  border-radius: 10px;
  z-index: 10;
  transition: all;
  transition-duration: 300ms;
}

.select-option__hidden {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: transparent;
  line-height: 17px;
  width: 100%;
  text-align: start;
  display: flex;
  justify-content: flex-start;
  opacity: 0;
  padding: 0px 20px;
  transition: all;
  transition-duration: 300ms;
  text-transform: uppercase;
  visibility: hidden;
}

.select-option {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 17px;
  color: #333943;
  width: 100%;
  text-align: start;
  display: flex;
  justify-content: flex-start;
  padding: 10px 20px;
  transition: all;
  transition-duration: 300ms;
}

.select-option:hover {
  opacity: 0.8;
  background: #f3f3f3;
}

svg {
  transition: all;
  transition-duration: 300ms;
}

.address-form__svg__hidden {
  padding: 20px;
  position: absolute;
  top: 30%;
  right: 0;
  transform: translateY(-50%);
}

::-webkit-scrollbar {
  width: 4px;
  height: 80%;
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.1);
}

/* Track */
::-webkit-scrollbar-track {
  background: #d9d9d9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f9ab50;
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.1);
  border-radius: 3px;
  transition: all;
  transition-duration: 200ms;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f9ab50;
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.1);
  border-radius: 3px;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .address-form__long {
    width: 100%;
  }
}

@keyframes opacity {
  0% {
  }
  100% {
    opacity: 100;
    padding: 10px 20px;
  }
}
</style>
