/** @format */

import { instance } from "./axios";
import Vue from "vue";

const endpoints = {
  //user
  getUser: "/api/user/get/",
  register: "/api/user/register/",
  login: "/api/user/token/",
  refresh: "/api/user/token/refresh/",
  updateUser: "/api/user/user/",
  addAddress: "/api/user/address/add/",
  updateAddress: "/api/user/address/",
  deleteAddress: "/api/user/address/",
  sendSMS: "/api/user/send_sms/",
  verifySMS: "/api/user/verify_sms/",
  changeUsername: "/api/user/change_username/",
  getQuestion: "/api/main_page/faq/",
  changePass: "/api/user/change_password/",
  //user

  //product
  getCategoryById: "/api/product/category/",
  getCategory: "/api/product/category/list/",
  getProductById: "/api/product/product/",
  getSubcategoryById: "/api/product/subcategory/",
  getProductsByFilters: "/api/product/produts_by_filters/",
  getProductFilters: "/api/product/filters/",
  //product

  //favourite
  deleteFavourite: "/api/user/favourite/delete/",
  getFavourite: "/api/user/favourite/get/",
  postFavourite: "/api/user/favourite/set/",
  //favourite

  //cart
  addCart: "/api/cart/add_to_cart/",
  putCart: "/api/cart/amount_set/",
  getCart: "/api/cart/get_current_user_cart/",
  delCart: "/api/cart/remove/",
  clearCart: "/api/cart/clear/",
  updateBonus: "/api/cart/update_bonus/",
  //cart

  //delivery
  getDelivery: "/api/delivery/delivery_window/",
  //delivery

  //market
  getMarket: "/api/product/market/get/",
  //market

  //main_page
  getBanners: "/api/main_page/banners/",
  getRecomendations: "/api/main_page/recomendations/",
  getSecondBanner: "/api/main_page/second_banners/",
  getVacancies: "/api/main_page/vacancies/",
  getContacts: "/api/main_page/contacts/",
  postWriteUs: "/api/main_page/write_to_us/",
  //main_page

  //recomended to subcategory
  getRecomendationsSubCategory: "/api/product/recommended/",
  //recomended to subcategory

  //another pages
  getOferta: "/api/main_page/oferta_get/1/",
  getPolitics: "/api/main_page/confidentiality_get/1/",
  getAbout: "/api/main_page/about_us/",
  //another pages

  //support
  getSupport: "/api/main_page/support/",
  //support

  //review
  addReview: "/api/product/reviews/",

  //city
  getCity: "/api/delivery/city/",

  //order
  addOrder: "/api/order/order/",
  getOrders: "/api/order/order/",
  getMinSum: "/api/order/minimal_summ/",
  resetToNew: "/api/order/reset_to_new/",
  refundOrder: "/api/order/refund/",
  getRefund: "/api/order/refund/",
  changeOrderStatus: "/api/order/order/",
  getOrderMobile: "/api/order/order_mobile/",
  //order

  //footer
  getLinks: "/api/main_page/app_links/",

  //
};

const api = {
  //user
  async getUser() {
    const res = await instance.get(endpoints.getUser);
    return res;
  },

  async Register(data) {
    const res = await instance.post(endpoints.register, data);
    return res;
  },

  async Login(data) {
    const res = await instance.post(endpoints.login, data);
    return res;
  },

  async addAddress(data) {
    const res = await instance.post(endpoints.addAddress, {
      ...data,
    });
    return res;
  },

  async updateAddress(id, data) {
    const res = await instance.patch(
      endpoints.updateAddress + id + "/update/",
      data
    );
    return res;
  },

  async deleteAddress(id) {
    const res = await instance.delete(
      endpoints.deleteAddress + id + "/delete/"
    );
    return res;
  },

  async sendSMS(phone) {
    const res = await instance.post(endpoints.sendSMS, { phone: phone });
    return res;
  },

  async verifySMS(data) {
    const res = await instance.post(endpoints.verifySMS, data);
    return res;
  },

  async updateUser(id, data) {
    const res = await instance.patch(
      endpoints.updateUser + id + "/update_info/",
      data
    );
    return res;
  },

  async changeUsername(data) {
    const res = await instance.post(endpoints.changeUsername, data);
    return res;
  },

  async getQuestion() {
    const res = await instance.get(endpoints.getQuestion);
    return res;
  },

  async getQuestionById(id) {
    const res = await instance.get(endpoints.getQuestion + id + "/");
    return res;
  },

  async changePass(data) {
    const res = await instance.post(endpoints.changePass, data);
    return res;
  },

  //user

  //delivery
  async getDelivery() {
    const res = await instance.get(endpoints.getDelivery);
    return res;
  },
  //delivery

  //product

  async getCategoryById(id) {
    const res = await instance.get(endpoints.getCategoryById + id + "/get/");
    return res;
  },

  async getCategory() {
    const res = await instance.get(endpoints.getCategory);
    return res;
  },

  async getProduct(search) {
    const res = await instance.get(
      endpoints.getProductById +
        (Vue.cookie.get("lang") === "kz" ? "kaz/" : "rus/"),
      {
        params: {
          search: search,
        },
      }
    );
    return res;
  },

  async getProductById(id) {
    const res = await instance.get(endpoints.getProductById + id + "/get/");

    return res;
  },

  async getSubcategoryById(id) {
    const res = await instance.get(
      endpoints.getSubcategoryById + id + "/products/"
    );
    return res;
  },

  async getProductFilters() {
    const res = await instance.get(endpoints.getProductFilters);
    return res.data.results;
  },

  async getProductsByFilters(data) {
    let filters = "";
    data.forEach((element, index) => {
      if (index < data.length - 1) {
        filters += element + ",";
      } else {
        filters += element;
      }
    });
    const res = await instance.get(
      endpoints.getProductsByFilters + "?id=" + filters
    );
    return res;
  },

  //product

  //favourite
  async getFavourite() {
    const res = await instance.get(endpoints.getFavourite);
    return res;
  },

  async postFavourite(id) {
    const res = await instance.post(endpoints.postFavourite, id);
    return res;
  },

  async deleteFavourite(id) {
    const res = await instance.delete(endpoints.deleteFavourite + id + "/");
    return res;
  },
  //favourite

  //cart
  async addCart(data) {
    const res = await instance.post(endpoints.addCart, data);
    return res;
  },

  async getCart() {
    const res = await instance.get(endpoints.getCart);
    return res;
  },

  async delCart(id) {
    const res = await instance.delete(endpoints.delCart + id);
    return res;
  },

  async putCart(id, data) {
    const res = await instance.put(endpoints.putCart + id + "/", data);
    return res;
  },

  async clearCart() {
    const res = await instance.delete(endpoints.clearCart);
    return res;
  },

  async UpdateBonus(is_spend_bonus) {
    const res = await instance.patch(endpoints.updateBonus, {
      is_spend_bonus: is_spend_bonus,
    });
    return res;
  },

  //cart

  //market
  async getMarket() {
    const res = await instance.get(endpoints.getMarket);
    return res;
  },

  async getMarketSearch(search) {
    const res = await instance.get(endpoints.getMarket, {
      params: { search: search },
    });
    return res;
  },
  //market

  //main_page

  async getBanners() {
    const res = await instance.get(endpoints.getBanners);
    return res;
  },

  async getRecomendations() {
    const res = await instance.get(endpoints.getRecomendations);
    return res;
  },

  async getSecondBanner() {
    const res = await instance.get(endpoints.getSecondBanner);
    return res;
  },

  async getVacancies(city) {
    const res = await instance.get(endpoints.getVacancies, {
      params: { city: city.id },
    });
    return res;
  },

  async getContacts() {
    const res = await instance.get(endpoints.getContacts);
    return res;
  },

  async postWriteUs(data) {
    const res = await instance.post(endpoints.postWriteUs, data);
    return res;
  },

  //main_page

  //another pages
  async getOferta() {
    const res = await instance.get(endpoints.getOferta);
    return res;
  },

  async getPolitics() {
    const res = await instance.get(endpoints.getPolitics);
    return res;
  },

  async getAbout() {
    const res = await instance.get(endpoints.getAbout);
    return res;
  },
  //another pages

  async getSupport() {
    const res = await instance.get(endpoints.getSupport);
    return res;
  },

  //review
  async addReview(data) {
    const res = await instance.post(endpoints.addReview, data);
    return res;
  },

  //city
  async getCity() {
    const res = await instance.get(endpoints.getCity);
    return res;
  },

  async searchCity(search) {
    const res = await instance.get(endpoints.getCity, {
      params: {
        search: search,
      },
    });
    return res;
  },

  //city

  //order
  async addOrder(data) {
    console.log(data);
    const res = await instance.post(endpoints.addOrder, data);
    return res;
  },

  async getOrder() {
    const res = await instance.get(endpoints.getOrders);
    return res;
  },

  async getOrderById(id) {
    const res = await instance.get(endpoints.getOrders + id);
    return res;
  },

  async getMinSum() {
    const res = await instance.get(endpoints.getMinSum);
    return res;
  },

  async resetToNew(data) {
    const res = await instance.post(endpoints.resetToNew, data);
    return res;
  },

  async refundOrder(data) {
    const res = await instance.post(endpoints.refundOrder, data);
    return res;
  },

  async getRefund() {
    const res = await instance.get(endpoints.refundOrder);
    return res;
  },

  async changeOrderStatus(data) {
    const res = await instance.patch(
      endpoints.changeOrderStatus + data.id + "/",
      {
        status: data.status,
      }
    );
    return res;
  },

  async getOrderMobile() {
    const res = await instance.get(endpoints.getOrderMobile);
    return res;
  },

  //footer
  async getLinks() {
    const res = await instance.get(endpoints.getLinks);
    return res;
  },

  //recomended to subcategory
  async getRecomendationsSubCategory(id) {
    const res = await instance.get(
      endpoints.getRecomendationsSubCategory + id + "/get/"
    );
    return res;
  },
  //recomended to subcategory
};

export default api;
