<!-- @format -->

<template>
  <div>
    <cabinet-modal
      :data="changeAddress"
      :id="user_id"
      :modalData="modalData"
      @isModal="changeModal" />
    <div @click="isLeave = !isLeave" class="modal-back" v-if="isLeave">
      <div @click.stop class="modal">
        <div class="leave-block">
          <div class="leave-text">{{ $t("areYouOk") }}</div>
          <div class="leave-btns">
            <button @click="handleLeave" class="leave-btn">
              {{ $t("yes") }}
            </button>
            <button @click="isLeave = !isLeave" class="leave-btn">
              {{ $t("no") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <p class="path">{{ $t("mainPage") }}/{{ $t("cabinet") }}</p>
      <div class="mobile-cart__header">
        <h1 class="mobile-h1">{{ $t("profile") }}</h1>
      </div>
      <div class="box">
        <h2 class="h2">
          {{ $t("cabinet") }}
        </h2>
        <div class="cabinet mobile-hidden">
          <div class="cabinet-tabs">
            <input
              type="radio"
              name="tabs"
              id="tab1"
              :checked="tabs === 1"
              style="width: 0; display: none" />
            <label @click="tabs = 1" for="tab1" class="cabinet-tab">
              {{ $t("profile") }}
            </label>
            <input
              type="radio"
              name="tabs"
              id="tab5"
              :checked="tabs === 5"
              style="width: 0; display: none" />
            <label @click="tabs = 5" for="tab5" class="cabinet-tab">
              {{ $t("changePhone") }}
            </label>
            <input
              type="radio"
              name="tabs"
              id="tab2"
              :checked="tabs === 2"
              style="width: 0; display: none" />
            <label @click="tabs = 2" for="tab2" class="cabinet-tab">
              {{ $t("storyOrders") }}
            </label>
            <input
              type="radio"
              name="tabs"
              id="tab3"
              :checked="tabs === 3"
              style="width: 0; display: none" />
            <label @click="tabs = 3" for="tab3" class="cabinet-tab">
              {{ $t("myAddresses") }}
            </label>
            <input
              type="radio"
              name="tabs"
              id="tab4"
              style="width: 0; display: none"
              :checked="tabs === 4" />
            <label @click="tabs = 4" for="tab4" class="cabinet-tab">
              {{ $t("supportPodderjka") }}
            </label>
            <input
              type="radio"
              name="tabs"
              id="tab6"
              style="width: 0; display: none"
              :checked="tabs === 6" />
            <label @click="tabs = 6" for="tab6" class="cabinet-tab">
              {{ $t("storyReturns") }}
            </label>
            <input
              type="radio"
              name="tabs"
              id="tab6"
              style="width: 0; display: none" />
            <label @click="isLeave = !isLeave" for="tab6" class="cabinet-tab">
              {{ $t("leave") }}
            </label>
          </div>
          <info :data="data" v-if="tabs === 1" />
          <history v-if="tabs === 2" />
          <Addresses
            :addresses="data.addresses"
            @isModal="changeModal"
            v-if="tabs === 3" />
          <support v-if="tabs === 4" />
          <change :data="data" v-if="tabs === 5" @reload="reload" />
          <return-history v-if="tabs === 6" />
        </div>
        <div class="cabinet desktop-hidden">
          <div class="cabinet-user desktop-hidden">
            <h2 class="cabinet-user__name">
              {{ data.first_name }} {{ data.last_name }}
            </h2>
            <div class="cabinet-user__tabs">
              <router-link to="/profile/address" class="cabinet-user__tab">
                <div class="cabinet-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none">
                    <path
                      d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
                      stroke="#3A4753"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path
                      d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                      stroke="#3A4753"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                  {{ $t("myAddresses") }}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 20 20"
                  fill="none">
                  <path
                    d="M6.66406 1.33317L15.9974 9.99984L6.66406 18.6665"
                    stroke="#3A4753"
                    stroke-width="1.5"
                    stroke-linecap="round" />
                </svg>
              </router-link>
              <router-link to="/profile/chat" class="cabinet-user__tab">
                <div class="cabinet-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none">
                    <path
                      d="M21 11.5C21.0034 12.8199 20.6951 14.1219 20.1 15.3C19.3944 16.7118 18.3098 17.8992 16.9674 18.7293C15.6251 19.5594 14.0782 19.9994 12.5 20C11.1801 20.0035 9.87812 19.6951 8.7 19.1L3 21L4.9 15.3C4.30493 14.1219 3.99656 12.8199 4 11.5C4.00061 9.92179 4.44061 8.37488 5.27072 7.03258C6.10083 5.69028 7.28825 4.6056 8.7 3.90003C9.87812 3.30496 11.1801 2.99659 12.5 3.00003H13C15.0843 3.11502 17.053 3.99479 18.5291 5.47089C20.0052 6.94699 20.885 8.91568 21 11V11.5Z"
                      stroke="#3A4753"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                  {{ $t("mySms") }}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 20 20"
                  fill="none">
                  <path
                    d="M6.66406 1.33317L15.9974 9.99984L6.66406 18.6665"
                    stroke="#3A4753"
                    stroke-width="1.5"
                    stroke-linecap="round" />
                </svg>
              </router-link>
              <router-link to="/profile/history" class="cabinet-user__tab">
                <div class="cabinet-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none">
                    <rect
                      x="4.53906"
                      y="7.06592"
                      width="14.9211"
                      height="16.1842"
                      rx="1.25"
                      stroke="#3A4753"
                      stroke-width="1.5" />
                    <path
                      d="M8.06302 7C7.80055 5 8.2205 1 12 1C15.7795 1 16.1995 5 15.937 7"
                      stroke="#3A4753"
                      stroke-width="1.5"
                      stroke-linecap="round" />
                  </svg>
                  {{ $t("storyOrders") }}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 20 20"
                  fill="none">
                  <path
                    d="M6.66406 1.33317L15.9974 9.99984L6.66406 18.6665"
                    stroke="#3A4753"
                    stroke-width="1.5"
                    stroke-linecap="round" />
                </svg>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Info from "@/components/cabinet/Info.vue";
import History from "@/components/cabinet/History.vue";
import Addresses from "@/components/cabinet/Addresses.vue";
import CabinetModal from "@/components/cabinet/CabinetModal.vue";
import Support from "@/components/cabinet/Support.vue";
import api from "@/api/api";
import Change from "@/components/cabinet/Change.vue";
import ReturnHistory from "@/components/cabinet/ReturnHistory.vue";
export default {
  name: "AppCabinet",
  components: {
    Info,
    History,
    Addresses,
    CabinetModal,
    Support,
    Change,
    ReturnHistory,
  },
  data() {
    return {
      tabs: 1,
      data: null,
      user_id: "",
      changeAddress: null,
      isLeave: false,
      modalData: {
        modal: false,
        type: "",
      },
    };
  },
  watch: {
    tabs: {
      handler() {
        this.$router.push(`/cabinet?tab=${this.tabs}`);
      },
    },
  },
  methods: {
    page() {
      this.$router.push("/");
    },
    changeModal(data) {
      this.modalData.modal = data.modal;
      this.modalData.type = data.type;
      if (data) {
        this.changeAddress = data;
      }
      if (data.status) {
        this.getUserInformation();
      }
    },
    getUserInformation() {
      api.getUser().then((res) => {
        this.$cookie.set("chat_token", res.data.chat_token);
        this.data = res.data;
        this.user_id = res.data.id;
      });
    },
    handleLeave() {
      this.$cookie.delete("access");
      this.$cookie.delete("refresh");
      this.$cookie.delete("favourite");
      localStorage.removeItem("cart");
      localStorage.removeItem("count");
      location.reload();
    },
    reload() {
      this.getUserInformation();
    },
  },
  created() {
    if (this.$route.query.tab) {
      this.tabs = parseInt(this.$route.query.tab);
    }
    if (!this.$cookie.get("access")) {
      this.$router.push("/login");
    }
    this.getUserInformation();
    let temp = new Date();
    console.log(temp, "date");
  },
};
</script>

<style>
.cabinet {
  margin-top: 43px;
}
.cabinet-user__name {
  color: var(--font, #3a4753);
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.cabinet-user__tabs {
  margin: 20px 0;
}
.cabinet-user__tab {
  width: 100%;
  color: #3a4753;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 9px;
  border-bottom: 1px solid var(--grey-line, #d9d9d9);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cabinet-user__tab:first-child {
  border-top: 1px solid var(--grey-line, #d9d9d9);
}

.cabinet-svg {
  display: flex;
  align-items: center;
  gap: 5px;
}

.cabinet-tabs {
  border-bottom: 2px solid #d9d9d9;
  display: flex;
  align-items: stretch;
  column-gap: 50px;
}
.cabinet-tab {
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #979ea6;
  padding-bottom: 16px;
  transition: all;
  transition-duration: 500ms;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cabinet-tabs input[type="radio"] + label::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: transparent;
  transition: all;
  transition-duration: 500ms;
}

.cabinet-tabs input[type="radio"]:checked + label {
  position: relative;
  color: #3ecd79;
}

.cabinet-tabs input[type="radio"]:checked + label::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #3ecd79;
}

.cabinet-info {
  padding: 36px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #d9d9d9;
  animation: opacity 300ms ease-in-out;
}

.cabinet-history {
  width: 100%;
  display: block;
  padding: 30px 0;
  animation: opacity 300ms ease-in-out;
}

.cabinet-info__half {
  width: 48%;
  display: flex;
  flex-direction: column;
  row-gap: 17px;
}

.cabinet-info__label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3a4753;
}

.cabinet-info__input__full {
  margin-top: 10px;
  padding: 16px 29px;
  width: 100%;
  border: 1px solid #979ea6;
  border-radius: 25px;
}

.cabinet-info__input__full::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #979ea6;
}

.cabinet-info__p {
  margin-left: 10px;
}

.cabinet-info__submit {
  margin-top: 30px;
  margin-left: auto;
  padding: 20px 69px;
  background: #f9ab50;
  border-radius: 100px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.cabinet-info__inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cabinet-info__input__small {
  width: calc(90% / 3);
  position: relative;
}

.cabinet-info__calendar {
  cursor: pointer;
  position: absolute;
  padding: 16px 0px;
  padding-right: 29px;
  right: 0;
  top: 60%;
  transform: translateY(-50%);
}

.cabinet-address {
  padding: 30px 0px;
  animation: opacity 300ms ease-in-out;
}

.modal-back {
  background: rgba(49, 49, 49, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: opacity 300ms ease-in-out;
}

.modal {
  position: relative;
  max-width: 577px;
  width: 100%;
  padding: 34px 78px;
  background: #ffffff;
  border-radius: 20px;
  z-index: 1000;
}

.modal-close {
  position: absolute;
  top: 21px;
  right: 21px;
}

.modal-h2 {
  text-align: center;
  margin-bottom: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 170%;
  text-transform: uppercase;
  color: #3a4753;
}

.modal-default {
  margin: 17px 0px;
  display: flex;
  align-items: center;
  column-gap: 14px;
}

.modal-default__label {
  border: 1px solid #979ea6;
  border-radius: 8px;
  padding: 5px;
  width: 29px;
  height: 29px;
  background: transparent;
  display: flex;
  cursor: pointer;
}

input[type="checkbox"]:checked + .modal-default__label {
  padding: 5px 5px;
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="checkbox"] + .modal-default__label .modal-default__label__div {
  transition: all;
  transition-duration: 300ms;
  transition-timing-function: ease-in;
}

input[type="checkbox"]:checked
  + .modal-default__label
  .modal-default__label__div {
  background: #f9ab50;
  width: 18px;
  height: 18px;
  border-radius: 4px;
}

.modal-default__label__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3a4753;
}

.modal-default__textarea {
  margin-top: 10px;
  width: 100%;
  border: 1px solid #979ea6;
  border-radius: 25px;
}

.modal-default__btn {
  margin-top: 17px;
  width: 100%;
  padding: 17px 0px;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  background: #f9ab50;
  border-radius: 100px;
}

.cabinet-support {
  padding: 30px 0px;
  animation: opacity 300ms ease-in-out;
}

.leave-block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80%;
}

.leave-text {
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  color: #3a4753;
}

.leave-btns {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 0px auto;
  width: fit-content;
  margin-top: 20px;
}

.leave-btn {
  padding: 10px 20px;
  background: #f9ab50;
  border-radius: 100px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

@keyframes opacity {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@media screen and (min-width: 640px) and (max-width: 1024px) {
  .cabinet-info {
    flex-direction: column;
    gap: 20px;
  }
  .cabinet-info__half {
    width: 100%;
  }
  .cabinet-tabs {
    column-gap: 25px;
  }
}

@media screen and (min-width: 641px) {
  .desktop-hidden {
    display: none;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .modal {
    overflow-y: scroll;
    z-index: 1000;
  }

  .cabinet {
    margin-top: 10px;
  }

  .modal-default__label {
    border-radius: 10px;
  }
  input[type="checkbox"]:checked
    + .modal-default__label
    .modal-default__label__div {
    background: #f9ab50;
    width: 18px;
    height: 18px;
    border-radius: 6px;
  }
  .cabinet-tabs {
    display: none;
  }
  .cabinet-tab {
    font-size: 14px;
    white-space: nowrap;
  }
  .cabinet-info {
    flex-direction: column;
    gap: 20px;
  }
  .cabinet-info__half {
    width: 100%;
  }
  .cabinet-info__inputs {
    flex-wrap: wrap;
  }
  .cabinet-info__input__small {
    width: 100%;
  }
  .cabinet-info__submit {
    width: 100%;
    padding: 15px;
  }
  .cabinet-history {
    overflow: scroll;
  }
  .cabinet-history__titles {
    column-gap: 5px;
    width: 550px;
    white-space: nowrap;
  }
  .cabinet-history__content {
    column-gap: 5px;
    width: 550px;
    white-space: nowrap;
  }
  .cabinet-support__chat {
    flex-direction: column;
    row-gap: 10px;
    padding: 15px;
  }
  .cabinet-support__btns {
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
  }
  .cabinet-support__btn {
    font-size: 14px;
  }
  .cabinet-support__info {
    padding: 15px;
  }
  .cabinet-support__text {
    font-size: 16px;
  }
  .cabinet-support__p {
    max-width: 90%;
    font-size: 14px;
  }
  .modal {
    max-width: 100%;
    height: 100%;
    border-radius: 0px;
    padding: 15px;
    z-index: 1000;
  }
}
</style>
