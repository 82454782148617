<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 15.5L11.5 11.5M7.5 13.5C4.18629 13.5 1.5 10.8137 1.5 7.5C1.5 4.18629 4.18629 1.5 7.5 1.5C10.8137 1.5 13.5 4.18629 13.5 7.5C13.5 10.8137 10.8137 13.5 7.5 13.5Z"
      stroke="#F9AB50"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: "AppSearchIcon",
};
</script>

<style></style>
