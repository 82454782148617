<!-- @format -->

<template>
  <div style="width: 100%; position: relative">
    <tr class="cabinet-history__content">
      <th class="cabinet-history__text">{{ item.number }}</th>
      <th class="cabinet-history__text">
        {{ item.create_date.split("T")[0] }}
      </th>
      <th class="cabinet-history__text" v-if="item.status === 'created'">
        {{ $t("created") }}
      </th>
      <th class="cabinet-history__text" v-if="item.status === 'viewing'">
        {{ $t("onWatch") }}
      </th>
      <th class="cabinet-history__text" v-if="item.status === 'courier_picked'">
        {{ $t("canceledCourierSklad") }}
      </th>
      <th class="cabinet-history__text" v-if="item.status === 'received'">
        {{ $t("received") }}
      </th>
      <th class="cabinet-history__text" v-if="item.status === 'closed'">
        {{ $t("getBack") }}
      </th>
      <th
        @click="isHistory = !isHistory"
        class="cabinet-history__text flex pointer">
        {{ item.total_sum }} ₸
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          :style="isHistory ? 'transform: rotate(180deg)' : ''">
          <path
            d="M6 9L12 15L18 9"
            stroke="#F7931E"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </th>
    </tr>
    <div :class="'return-block'" v-if="isHistory">
      <div class="return-block__bottom">
        <div class="return-block__one" v-for="el in item.products" :key="el.id">
          <div class="return-block__left">
            <img
              :src="el.product_image_url"
              alt="image"
              class="return-block__img" />
            <div class="">
              <p class="return-block__name">{{ el.product.name_rus }}</p>
              <p class="return-block__weight">
                {{
                  el.product.weight > 1000
                    ? el.product.weight / 1000
                    : el.product.weight
                }}
                {{ el.product.weight > 1000 ? "Кг" : "гр" }}
              </p>
            </div>
          </div>
          <div class="return-block__right">
            <p class="return-block__text">
              {{ el.product.discount_price || el.product.price }} ₸ /шт
            </p>
            <p class="return-block__text">{{ el.quantity }} шт</p>
            <p class="return-block__text">{{ el.summ }} ₸</p>
          </div>
        </div>
      </div>
      <div class="return-block__info">
        <p class="return-block__text">{{ $t("orderWeight") }}: 800 г</p>
        <p class="return-block__text">
          {{ $t("total_sum") }}: {{ item.total_sum }} ₸
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReturnHistoryOne",
  props: ["item"],
  data() {
    return {
      isHistory: false,
    };
  },
};
</script>

<style scoped>
.cabinet-history__titles {
  background: #f2f2f2;
  border-radius: 15px;
  padding: 15px 17px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.cabinet-history__title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #3a4753;
  grid-column: span 1;
}

th {
  width: 150px;
  text-align: center;
}

th:last-child {
  text-align: end;
}

.cabinet-history__content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  padding: 15px 17px;
  border-bottom: 2px solid #d9d9d9;
  transition: all;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  position: relative;
}

.cabinet-history__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
}

.cabinet-history__content__loader {
  height: 52px;
  width: 100%;
  /* background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed; */
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
  border-radius: 8px;
}

.cabinet-history__text:first-child {
  text-decoration-line: underline;
}

.cabinet-history__text:nth-child(3) {
  color: #3ecd79;
}

.yellow {
  color: orange !important;
}

.red {
  color: red !important;
}

.black {
  font-weight: 700;
  color: #3a4753 !important;
}

.flex {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-end;
}

.text-center {
  margin-left: -30px;
}

tr {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.return-block {
  background: #f7f7f7;
  border-radius: 6px;
  padding: 15px;
  width: 100%;
  height: 100%;
  transition: all;
  transition-duration: 500ms;
  animation: opacity 500ms ease-in-out;
}

.return-block__one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.return-block__left {
  display: flex;
  align-items: center;
  gap: 15px;
}
.return-block__img {
  width: 85px;
  height: 68px;
  object-fit: cover;
}

.return-block__name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
}

.return-block__weight {
  margin-top: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #979ea6;
}

.return-block__right {
  display: flex;
  align-items: center;
  width: 35%;
  justify-content: space-between;
}

.return-block__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a4753;
}

.return-block__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.return-block__bottom {
  margin-bottom: 15px;
  border-bottom: 2px solid #d9d9d9;
}

@media screen and (max-width: 640px) {
  th {
    width: 120px;
  }
  .cabinet-history__title {
    text-align: end;
  }

  .cabinet-history__title:first-child {
    text-align: start;
  }

  .cabinet-history__title:nth-child(3) {
    text-align: end;
  }

  .cabinet-history__text:first-child {
    text-align: start;
  }
  .cabinet-history__text {
    text-align: end;
  }
}
</style>
