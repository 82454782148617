<!-- @format -->

<template>
  <div :class="checked ? 'cart-left__info pl' : 'cart-left__info'">
    <div class="cart-checked" v-if="checked">
      <input
        type="checkbox"
        :checked="checkedOne"
        v-model="checkedOne"
        :name="'default' + index"
        :id="'default' + index"
        style="display: none" />
      <label :for="'default' + index" class="modal-default__label">
        <div class="modal-default__label__div"></div>
      </label>
    </div>
    <div class="cart-left__products">
      <img :src="item.product_image_url" alt="" class="cart-left__img" />
      <div class="cart-info">
        <router-link :to="'/card/' + item.product.id" class="cart-left__name">
          {{
            $t("lang") === "kz" ? item.product.name_kaz : item.product.name_rus
          }}
        </router-link>
        <p class="cart-left__mass">{{ item.product.weight }} г</p>
        <div class="mobile-cart__info">
          <p class="mobile-cart__price">
            1 шт / {{ item.price }} ₸ <br />
            {{ oneSum }} ₸
          </p>
          <div class="mobile-cart__btn">
            <button
              @click="count > 1 ? handleMinus() : count"
              class="mobile-cart__minus">
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M1 8H14" stroke="#3A4753" />
              </svg>
            </button>
            {{ count }} шт
            <button
              @click="count < item.quantity ? handlePlus() : count"
              class="mobile-cart__plus">
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 0.5V13.5M0 7H13" stroke="#3A4753" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="cart-left__prices" v-if="item.product.discount_price">
      <p
        class="cart-left__old__price"
        v-if="item.product.price !== item.product.discount_price">
        {{ item.product.price }} ₸ /шт
      </p>
      <p class="cart-left__new__price">
        {{ item.product.discount_price }} ₸ /шт
      </p>
    </div>
    <div class="cart-left__prices" v-else>
      <p class="cart-left__old__price">{{ item.product.price }} ₸ /шт</p>
      <p class="cart-left__new__price">{{ item.product.price }} ₸ /шт</p>
    </div>
    <div class="cart-left__counts">
      <button @click="count > 1 ? handleMinus() : count">
        <svg
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M1 8H14" stroke="#3A4753" />
        </svg>
      </button>
      {{ count }}
      <button @click="count < item.quantity ? handlePlus() : count">
        <svg
          width="13"
          height="14"
          viewBox="0 0 13 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M6.5 0.5V13.5M0 7H13" stroke="#3A4753" />
        </svg>
      </button>
    </div>
    <p class="cart-left__sum" style="text-align: center">
      {{ count * item.product.discount_price || item.product.price }} ₸
    </p>
  </div>
</template>

<script>
export default {
  name: "AppCartInfo",
  props: ["checked", "index", "item"],
  data() {
    return {
      count: this.item.quantity,
      price: this.item.summ,
      oneSum: this.item.summ,
      checkedOne: true,
    };
  },
  methods: {
    handlePlus() {
      this.count++;
      this.$emit("price", {
        type: "plus",
        price: this.item.products.price,
        id: this.item.id,
      });
    },
    handleMinus() {
      this.count--;
      this.$emit("price", {
        type: "minus",
        price: this.item.products.price,
        id: this.item.id,
      });
    },
  },
  watch: {
    checkedOne: {
      handler() {
        this.$emit("checked", {
          type: this.checkedOne,
          id: this.item.id,
          count: this.count,
        });
      },
    },
  },
};
</script>

<style>
.cart-checked {
  position: absolute;
  left: -50px;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .pl {
    padding-left: 50px;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .cart-checked {
    left: -15px;
  }
  .pl {
    padding-left: 25px;
  }
}
</style>
