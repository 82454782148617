<!-- @format -->

<template>
  <table class="cabinet-history">
    <tr class="cabinet-history__titles">
      <th class="cabinet-history__title">{{ $t("numberZakaz") }}</th>
      <th class="cabinet-history__title">{{ $t("dateDelivery") }}</th>
      <th class="cabinet-history__title">{{ $t("status") }}</th>
      <th class="cabinet-history__title">{{ $t("sum") }}</th>
    </tr>
    <div v-if="!history">
      <tr
        class="cabinet-history__content cabinet-history__content__loader"
        v-for="i in 5"
        :key="i"></tr>
    </div>
    <div v-else>
      <tr
        @click="$router.push('/history/' + item.id)"
        class="cabinet-history__content"
        v-for="item in history"
        :key="item.id">
        <th class="cabinet-history__text">{{ item.number }}</th>
        <th class="cabinet-history__text">
          {{ item.create_date.split("T")[0] }}
        </th>
        <th class="cabinet-history__text" v-if="item.status === 'CREATED'">
          {{ $t("created") }}
        </th>
        <th
          class="cabinet-history__text yellow"
          v-if="item.status === 'COURIER_RESERVED'">
          {{ $t("getCourier") }}
        </th>
        <th
          class="cabinet-history__text yellow"
          v-if="item.status === 'ON DELIVERY'">
          {{ $t("onDelivery") }}
        </th>
        <th
          class="cabinet-history__text black"
          v-if="item.status === 'COMPLETED'">
          {{ $t("end") }}
        </th>
        <th class="cabinet-history__text red" v-if="item.status === 'CANCELED'">
          {{ $t("canceled") }}
        </th>
        <th class="cabinet-history__text red" v-if="item.status === 'PROBLEM'">
          {{ $t("problem") }}
        </th>
        <th
          class="cabinet-history__text red"
          v-if="item.status === 'COURIER_RESERVER_REFUND'">
          {{ $t("curaCancel") }}
        </th>
        <th
          class="cabinet-history__text red"
          v-if="item.status === 'COURIER_WAREHOUSE_REFUND'">
          {{ $t("curaCancelCourier") }}
        </th>
        <th class="cabinet-history__text red" v-if="item.status === 'RETURNED'">
          {{ $t("getBack") }}
        </th>
        <th class="cabinet-history__text" v-if="item.status === 'COLLECTED'">
          {{ $t("collected") }}
        </th>
        <th
          class="cabinet-history__text red"
          v-if="item.status === 'IN_CANCEL'">
          {{ $t("canceled") }}
        </th>
        <th class="cabinet-history__text">{{ item.total_sum }} ₸</th>
      </tr>
    </div>
  </table>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AppHistory",
  data() {
    return {
      history: "",
    };
  },
  created() {
    api.getOrder().then((res) => {
      this.history = res.data;
    });
  },
};
</script>

<style scoped>
.cabinet-history__titles {
  background: #f2f2f2;
  border-radius: 15px;
  padding: 15px 17px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.cabinet-history__title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #3a4753;
  grid-column: span 1;
}

th {
  width: 150px;
  text-align: center;
}

th:last-child {
  text-align: end;
}

.cabinet-history__content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  padding: 15px 17px;
  border-bottom: 2px solid #d9d9d9;
  transition: all;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
}

.cabinet-history__content:hover {
  background: #d9d9d9;
}

.cabinet-history__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
}

.cabinet-history__content__loader {
  height: 52px;
  width: 100%;
  /* background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed; */
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
  border-radius: 8px;
}

.cabinet-history__text:first-child {
  text-decoration-line: underline;
}

.cabinet-history__text:nth-child(3) {
  color: #3ecd79;
}

.yellow {
  color: orange !important;
}

.red {
  color: red !important;
}

.black {
  font-weight: 700;
  color: #3a4753 !important;
}

@media screen and (max-width: 640px) {
  th {
    width: 120px;
  }
  .cabinet-history__title {
    text-align: end;
  }

  .cabinet-history__title:first-child {
    text-align: start;
  }

  .cabinet-history__title:nth-child(3) {
    text-align: end;
  }

  .cabinet-history__text:first-child {
    text-align: start;
  }
  .cabinet-history__text {
    text-align: end;
  }
}
</style>
