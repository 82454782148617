<template>
  <div :class="checked ? 'cart-left__info pl' : 'cart-left__info'">
    <div class="cart-checked" v-if="checked">
      <input
        type="checkbox"
        checked
        :name="'default' + index"
        :id="'default' + index"
        style="display: none"
      />
      <label :for="'default' + index" class="modal-default__label">
        <div class="modal-default__label__div"></div>
      </label>
    </div>
    <div class="cart-left__products">
      <img :src="item.product.product_image" alt="" class="cart-left__img" />
      <div class="cart-info">
        <p class="cart-left__name">
          {{
            $t("lang") === "kz" ? item.product.name_kaz : item.product.name_rus
          }}
        </p>
        <p class="cart-left__mass">{{ item.product.weight }} г</p>
        <div class="mobile-cart__info">
          <p class="mobile-cart__price">
            1 шт / {{ price }} ₸ <br />
            {{ oneSum }} ₸
          </p>
          <div class="mobile-cart__btn">
            <button @click="minus()" class="mobile-cart__minus">
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 8H14" stroke="#3A4753" />
              </svg>
            </button>
            {{ count }} шт
            <button @click="plus()" class="mobile-cart__plus">
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6.5 0.5V13.5M0 7H13" stroke="#3A4753" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="cart-left__prices" v-if="item.product.discount_price">
      <p class="cart-left__old__price">{{ item.product.price }} ₸ /шт</p>
      <p class="cart-left__new__price">
        {{ item.product.discount_price }} ₸ /шт
      </p>
    </div>
    <div class="cart-left__prices" v-else>
      <!-- <p class="cart-left__old__price">{{ item.product.price }} ₸ /шт</p> -->
      <p class="cart-left__new__price">{{ item.product.price }} ₸ /шт</p>
    </div>
    <div class="cart-left__counts">
      <button @click="minus()">
        <svg
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 8H14" stroke="#3A4753" />
        </svg>
      </button>
      {{ count }}
      <button @click="plus()">
        <svg
          width="13"
          height="14"
          viewBox="0 0 13 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6.5 0.5V13.5M0 7H13" stroke="#3A4753" />
        </svg>
      </button>
    </div>
    <p class="cart-left__sum" style="text-align: center">{{ oneSum }} ₸</p>
    <button
      class="cart-left__delete"
      v-if="!checked"
      @click="handleDelete(item.id)"
    >
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1.5 1.5L13.5 13.5M1.5 13.5L13.5 1.5" stroke="black" />
      </svg>
    </button>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AppCartInfo",
  props: ["checked", "index", "item"],
  data() {
    return {
      count: this.item.quantity,
      price: this.item.product.discount_price
        ? this.item.product.discount_price
        : this.item.product.price,
      oneSum: this.item.amount,
    };
  },
  methods: {
    minus() {
      if (this.count > 0) {
        this.count--;
        api.putCart(this.item.id, {
          quantity: this.count,
          product: this.item.product.id,
        });
        this.oneSum = this.count * this.price;
        this.$emit("sum", { type: "minus", price: this.price });
      }
    },
    plus() {
      this.count++;
      api.putCart(this.item.id, {
        quantity: this.count,
        product: this.item.product.id,
      });
      this.oneSum = this.count * this.price;
      this.$emit("sum", { type: "plus", price: this.price });
    },
    handleDelete(id) {
      api.delCart(id).then(() => {
        this.$emit("refresh");
      });
    },
  },
};
</script>

<style>
.cart-checked {
  position: absolute;
  left: -50px;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .pl {
    padding-left: 50px;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .cart-checked {
    left: -15px;
  }
  .pl {
    padding-left: 25px;
  }
}
</style>
