<!-- @format -->

<template>
  <header class="container">
    <div class="mobile-header__box">
      <h2 class="mobil-header_h2">КАТАЛОГ</h2>
      <p class="mobil-header_text">
        {{ $t("mainPageText") }}
      </p>
      <label for="address">
        <input
          type="text"
          id="address"
          :placeholder="$t('street')"
          class="mobile-header__address" />
      </label>
      <div class="mobile-header__flex">
        <input
          type="text"
          id="search"
          :placeholder="$t('find')"
          class="mobile-header__search"
          v-model="searchText" />
        <button @click="$emit('isModal')" class="mobile-header__btn">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect y="2" width="18" height="2" rx="1" fill="#F7931E" />
            <rect y="8" width="18" height="2" rx="1" fill="#F7931E" />
            <rect y="14" width="18" height="2" rx="1" fill="#F7931E" />
            <rect x="12" y="2" width="2" height="2" fill="white" />
            <circle cx="13" cy="3" r="2" stroke="#F7931E" stroke-width="2" />
            <rect x="4" y="8" width="2" height="2" fill="white" />
            <circle cx="5" cy="9" r="2" stroke="#F7931E" stroke-width="2" />
            <rect x="12" y="14" width="2" height="2" fill="white" />
            <circle cx="13" cy="15" r="2" stroke="#F7931E" stroke-width="2" />
          </svg>
        </button>
        <router-link to="/wishlist" class="mobile-header__btn">
          <bookmark v-bind:fill="bookmarkFill" />
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
import Bookmark from "@/icon_components/Bookmark.vue";
export default {
  name: "AppMobileHeader",
  components: { Bookmark },
  data() {
    return {
      bookmarkFill: "none",
      searchText: this.$route.query.search || "",
    };
  },
  watch: {
    "$route.query.search": {
      handler() {
        this.searchText = "";
      },
    },
    searchText: {
      handler() {
        this.$router.push({ path: "/", query: { search: this.searchText } });
      },
    },
  },
  created() {
    console.log(this.$route.query.search);
  },
};
</script>

<style>
.mobil-header-box {
  width: 100%;
  padding-bottom: 10px;
  height: fit-content;
}
.mobil-header_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 149.4%;
  text-align: center;
  color: #39424b;
  margin: 19px 0;
}
.mobil-header_text {
  max-width: 205px;
  margin: 0 auto;
  display: block;
  margin-bottom: 26px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 149.4%;
  text-align: center;
  color: #3a4753;
}
.mobile-header__address {
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 10px 16px;
}
.mobile-header__search {
  width: 70%;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 10px 16px;
}
.mobile-header__flex {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 17px;
}
.mobile-header__btn {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.1);
  border-radius: 10px;
}
</style>
