<!-- @format -->

<template>
  <section class="container">
    <div class="second-banner__loader" v-if="!banners"></div>
    <swiper
      class="swiper"
      :slides-per-view="1"
      :pagination="{ clickable: true }"
      v-if="banners.length > 0"
      :autoplay="{
        delay: 4000,
        disableOnInteraction: false,
      }">
      <swiper-slide v-for="(banner, index) in banners" :key="index">
        <div class="second-banner__block">
          <h2 class="banner-content__h2" style="max-width: 100%">
            {{ banner.title }}
          </h2>
          <p class="banner-content__text limit-3" style="max-width: 50%">
            {{ banner.text }}
          </p>

          <a :href="banner.link" class="banner-content__btn">{{
            banner.button_text
          }}</a>
        </div>

        <img :src="banner.image" alt="" class="swiper-image" />
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import { Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import api from "@/api/api";

SwiperCore.use([Pagination, Autoplay]);

export default {
  name: "AppSwiperMenu",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      banners: "",
      autoplay: {
        delay: 500,
        disableOnInteraction: false,
      },
    };
  },
  created() {
    api.getSecondBanner().then((res) => {
      this.banners = res.data.results;
    });
  },
};
</script>

<style>
.swiper-pagination {
  width: 100%;
  max-width: 1216px !important;
  margin: 0 auto;
  right: 0;
  text-align: center;
  bottom: 10px;
}

.swiper {
  width: 100%;
  height: 320px;
  border-radius: 20px;
  overflow: hidden;
}
.swiper-slide {
  width: 100%;
  height: 320px;
  border-radius: 20px;
}

.swiper-pagination-bullet {
  background: white;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background: #3ecd79;
}

.swiper-image {
  width: 100%;
  height: 100%;
  object-position: center;
}

.second-banner__block {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.limit-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  box-orient: vertical;
}

.second-banner__loader {
  width: 100%;
  height: 320px;
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.1);
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
</style>
