<!-- @format -->

<template>
  <div>
    <cabinet-modal
      v-if="changeAddress.address"
      :data="changeAddress"
      :id="user_id"
      :modalData="modalData"
      @isModal="changedModal" />
    <div class="container">
      <p class="path">
        {{ $t("mainPage") }}/ {{ $t("cart") }} / {{ $t("oformitZakaz") }}
      </p>
      <div class="mobile-cart__header">
        <button @click="page" class="mobile-arrow">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: rotate(180deg)">
            <path
              d="M5 1L12 7.5L5 14"
              stroke="#F9AB50"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </button>
        <h1 class="mobile-h1">{{ $t("oformitZakaz") }}</h1>
      </div>
      <div class="box">
        <h2 class="h2">{{ $t("oformitZakaz") }}</h2>
        <div class="cart-flex">
          <div class="cart-left">
            <div class="loader-address" v-if="loader"></div>
            <div class="address-box" v-else-if="data">
              <Address
                v-for="(item, index) in data.addresses"
                :key="item.id"
                :address="item"
                :index="index"
                @changeInfo="changeModal" />
            </div>
            <div>
              <div class="address-form">
                <p class="address-form__name">{{ $t("dateDelivery") }}</p>
                <div class="address-form__flex mt-20">
                  <div v-for="(item, index) in dates" :key="item.date">
                    <input
                      type="radio"
                      name="date"
                      :id="'date' + item.date"
                      class="address-input__date"
                      style="display: none"
                      :checked="index === dateIndex" />
                    <label
                      @click="
                        (dateIndex = index),
                          (times = item.windows.filter(
                            (filt) => filt.is_available === true
                          )),
                          (order.date.day = item.split('-')[2]),
                          (order.date.month = item.split('-')[1]),
                          (order.date.year = item.split('-')[0])
                      "
                      :for="'date' + item.date"
                      class="address-form__label">
                      {{ item.date }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="address-form">
                <p class="address-form__name">Время доставки</p>
                <div class="address-form__flex mt-20" v-if="loader">
                  <div class="address-form__label__loader"></div>
                  <div class="address-form__label__loader"></div>
                  <div class="address-form__label__loader"></div>
                </div>
                <div class="address-form__flex mt-20">
                  <div v-for="time in times" :key="time.id">
                    <input
                      type="radio"
                      name="time"
                      :id="'time' + time.id"
                      class="address-input__date"
                      style="display: none" />
                    <label
                      @click="
                        (order.delivery_window = time.id),
                          (order.start_time = time.start_time),
                          (order.end_time = time.end_time),
                          (errorMessage = '')
                      "
                      :for="'time' + time.id"
                      class="address-form__label"
                      style="padding: 10px 21px">
                      {{
                        time.start_time
                          .split("T")[1]
                          .split("+")[0]
                          .split(":")[0]
                      }}:{{
                        time.start_time
                          .split("T")[1]
                          .split("+")[0]
                          .split(":")[1]
                      }}
                      -
                      {{
                        time.end_time.split("T")[1].split("+")[0].split(":")[0]
                      }}:{{
                        time.end_time.split("T")[1].split("+")[0].split(":")[1]
                      }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="address-form" v-if="!isMobile">
                <p class="address-form__name">{{ $t("payType") }}</p>
                <div class="address-form__flex mt-20">
                  <input
                    type="radio"
                    name="pay"
                    id="pay1"
                    class="address-input__date"
                    style="display: none"
                    checked />
                  <label
                    @click="order.pay_type = 'online'"
                    for="pay1"
                    class="address-form__pay">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <rect
                        x="3"
                        y="6.5"
                        width="18"
                        height="13"
                        rx="2"
                        stroke="#3A4753"
                        stroke-width="2" />
                      <path
                        d="M7 15.5H7.01"
                        stroke="#3A4753"
                        stroke-width="2"
                        stroke-linecap="round" />
                      <path
                        d="M4 11.5H21"
                        stroke="#3A4753"
                        stroke-width="2"
                        stroke-linecap="round" />
                    </svg>
                    {{ $t("payCard") }}
                  </label>
                  <input
                    type="radio"
                    name="pay"
                    id="pay2"
                    class="address-input__date"
                    style="display: none" />
                  <label
                    @click="order.pay_type = 'card'"
                    for="pay2"
                    class="address-form__pay">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M8.8381 8.40946H15M8.8381 11.3571H9M11.8463 11.3571H12M14.8341 11.3571H15M8.8381 13.9286H9M11.8463 13.9286H12M14.8341 13.9286H15M7 16.5H17C17.5523 16.5 18 16.1162 18 15.6429V6.35714C18 5.88376 17.5523 5.5 17 5.5H7C6.44772 5.5 6 5.88376 6 6.35714V15.6429C6 16.1162 6.44772 16.5 7 16.5Z"
                        stroke="#3A4753"
                        stroke-width="2"
                        stroke-linecap="round" />
                      <mask id="path-2-inside-1_2387_80274" fill="white">
                        <rect x="7" y="15.5" width="10" height="5" rx="1" />
                      </mask>
                      <rect
                        x="7"
                        y="15.5"
                        width="10"
                        height="5"
                        rx="1"
                        stroke="#3A4753"
                        stroke-width="4"
                        mask="url(#path-2-inside-1_2387_80274)" />
                    </svg>
                    {{ $t("payCura") }}
                  </label>
                  <input
                    type="radio"
                    name="pay"
                    id="pay3"
                    class="address-input__date"
                    style="display: none" />
                  <label
                    @click="order.pay_type = 'cash'"
                    for="pay3"
                    class="address-form__pay">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.25 4.5H9.75C6.56802 4.5 4.97703 4.5 3.98851 5.50421C3 6.50841 3 8.12465 3 11.3571V13.6429C3 16.8753 3 18.4916 3.98851 19.4958C4.97703 20.5 6.56802 20.5 9.75 20.5H14.25C17.432 20.5 19.023 20.5 20.0115 19.4958C21 18.4916 21 16.8753 21 13.6429V11.3571C21 8.12465 21 6.50841 20.0115 5.50421C19.023 4.5 17.432 4.5 14.25 4.5Z"
                        stroke="#3A4753"
                        stroke-width="2" />
                      <path
                        d="M7 8.5H10"
                        stroke="#3A4753"
                        stroke-width="2"
                        stroke-linecap="round" />
                      <path
                        d="M19 16.5H17C16.0572 16.5 15.5858 16.5 15.2929 16.2071C15 15.9142 15 15.4428 15 14.5C15 13.5572 15 13.0858 15.2929 12.7929C15.5858 12.5 16.0572 12.5 17 12.5H19C19.9428 12.5 20.4142 12.5 20.7071 12.7929C21 13.0858 21 13.5572 21 14.5C21 15.4428 21 15.9142 20.7071 16.2071C20.4142 16.5 19.9428 16.5 19 16.5Z"
                        stroke="#3A4753"
                        stroke-width="2" />
                    </svg>
                    {{ $t("payCuraNalom") }}
                  </label>
                </div>
              </div>
              <div class="address-form">
                <p class="address-form__name">
                  {{ $t("comment") }}
                </p>
                <div class="address-form__flex mt-20">
                  <textarea
                    name=""
                    id=""
                    rows="7"
                    class="address-form__textarea"
                    v-model="order.comment"></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="cart-right">
            <p class="cart-right__info">{{ $t("sumToPay") }}</p>
            <div class="cart-right__flex">
              <p class="cart-right__text">{{ $t("productPrice") }}</p>
              <p class="cart-right__sum">{{ price }} ₸</p>
            </div>
            <div class="cart-right__flex">
              <p class="cart-right__text">
                {{ $t("delivery") }}

                <span class="cart-right__span">{{ $t("from1000") }}</span>
                <br />
                <span class="cart-right__sale">{{ $t("from1000") }}</span>
              </p>
              <p class="cart-right__sum">{{ $t("free") }}</p>
            </div>
            <div class="cart-right__flex">
              <p class="cart-right__text">{{ $t("spendBonus") }}</p>
              <div class="cart-right__bonus" v-if="bonus > 0">
                <input
                  type="checkbox"
                  id="bonus"
                  style="display: none"
                  v-model="order.is_spend_bonus" />
                <label for="bonus"></label>
                <p class="cart-right__sum">{{ bonus }} б</p>
              </div>
            </div>
            <div class="cart-right__flex" v-if="posible_bonus > 0">
              <p class="cart-right__text">{{ $t("getBonus") }}</p>
              <p class="cart-right__sum">+{{ posible_bonus }} ₸</p>
            </div>
            <div class="cart-border"></div>
            <div class="cart-right__flex">
              <p class="cart-right__sum">{{ $t("itogo") }}:</p>
              <p class="cart-right__sum">{{ price }} ₸</p>
            </div>
            <button @click="handleOrder" class="cart-right__btn mt-18">
              {{ $t("oformit") }}

              {{ price }} т.
            </button>
            <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CabinetModal from "@/components/cabinet/CabinetModal.vue";
import Address from "@/components/address/Address.vue";
import api from "@/api/api";
export default {
  components: { Address, CabinetModal },
  name: "AppOrder",
  data() {
    return {
      isMobile: false,
      data: null,
      loader: false,
      times: "",
      user_id: "",
      price: "",
      dateIndex: 0,
      date: [],
      dates: [],
      bonus: 0,
      posible_bonus: 0,
      errorMessage: "",
      dateNumberForm: [],
      order: {
        is_spend_bonus: false,
        delivery_window: 0,
        pay_type: "online",
        comment: "",
        address_id: "",
        start_time: "",
        end_time: "",
        date: {
          day: "1",
          month: "8",
          year: "2023",
        },
      },
      changeAddress: {
        address: "",
      },
      isLeave: false,
      modalData: {
        modal: false,
        type: this.$t("deleteAddress"),
      },
    };
  },
  watch: {
    dateIndex: {
      handler() {
        this.order.date = this.dateNumberForm[this.dateIndex];
      },
    },
    "order.is_spend_bonus": {
      handler() {
        api.UpdateBonus(this.order.is_spend_bonus).then(() => {
          api.getCart().then((res) => {
            this.order.is_spend_bonus = res.data.is_spend_bonus;
            this.posible_bonus = res.data.possible_bonus;
            this.bonus = res.data.bonus;
            this.price = res.data.total_price;
          });
        });
      },
    },
    // times: {
    //   handler() {
    //     this.times = this.times.filter((filt) => filt.is_available === true);
    //   },
    // },
  },
  methods: {
    handleOrder() {
      if (this.order.start_time) {
        api
          .addOrder(this.order)
          .then(() => {
            localStorage.setItem("count", 0);
            this.$store.dispatch("increment");
            this.$router.push("/cabinet?tab=2");
          })
          .catch(() => {
            this.errorMessage = this.$t("pleaseGetAddress");
          });
      } else {
        this.errorMessage = this.$t("pleaseGetTime");
      }
    },

    page() {
      this.$router.go(-1);
    },
    changeModal(data) {
      this.modalData.modal = true;
      this.changeAddress.address = data;
    },
    changedModal(data) {
      this.modalData.modal = data.modal;
      this.getUserInformation();
      if (data) {
        this.changeAddress = data;
      }
    },
    resize() {
      if (window.innerWidth < 640) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    getUserInformation() {
      this.loader = true;
      api
        .getUser()
        .then((res) => {
          this.loader = false;
          this.data = res.data;
          this.user_id = res.data.id;
          if (this.data.addresses) {
            this.data.addresses.forEach((el) => {
              if (el.is_main) {
                this.order.address_id = el.id;
              }
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize, { passive: true });
  },
  created() {
    api.getDelivery().then((res) => {
      this.dates = res.data;
      this.times = res.data[0].windows.filter(
        (filt) => filt.is_available === true
      );
    });
    api.getCart().then((res) => {
      this.order.is_spend_bonus = res.data.is_spend_bonus;
      this.posible_bonus = res.data.possible_bonus;
      this.bonus = res.data.bonus;
      this.price = res.data.total_price;
    });
    if (!this.$cookie.get("access")) {
      this.$router.push("/login");
    }
    this.getUserInformation();
  },
};
</script>

<style>
.mt-20 {
  margin-top: 20px;
}
.address-box {
  flex-wrap: wrap;
  gap: 15px;
}
.address-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.address-block {
  width: 371px;
  padding: 21px 13px 22px 25px;
  background: #fff;
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.1);
  border-radius: 20px;
}

.address-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.address-info__btns {
  display: flex;
  align-items: center;
  column-gap: 16px;
}
.address-info__name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 170%;
  text-transform: uppercase;
  color: #3a4753;
}
.address-info__btn {
  width: 40px;
  height: 40px;
  background: #f2f2f2;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.address-content {
  margin-top: 15px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.address-content__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #3a4753;
}
.address-main {
  padding: 7px 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #f9ab50;
  background: #f2f2f2;
  border-radius: 10px;
}
.address-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.address-form {
  margin-top: 36px;
}
.address-form__name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 170%;
  text-transform: uppercase;
  color: #3a4753;
}
.address-form__input {
  margin-top: 20px;
  width: 100%;
  padding: 15px 20px;
  border: 1px solid #979ea6;
  border-radius: 25px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3a4753;
}
.address-form__input::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #979ea6;
}
label {
  position: relative;
}
.address-form__svg {
  padding: 20px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.address-form__inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  row-gap: 15px;
  column-gap: 5px;
}
.address-form__small__input {
  max-width: 180px;
  padding: 15px 20px;
  border: 1px solid #979ea6;
  border-radius: 25px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3a4753;
}
.address-form__flex {
  display: flex;
  align-items: center;
  gap: 14px;
  flex-wrap: wrap;
  width: 99%;
}
.address-input__date {
  padding: 10px 15px;
}
.address-form__label {
  display: flex;
  padding: 10px 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #3a4753;
  background: #f9e8c1;
  border-radius: 8px;
  cursor: pointer;
  transition: all;
  transition-duration: 200ms;
}

.error {
  color: red;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.address-form__label__loader {
  width: 120px;
  height: 40px;
  border-radius: 8px;
  background-color: #f9e8c1;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #f9e8c1;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

.address-form__flex input[type="radio"]:checked + .address-form__label {
  background: #ffcc51;
}

.address-form__pay {
  padding: 23px 27px;
  background: #dbf5e6;
  border-radius: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #3a4753;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.address-form__flex input[type="radio"]:checked + .address-form__pay {
  background: #3ecd79;
  color: white;
}

.address-form__flex input[type="radio"]:checked + .address-form__pay svg path {
  stroke: #fff;
}
.address-form__flex input[type="radio"]:checked + .address-form__pay svg rect {
  stroke: #fff;
}

.address-form__textarea {
  outline: none;
  padding: 10px 15px;
  border: 1px solid #979ea6;
  border-radius: 25px;
  width: 100%;
}

.mt-18 {
  margin-top: 18px;
}

.mobile-arrow {
  position: absolute;
  top: 50%;
  left: 0;
}

.h2 {
  display: block;
}

.loader-address {
  max-width: 371px;
  height: 205px;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.1);
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

.cart-right__bonus {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cart-right__bonus input[type="checkbox"] + label {
  width: 37px;
  height: 22px;
  border-radius: 50px;
  background: #d3dadc;
  transition: all;
  transition-duration: 300ms;
  position: relative;
  display: block;
}

.cart-right__bonus input[type="checkbox"]:checked + label {
  background: #3ecd79;
}

.cart-right__bonus input[type="checkbox"] + label::after {
  content: "";
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 50%;
  right: 45%;
  transform: translateY(-50%);
  transition: all;
  transition-duration: 200ms;
}

.cart-right__bonus input[type="checkbox"]:checked + label::after {
  right: 4px !important;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .address-block {
    padding: 17px 15px;
    width: 100%;
    box-shadow: 1px 3px 21px -3px rgba(0, 0, 0, 0.15);
  }
  .h2 {
    display: none;
  }
  .address-form__small__input {
    width: 100%;
    max-width: 100%;
  }
  .loader-address {
    max-width: 100%;
  }
}
</style>
