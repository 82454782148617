<!-- @format -->

<template>
  <div class="main">
    <div
      v-if="isMobile"
      class="modal-back"
      style="background: rgba(33, 38, 45, 0.73)"
      :style="isModal ? 'display:block' : 'display:none'"
      @click="isModal = !isModal"></div>
    <div
      v-if="isMobile"
      class="modal-down"
      @click.stop
      :style="
        isModal ? 'transform:translate(0)' : 'transform:translateY(100%)'
      ">
      <div class="modal-down__scroll">
        <h3 class="modal-h3">Какие продукты показывать?</h3>
        <div
          class="modal-checbox__content"
          v-for="filter in filters"
          :key="filter.name">
          {{ filter.name }}
          <div
            class="modal-checkbox__flex"
            v-for="item in filter.category_filters"
            :key="item.id">
            <input
              type="checkbox"
              :id="'checkbox' + item.id"
              style="display: none" />
            <label
              @click="addOrDeleteChoice(item.id)"
              :for="'checkbox' + item.id">
            </label>
            {{ item.name }}
          </div>
        </div>
        <button @click="handleFilter" class="modal-down__btn">Показать</button>
      </div>
    </div>
    <mobile-header
      @isModal="isModal = !isModal"
      v-if="isMobile && $route.path === '/'" />
    <Banner v-if="!isMobile" />
    <category-list v-if="isMobile" />
    <list :menu="results" v-if="results" />
    <div class="container" v-else-if="searchRes">
      <div class="menu">
        <div class="menu-box">
          <one-of-list
            v-for="item in searchRes"
            :key="item.id"
            :itemMenu="item" />
        </div>
      </div>
    </div>
    <list-loader v-else />

    <swiper-menu v-if="!isMobile" />
    <router-link to="/cart" class="category-btn__cart fixed-btn">
      <div class="category-cart">
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4 5L4.77143 7.57143M4.77143 7.57143L7.64335 17.1445C7.77024 17.5675 8.15957 17.8571 8.60117 17.8571H20C21.1046 17.8571 22 16.9617 22 15.8571V10.1429C22 8.7227 20.8487 7.57143 19.4286 7.57143H4.77143ZM19.4286 23C18.7185 23 18.1429 22.4244 18.1429 21.7143C18.1429 21.0042 18.7185 20.4286 19.4286 20.4286C20.1387 20.4286 20.7143 21.0042 20.7143 21.7143C20.7143 22.4244 20.1387 23 19.4286 23ZM9.14286 21.7143C9.14286 21.0042 9.71849 20.4286 10.4286 20.4286C11.1387 20.4286 11.7143 21.0042 11.7143 21.7143C11.7143 22.4244 11.1387 23 10.4286 23C9.71849 23 9.14286 22.4244 9.14286 21.7143Z"
            stroke="#fff"
            stroke-width="2"
            stroke-linecap="round" />
        </svg>
        <p class="category-cart__count">{{ this.$store.state.cartCount }}</p>
      </div>
      {{ $t("cart") }}
    </router-link>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
import List from "@/components/List.vue";
import SwiperMenu from "@/components/SwiperMenu.vue";
import CategoryList from "@/components/CategoryList.vue";
import api from "@/api/api";
import OneOfList from "@/components/OneOfList.vue";
import MobileHeader from "@/components/layout/MobileHeader.vue";
import ListLoader from "@/components/ListLoader.vue";

export default {
  name: "AppMainPage",
  components: {
    Banner,
    List,
    SwiperMenu,
    CategoryList,
    OneOfList,
    MobileHeader,
    ListLoader,
  },
  data() {
    return {
      isModal: false,
      isMobile: false,
      loader: false,
      results: "",
      favourites: "",
      searchRes: "",
      filters: "",
      choiceFilters: [],
    };
  },
  watch: {
    "$route.query.search": {
      handler() {
        if (this.$route.query.search) {
          api.getProduct(this.$route.query.search).then((res) => {
            this.searchRes = res.data.results;
            this.results = "";
          });
        } else {
          api.getRecomendations().then((res) => {
            let temp = res.data.results[0].category_products;
            this.results = temp.sort((a, b) => b.sort - a.sort);
            this.searchRes = "";
          });
        }
      },
    },
  },
  created() {
    console.log(this.$route.path);
    api
      .getProductFilters()
      .then((res) => {
        this.filters = res;
      })
      .catch((err) => {
        console.log(err.response.status);
      });
    if (this.$route.query.search) {
      this.loader = true;
      api.getProduct(this.$route.query.search).then((res) => {
        this.searchRes = res.data.results;
        this.results = "";
        this.loader = false;
      });
    } else {
      api.getRecomendations().then((res) => {
        this.results = res.data.results[0].category_products;
        this.searchRes = "";
        console.log(this.results, "results");
      });
    }
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize, { passive: true });
  },
  methods: {
    addOrDeleteChoice(id) {
      let isChoiced = false;
      this.choiceFilters.forEach((el) => {
        if (el === id) {
          isChoiced = true;
          this.choiceFilters.filter((filt) => filt !== id);
        }
      });
      if (!isChoiced) {
        this.choiceFilters.push(id);
      }
    },
    handleFilter() {
      this.loader = true;
      this.$router.push("/");
      if (this.choiceFilters.length >= 1) {
        api.getProductsByFilters(this.choiceFilters).then((res) => {
          this.searchRes = res.data.results;
          this.results = "";
          this.loader = false;
          this.isModal = false;
        });
      } else {
        api.getRecomendations().then((res) => {
          this.results = res.data.results[0].category_products;
          this.searchRes = "";
          console.log(this.results, "results");
        });
      }
    },
    resize() {
      if (window.innerWidth < 640) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
};
</script>

<style>
.fixed-btn {
  position: fixed;
  left: 50%;
  bottom: 80px;
  transform: translateX(-50%);
}

.modal-down__btn {
  padding: 13px;
  width: 100%;
  border-radius: 10px;
  background: #f9ab50;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.24px;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .main {
    padding-bottom: 50px;
  }
}
</style>
