<!-- @format -->

<template>
  <div>
    <div class="container">
      <p class="path">{{ $t("mainPage") }}/ {{ $t("oferta") }}</p>
      <div class="mobile-cart__header">
        <button @click="page" class="mobile-arrow">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: rotate(180deg)">
            <path
              d="M5 1L12 7.5L5 14"
              stroke="#F9AB50"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </button>
        <h1 class="mobile-h1">{{ $t("oferta") }}</h1>
      </div>
      <div class="box">
        <h2 class="h2">{{ $t("oferta") }}</h2>
        <p class="text" v-html="text"></p>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AppOferta",
  data() {
    return {
      text: "",
    };
  },
  methods: {
    page() {
      this.$router.go(-1);
    },
  },
  created() {
    api.getOferta().then((res) => {
      this.text = res.data.text;
    });
  },
};
</script>

<style>
.text {
  max-width: 75%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #3a4753;
}
</style>
