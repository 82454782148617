<!-- @format -->

<template>
  <div>
    <div class="modal-back" @click="$router.push('/')" v-if="isModal">
      <div class="modal-small" @click.stop>
        <h2 class="modal-small__h2">{{ $t("thanksReturn") }}</h2>
        <p class="modal-small__p" v-if="error">
          {{ error }}
        </p>
        <p class="modal-small__p" v-else>
          {{ $t("returnWill") }}
        </p>
        <button @click="$router.push('/')" class="modal-small__btn">Ок</button>
        <button @click="$router.push('/')" class="modal-close">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 1.5L13.5 13.5M1.5 13.5L13.5 1.5" stroke="black" />
          </svg>
        </button>
      </div>
    </div>
    <div class="container">
      <p class="path">
        {{ $t("mainPage") }}/{{ $t("cabinet") }} /
        {{ $t("returnTovar") }}
      </p>
      <div class="mobile-cart__header">
        <button @click="page" class="mobile-arrow">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: rotate(180deg)">
            <path
              d="M5 1L12 7.5L5 14"
              stroke="#F9AB50"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </button>
        <h1 class="mobile-h1">
          {{ $t("return") }}
        </h1>
      </div>
      <div class="box">
        <h2 class="h2">{{ $t("return") }}</h2>
        <div class="cart-flex">
          <div class="cart-left">
            <div class="cart-left__info pl-50">
              <p class="cart-left__text">{{ $t("product") }}</p>
              <p class="cart-left__text">{{ $t("price") }}</p>
              <p class="cart-left__text">
                {{ $t("count") }}
              </p>
              <p class="cart-left__text" style="text-align: center">
                {{ $t("sum") }}
              </p>
              <return-info
                :checked="true"
                v-for="(item, index) in returns.order_details"
                :key="item.number"
                :index="index"
                :item="item"
                @price="calculateTotalSum"
                @checked="checkRefundItem" />
            </div>
          </div>
          <div class="cart-right">
            <p class="cart-right__info">{{ $t("sumReturn") }}</p>
            <div class="cart-right__flex">
              <p class="cart-right__text">{{ $t("priceReturn") }}</p>
              <p class="cart-right__sum">{{ returns.total_sum }} ₸</p>
            </div>
            <div class="cart-border"></div>
            <div class="cart-right__flex">
              <p class="cart-right__sum">{{ $t("itogo") }}:</p>
              <p class="cart-right__sum">{{ returns.total_sum }} ₸</p>
            </div>
            <button
              @click="handleRefund"
              class="cart-right__btn"
              style="background: #f9ab50">
              {{ $t("returnText") }} {{ returns.total_sum }} т.
            </button>
          </div>
        </div>
        <div class="return">
          <p class="return-comment" v-if="!isMobile">{{ $t("comment") }}</p>
          <textarea
            v-if="!isMobile"
            class="return-textarea"
            name="returnComment"
            id="returnComment"
            cols="30"
            rows="7"
            v-model="comment"></textarea>
          <p class="return-text">
            {{ $t("returnText2") }}
          </p>
          <button class="return-text__info">
            {{ $t("returnText3") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReturnInfo from "@/components/ReturnInfo.vue";
import api from "@/api/api";
export default {
  components: { ReturnInfo },
  name: "AppReturn",
  data() {
    return {
      isMobile: false,
      isModal: false,
      error: "",
      returns: "",
      comment: "",
      products: [],
    };
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize, { passive: true });
  },
  methods: {
    calculateTotalSum(priceOne) {
      if (priceOne.type === "minus") {
        this.returns.total_sum -= priceOne.price;
        this.products.forEach((element) => {
          if (element.id === priceOne.id) {
            element.amount--;
          }
        });
      } else {
        this.returns.total_sum += priceOne.price;
        this.products.forEach((element) => {
          if (element.id === priceOne.id) {
            element.amount++;
          }
        });
      }
    },
    checkRefundItem(checkedOne) {
      if (checkedOne.type) {
        this.products.push({ id: checkedOne.id, amount: checkedOne.count });
      } else {
        this.products = this.products.filter(
          (filt) => filt.id !== checkedOne.id
        );
      }
    },
    resize() {
      if (window.innerWidth < 640) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    page() {
      this.$router.go(-1);
    },
    handleRefund() {
      api
        .refundOrder({
          order_id: parseInt(this.$route.params.id),
          products: this.products,
          comment: this.comment,
        })
        .then((res) => {
          this.error = res.data.error;
          this.isModal = true;
        });
    },
  },
  created() {
    if (this.$route.params.id) {
      const id = parseInt(this.$route.params.id);
      api
        .getOrderById(id)
        .then((res) => {
          this.returns = res.data;
          res.data.order_details.forEach((element) => {
            this.products.push({ id: element.id, amount: element.quantity });
          });
          console.log(this.products);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>

<style scoped>
.cart-right {
  width: 33%;
}
.return {
  margin-top: 30px;
  width: 100%;
}

.return-comment {
  margin-bottom: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 170%;
  text-transform: uppercase;
  color: #3a4753;
}

.return-textarea {
  outline: none;
  padding: 15px;
  width: 65%;
  border: 1px solid #979ea6;
  border-radius: 25px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
}

.return-text {
  margin-top: 36px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
}

.return-text__info {
  margin-top: 13px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #3ecd79;
}

.pl-50 {
  padding-left: 50px;
}

.modal-small {
  position: relative;
  width: 100%;
  max-width: 443px;
  margin: 0 auto;
  background: white;
  background: #ffffff;
  border-radius: 20px;
  padding: 66px 48px;
}

.modal-small__h2 {
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 170%;
  text-transform: uppercase;
  color: #3a4753;
}

.modal-small__p {
  margin: 24px 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 172.4%;
  text-align: center;
  color: #3a4753;
}

.modal-small__btn {
  margin: 0 auto;
  padding: 17px 63px;
  background: #3ecd79;
  border-radius: 100px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

@media screen and (min-width: 640px) and (max-width: 1024px) {
  .cart-right {
    width: 50%;
    margin: 0 auto;
  }
  .pl-50 {
    padding-left: 50px;
  }
}
@media screen and (min-width: 0px) and (max-width: 640px) {
  .modal-small {
    max-width: 100%;
    border-radius: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .cart-right {
    width: 100%;
    margin: 0 auto;
  }
  .pl-50 {
    padding-left: 25px;
  }
  .return-text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 148.4%;
    color: #979ea6;
  }
  .return-text__info {
    margin-top: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 148.4%;
    color: #3ecd79;
  }
}
</style>
