<!-- @format -->

<template>
  <div class="">
    <header class="header-faq">
      <p class="path">{{ $t("faq") }}</p>
      <div class="mobile-cart__header">
        <button @click="page">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: rotate(180deg)">
            <path
              d="M5 1L12 7.5L5 14"
              stroke="#F9AB50"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </button>
        <h1 class="mobile-h1 faq-title">{{ faq.subtitle }}</h1>
      </div>
    </header>
    <div class="container my-15">
      <p class="faq-text" v-html="faq.text"></p>
      <p class="faq-support">
        {{ $t("haveQuestion") }} <br />
        {{ $t("weHelp") }}
      </p>
      <div class="faq-btns">
        <button class="faq-btn">
          Почта
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none">
            <path
              d="M3.33464 3.33398H16.668C17.5846 3.33398 18.3346 4.08398 18.3346 5.00065V15.0007C18.3346 15.9173 17.5846 16.6673 16.668 16.6673H3.33464C2.41797 16.6673 1.66797 15.9173 1.66797 15.0007V5.00065C1.66797 4.08398 2.41797 3.33398 3.33464 3.33398Z"
              stroke="#F7931E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M18.3346 5L10.0013 10.8333L1.66797 5"
              stroke="#F7931E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </button>
        <router-link class="faq-btn" to="/profile/chat">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path
              d="M21 11.5C21.0034 12.8199 20.6951 14.1219 20.1 15.3C19.3944 16.7118 18.3098 17.8992 16.9674 18.7293C15.6251 19.5594 14.0782 19.9994 12.5 20C11.1801 20.0035 9.87812 19.6951 8.7 19.1L3 21L4.9 15.3C4.30493 14.1219 3.99656 12.8199 4 11.5C4.00061 9.92179 4.44061 8.37488 5.27072 7.03258C6.10083 5.69028 7.28825 4.6056 8.7 3.90003C9.87812 3.30496 11.1801 2.99659 12.5 3.00003H13C15.0843 3.11502 17.053 3.99479 18.5291 5.47089C20.0052 6.94699 20.885 8.91568 21 11V11.5Z"
              stroke="#3ECD79"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" /></svg
          >Чат
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppSubfaq",
  data() {
    return {
      faq: JSON.parse(this.$route.query.faq),
    };
  },
  methods: {
    page() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.header-faq {
  background: #e2e7ee;
  padding: 15px;
  padding-top: 0px;
}

.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.faq-text {
  color: #3a4753;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.faq-title {
  margin-top: 10px;
}

.faq-support {
  margin: 20px 0;
  color: #3a4753;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}

.faq-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.faq-btn {
  padding: 13px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #f2f2f2;
}
</style>
