<!-- @format -->

<template>
  <section>
    <div class="container">
      <div class="menu" v-for="item in menu" :key="item.id">
        <div class="menu-box__text">
          <h3 class="menu-box__h3">
            {{ $t("lang") === "kz" ? item.name_kaz : item.name_rus }}
          </h3>
          <router-link
            :to="
              '/catalog/' +
              item.category_id +
              '/' +
              item.products[0].subcategory_link
            "
            class="menu-box__btn"
            v-if="item.products[0]"
            >{{ $t("seeAll") }}</router-link
          >
        </div>
        <div class="menu-box">
          <oneOfList
            v-for="itemMenu in item.products"
            :key="itemMenu.id"
            :itemMenu="itemMenu"
            :favourite="favourite" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import OneOfList from "./OneOfList.vue";
export default {
  components: { OneOfList },
  name: "AppMainMenu",
  props: ["menu"],
  data() {
    return {
      isMobile: false,
      favourite: "",
    };
  },
  created() {
    this.favourite = JSON.parse(this.$cookie.get("favourite"));
  },
};
</script>

<style>
.menu {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin: 60px 0;
}
.menu-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}
.menu-box__text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu-box__h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 170%;
  color: #3a4753;
}
.menu-box__btn {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 170%;
  text-decoration-line: underline;
  color: #f9ab50;
}
.color-white {
  stroke: white;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .menu {
    margin: 32px 0;
  }
  .menu-box {
    gap: 8px;
  }
  .menu-box__h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    line-height: 21px;
    color: #3a4753;
    width: 60%;
  }
  .menu-box__btn {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 170%;
    text-decoration-line: underline;
    color: #3a4753;
  }
}
</style>
