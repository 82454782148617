<template>
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.4233 23.8871C22.0857 24.3546 23 23.8809 23 23.0701V7.00001C23 5.34316 21.6569 4.00001 20 4.00001L9 4C7.34315 4 6 5.34315 6 7V23.0701C6 23.8809 6.91427 24.3546 7.57668 23.887L13.9233 19.4071C14.269 19.163 14.731 19.163 15.0767 19.4071L21.4233 23.8871Z"
      stroke="#F9AB50"
      stroke-width="2"
      stroke-linecap="square"
    />
  </svg>
</template>

<script>
export default {
  name: "AppBookmarkIcon",
  props: ["fill"],
};
</script>

<style></style>
