<!-- @format -->

<template>
  <div class="">
    <header class="header-faq">
      <div class="mobile-cart__header">
        <button @click="page" class="mobile-arrow">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: rotate(180deg)">
            <path
              d="M5 1L12 7.5L5 14"
              stroke="#F9AB50"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </button>
        <h1 class="mobile-h1">{{ $t("settings") }}</h1>
      </div>
    </header>
    <div class="container">
      <form @submit.prevent="handleChange" class="change">
        <label for="firstName" class="change-label">
          {{ $t("yourName") }}
          <input
            type="text"
            id="firstName"
            class="change-input"
            :placeholder="$t('yourName')"
            v-model="first_name" />
        </label>
        <label for="lastName" class="change-label">
          {{ $t("yourSurname") }}

          <input
            type="text"
            id="lastName"
            class="change-input"
            :placeholder="$t('yourSurname')"
            v-model="last_name" />
        </label>
        <button type="submit" class="change-btn">
          {{ $t("apply") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AppSubfaq",
  data() {
    return {
      username: "",
      first_name: "",
      last_name: "",
      id: "",
    };
  },
  created() {
    if (!this.$cookie.get("access")) {
      this.$router.push("/login");
    }
    api.getUser().then((res) => {
      this.id = res.data.id;
      this.username = res.data.username;
      this.first_name = res.data.first_name;
      this.last_name = res.data.last_name;
    });
  },
  methods: {
    page() {
      this.$router.go(-1);
    },
    handleChange() {
      api
        .updateUser(this.id, {
          username: this.username,
          first_name: this.first_name,
          last_name: this.last_name,
        })
        .then(() => {
          this.$router.go(-1);
        });
    },
  },
};
</script>

<style scoped>
.header-faq {
  background: #e2e7ee;
  padding: 15px;
  padding-top: 0px;
}

.change {
  padding: 13px 15px;
  border-radius: 10px;
  background: var(--light, #f2f2f2);
  margin-top: 30px;
}

.change-label {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--font, #3a4753);
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-top: 15px;
}

.change-label:first-child {
  margin-top: 0px;
}

.change-input {
  padding: 7px 10px;
  border-radius: 50px;
  background: #fff;
  color: #3a4753;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.change-input::placeholder {
  color: #8b8c92;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.change-btn {
  margin: 20px auto;
  display: block;
  border-radius: 50px;
  background: #f9ab50;
  padding: 4px 13px;
  color: #fff;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
</style>
