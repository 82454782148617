<!-- @format -->

<template>
  <table class="cabinet-history">
    <tr class="cabinet-history__titles">
      <th class="cabinet-history__title">{{ $t("numberZakaz") }}</th>
      <th class="cabinet-history__title">{{ $t("dateDelivery") }}</th>
      <th class="cabinet-history__title">{{ $t("status") }}</th>
      <th class="cabinet-history__title text-center">{{ $t("sum") }}</th>
    </tr>
    <div>
      <return-history-one v-for="item in history" :key="item.id" :item="item" />
    </div>
  </table>
</template>

<script>
import api from "@/api/api";
import ReturnHistoryOne from "./ReturnHistoryOne.vue";
export default {
  components: { ReturnHistoryOne },
  name: "AppReturnHistory",
  data() {
    return {
      history: "",
    };
  },
  created() {
    api.getRefund().then((res) => {
      this.history = res.data;
    });
  },
};
</script>

<style scoped>
.cabinet-history__titles {
  background: #f2f2f2;
  border-radius: 15px;
  padding: 15px 17px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.cabinet-history__title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #3a4753;
  grid-column: span 1;
}

th {
  width: 150px;
  text-align: center;
}

th:last-child {
  text-align: end;
}

.cabinet-history__content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  padding: 15px 17px;
  border-bottom: 2px solid #d9d9d9;
  transition: all;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  position: relative;
}

.cabinet-history__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
}

.cabinet-history__content__loader {
  height: 52px;
  width: 100%;
  /* background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed; */
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
  border-radius: 8px;
}

.cabinet-history__text:first-child {
  text-decoration-line: underline;
}

.cabinet-history__text:nth-child(3) {
  color: #3ecd79;
}

.yellow {
  color: orange !important;
}

.red {
  color: red !important;
}

.black {
  font-weight: 700;
  color: #3a4753 !important;
}

.flex {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-end;
}

.text-center {
  margin-left: -30px;
}

tr {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.return-block {
  background: #f7f7f7;
  border-radius: 6px;
  padding: 15px;
  width: 100%;
  height: 100%;
  transition: all;
  transition-duration: 500ms;
  animation: opacity 500ms ease-in-out;
}

.return-block__one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.return-block__left {
  display: flex;
  align-items: center;
  gap: 15px;
}
.return-block__img {
  width: 85px;
  height: 68px;
  object-fit: cover;
}

.return-block__name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
}

.return-block__weight {
  margin-top: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #979ea6;
}

.return-block__right {
  display: flex;
  align-items: center;
  width: 35%;
  justify-content: space-between;
}

.return-block__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a4753;
}

.return-block__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.return-block__bottom {
  margin-bottom: 15px;
  border-bottom: 2px solid #d9d9d9;
}

@media screen and (max-width: 640px) {
  th {
    width: 120px;
  }
  .cabinet-history__title {
    text-align: end;
  }

  .cabinet-history__title:first-child {
    text-align: start;
  }

  .cabinet-history__title:nth-child(3) {
    text-align: end;
  }

  .cabinet-history__text:first-child {
    text-align: start;
  }
  .cabinet-history__text {
    text-align: end;
  }
}
</style>
