<!-- @format -->

<template>
  <section class="banner">
    <div class="banner-loader" v-if="!banners"></div>
    <swiper
      :slides-per-view="1"
      :pagination="{ clickable: true }"
      class="pagination-left"
      :autoplay="{
        delay: 4000,
        disableOnInteraction: false,
      }"
      v-else
    >
      <swiper-slide
        class="banner-item"
        v-for="(item, index) in banners"
        :key="index"
      >
        <img :src="item.image" alt="" class="banner_img" />
        <div class="container">
          <div class="banner-content">
            <p class="banner-content__text">{{ item.title }}</p>
            <h2 class="banner-content__h2">
              {{ item.text }}
            </h2>
            <a :href="item.link" class="banner-content__btn">{{
              $t("seeMore")
            }}</a>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import { Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import api from "@/api/api";
SwiperCore.use([Pagination]);

export default {
  name: "AppBaner",
  components: { Swiper, SwiperSlide },
  data() {
    return {
      banners: "",
    };
  },
  created() {
    api.getBanners().then((res) => {
      this.banners = res.data.results;
    });
  },
};
</script>

<style>
.swiper-container {
  position: relative;
}

.pagination-left .swiper-pagination {
  width: 100%;
  max-width: 1216px !important;
  margin: 0 auto;
  right: 0;
  text-align: left;
  bottom: 10px;
}

.banner-loader {
  max-width: 100%;
  height: 436px;
  width: 100%;
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.1);
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}

@media screen and (min-width: 0px) and (max-width: 1260px) {
  .pagination-left .swiper-pagination {
    padding: 0px 15px;
  }
}

.swiper-slide {
  position: relative;
}

.swiper-wrapper {
  width: 100%;
  height: 100%;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.banner {
  position: relative;
  overflow: hidden;
  max-height: 436px;
}

.banner-item {
  width: 100%;
  height: 436px !important;
}

.banner_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: 100%;
  object-fit: cover;
}
.banner-content {
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  z-index: 3;
  row-gap: 20px;
}
.banner-content__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
  z-index: 3;
}
.banner-content__h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 23.9905px;
  line-height: 170%;
  text-transform: uppercase;
  color: #ffffff;
  max-width: 345px;
  z-index: 3;
}
.banner-content__btn {
  background: #3ecd79;
  border-radius: 100px;
  padding: 17px 53px 17px 51px;
  width: fit-content;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  z-index: 3;
}

@media screen and (max-width: 1024px) {
  .banner-item {
    height: 356px !important;
  }
  .banner-loader {
    height: 356px;
  }
}
</style>
