<!-- @format -->

<template>
  <div>
    <cabinet-modal
      :data="changeAddress"
      :id="user_id"
      :modalData="modalData"
      @isModal="changeModal" />
    <div class="container">
      <p class="path">{{ $t("mainPage") }}/ {{ $t("cart") }}</p>
      <div class="mobile-cart__header">
        <button @click="page" class="mobile-arrow">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: rotate(180deg)">
            <path
              d="M5 1L12 7.5L5 14"
              stroke="#F9AB50"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </button>
        <h1 class="mobile-h1">{{ $t("addresses") }}</h1>
      </div>

      <Addresses :addresses="addresses" @isModal="changeModal" />
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import Addresses from "@/components/cabinet/Addresses.vue";
import CabinetModal from "@/components/cabinet/CabinetModal";
export default {
  components: { Addresses, CabinetModal },
  name: "AppCabinetAddress",
  data() {
    return {
      addresses: "",
      changeAddress: null,
      user_id: "",
      modalData: {
        modal: false,
        type: "",
      },
    };
  },
  created() {
    this.getUserInformation();
  },
  methods: {
    page() {
      this.$router.go(-1);
    },
    getUserInformation() {
      api.getUser().then((res) => {
        console.log(res);
        this.addresses = res.data.addresses;
      });
    },
    AdressModal() {
      this.$emit("isModal", {
        modal: true,
        type: this.$t("addAddress"),
        address: "",
      });
    },
    change(data) {
      this.$emit("isModal", {
        modal: true,
        type: this.$t("deleteAddress"),
        address: data,
      });
    },
    changeModal(data) {
      this.modalData.modal = data.modal;
      this.modalData.type = data.type;
      if (data) {
        this.changeAddress = data;
      }
      if (data.status) {
        this.getUserInformation();
      }
    },
  },
};
</script>

<style>
.cabinet-address__flex {
  display: flex;
  align-items: center;
  gap: 28px;
  flex-wrap: wrap;
}

.cabinet-address__btn {
  padding: 13px 45px;
  background: #3ecd79;
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 60px;
}
</style>
