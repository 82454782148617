<template>
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 18.3996H20M3.8 9.99961V18.3996M18.2 9.99961V18.3996M3.2 1.59961H18.8M6.2 9.99961V11.5996C6.2 12.7042 7.09543 13.5996 8.2 13.5996H13.8C14.9046 13.5996 15.8 12.7042 15.8 11.5996V9.99961M3.8 3.99961H18.2L20.4005 6.75022C21.4481 8.05975 20.5158 9.99961 18.8388 9.99961H3.39391C1.76573 9.99961 0.819828 8.15799 1.76828 6.83457L3.8 3.99961Z"
      stroke="#3ECD79"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "AppShopIcon",
};
</script>

<style></style>
