<template>
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.5 11.8571C11.064 11.8571 12.3333 10.6411 12.3333 9.14282C12.3333 7.64454 11.064 6.42855 9.5 6.42855C7.936 6.42855 6.66667 7.64454 6.66667 9.14282C6.66667 10.6411 7.936 11.8571 9.5 11.8571Z"
      stroke="#F9AB50"
      stroke-width="2"
      stroke-linecap="square"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 9.14282C18 15.9285 10.9167 20 9.5 20C8.08333 20 1 15.9285 1 9.14282C1 4.64661 4.80658 1 9.5 1C14.1934 1 18 4.64661 18 9.14282Z"
      stroke="#F9AB50"
      stroke-width="2"
      stroke-linecap="square"
    />
  </svg>
</template>

<script>
export default {
  name: "AppPinIcon",
};
</script>

<style></style>
