<!-- @format -->

<template>
  <div class="map">
    <google-maps-map
      :center="{
        lat: shops[0].address.latitude,
        lng: shops[0].address.longitude,
      }"
      :zoom="12"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      }"
      map-type-id="terrain"
      style="width: 100%; height: 100%">
      <google-maps-marker
        v-for="shop in shops"
        :key="shop.id"
        :clickable="true"
        :position="{
          lat: shop.address.latitude,
          lng: shop.address.longitude,
        }">
      </google-maps-marker>
    </google-maps-map>
    <div class="shops" v-if="!isMobile">
      <label for="search" class="shops-search">
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.5 15.5L11.5 11.5M7.5 13.5C4.18629 13.5 1.5 10.8137 1.5 7.5C1.5 4.18629 4.18629 1.5 7.5 1.5C10.8137 1.5 13.5 4.18629 13.5 7.5C13.5 10.8137 10.8137 13.5 7.5 13.5Z"
            stroke="#3ECD79"
            stroke-width="2" />
        </svg>

        <input
          type="text"
          class="shops-search__input"
          id="search"
          :placeholder="$t('find')"
          v-model="search" />
      </label>
      <div class="shops-address" v-for="shop in shops" :key="shop.id">
        <h3 class="shops-address__city">
          {{ shop.name }}, {{ shop.address.city }}
        </h3>
        <div class="shops-address__info">
          <svg
            width="16"
            height="16"
            viewBox="0 0 19 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.5 11.8571C11.064 11.8571 12.3333 10.6411 12.3333 9.14282C12.3333 7.64454 11.064 6.42855 9.5 6.42855C7.936 6.42855 6.66667 7.64454 6.66667 9.14282C6.66667 10.6411 7.936 11.8571 9.5 11.8571Z"
              stroke="#3ECD79"
              stroke-width="2"
              stroke-linecap="square" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18 9.14282C18 15.9285 10.9167 20 9.5 20C8.08333 20 1 15.9285 1 9.14282C1 4.64661 4.80658 1 9.5 1C14.1934 1 18 4.64661 18 9.14282Z"
              stroke="#3ECD79"
              stroke-width="2"
              stroke-linecap="square" />
          </svg>
          ул. {{ shop.address.street }}, {{ shop.address.building }}
        </div>
        <div class="shops-address__info">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.5 4.14286V8L11.2857 10.5714M8.5 14C4.91015 14 2 11.3137 2 8C2 4.68629 4.91015 2 8.5 2C12.0899 2 15 4.68629 15 8C15 11.3137 12.0899 14 8.5 14Z"
              stroke="#3ECD79"
              stroke-width="2"
              stroke-linejoin="round" />
          </svg>

          {{ shop.start_time.split("T")[1].split("+")[0].split(":")[0] }}:{{
            shop.start_time.split("T")[1].split("+")[0].split(":")[1]
          }}
          - {{ shop.end_time.split("T")[1].split("+")[0].split(":")[0] }}:{{
            shop.end_time.split("T")[1].split("+")[0].split(":")[1]
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import { gmapApi } from "vue2-google-maps";

export default {
  name: "AppYandexMap",
  props: ["shops"],
  computed: {
    google: gmapApi,
  },
  data() {
    return {
      search: "",
      isMobile: false,
      coords: { lat: 43.2567, lng: 76.9286 },
      tab: 2,
      icon: {
        layout: "default#imageWithContent",
        imageHref: "https://damdala-front.a-lux.dev/Pin_fill.svg",
        imageSize: [43, 43],
        imageOffset: [0, 0],
      },
    };
  },
  // computed: {
  //   balloonTemplate() {
  //     return `
  //       <h1 class="red">Hi, everyone!</h1>
  //       <p>I am here: ${this.coords}</p>
  //       <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  //         <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 11.8571C11.064 11.8571 12.3333 10.6411 12.3333 9.14282C12.3333 7.64454 11.064 6.42855 9.5 6.42855C7.936 6.42855 6.66667 7.64454 6.66667 9.14282C6.66667 10.6411 7.936 11.8571 9.5 11.8571Z" stroke="#F9AB50" stroke-width="2" stroke-linecap="square"/>
  //         <path fill-rule="evenodd" clip-rule="evenodd" d="M18 9.14282C18 15.9285 10.9167 20 9.5 20C8.08333 20 1 15.9285 1 9.14282C1 4.64661 4.80658 1 9.5 1C14.1934 1 18 4.64661 18 9.14282Z" stroke="#F9AB50" stroke-width="2" stroke-linecap="square"/>
  //       </svg>

  //     `;
  //   },
  // },
  watch: {
    search: {
      handler() {
        this.$emit("search", this.search);
      },
    },
  },
  methods: {
    page() {
      this.$router.go(-1);
    },
    resize() {
      if (window.innerWidth < 640) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize, { passive: true });
  },
  created() {
    api.getMarket().then((res) => {
      console.log(res, "oiwrhgoirgoierhg");
    });
  },
};
</script>

<style>
.map {
  width: 100%;
  height: 100vh;
  position: relative;
}
</style>
