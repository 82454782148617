<!-- @format -->

<template>
  <div>
    <div class="cabinet-info">
      <div class="cabinet-info__half">
        <label for="name" class="cabinet-info__label">
          <p class="cabinet-info__p">{{ $t("yourName") }}</p>
          <input
            type="text"
            id="name"
            name="name"
            class="cabinet-info__input__full"
            required
            :placeholder="$t('yourName')"
            v-model="first_name" />
        </label>
        <label for="name" class="cabinet-info__label">
          <p class="cabinet-info__p">{{ $t("yourSurname") }}</p>
          <input
            type="text"
            id="name"
            name="name"
            class="cabinet-info__input__full"
            required
            :placeholder="$t('yourSurname')"
            v-model="last_name" />
        </label>
      </div>
      <div class="cabinet-info__half">
        <label for="phone" class="cabinet-info__label">
          <p class="cabinet-info__p">{{ $t("mobPhone") }}</p>
          <input
            type="text"
            id="phone"
            name="phone"
            class="cabinet-info__input__full"
            placeholder="+7 | _ _ _  -  _ _  -  _ _"
            v-mask="'+7 (###) ###-##-##'"
            required
            v-model="username" />
        </label>
      </div>
    </div>
    <button @click="handleChange" class="cabinet-info__submit">
      {{ $t("save") }}
    </button>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AppInfo",
  props: ["data"],
  data() {
    return {
      username: "",
      first_name: "",
      last_name: "",
    };
  },
  watch: {
    data: {
      handler() {
        this.username = this.data.username;
        this.first_name = this.data.first_name;
        this.last_name = this.data.last_name;
      },
    },
  },
  mounted() {
    this.username = this.data.username;
    this.first_name = this.data.first_name;
    this.last_name = this.data.last_name;
    console.log(this.data, "info");
  },
  methods: {
    handleChange() {
      api
        .updateUser(this.data.id, {
          username: this.username,
          first_name: this.first_name,
          last_name: this.last_name,
        })
        .then(() => {
          location.reload();
        });
    },
  },
};
</script>

<style></style>
