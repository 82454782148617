/** @format */

import Vue from "vue";
import App from "./App.vue";
import router from "./router"; // added by router plugin
import VueMask from "v-mask";
import VueCookie from "vue-cookie";
import VueI18n from "vue-i18n";
import ru from "./i18n/ru.json";
import kz from "./i18n/kz.json";
import store from "./store/store";
import * as VueGoogleMaps from "vue2-google-maps";

const languages = {
  ru,
  kz,
};

const messages = Object.assign(languages);

Vue.config.productionTip = false;
Vue.use(VueMask);
Vue.use(VueCookie, { expires: "7d" });
Vue.use(VueI18n);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAIYQFT5nn-WfO6XfYmaO5_2b1omfzqtFg",
    libraries: "places",
  },
  installComponents: true,
});

Vue.component("google-maps-map", VueGoogleMaps.Map);
Vue.component("google-maps-marker", VueGoogleMaps.Marker);

const i18n = new VueI18n({
  legacy: false,
  locale: Vue.cookie.get("lang") === "kz" ? "kz" : "ru",
  messages,
});

new Vue({
  router, // added by router plugin
  render: (h) => h(App),
  i18n,
  store: store,
}).$mount("#app");
