<!-- @format -->

<template>
  <div>
    <div class="mobile-cart__header__2">
      <div class="container">
        <p class="path">{{ $t("mainPage") }}/ {{ $t("login") }}</p>
        <div class="mobile-cart__header">
          <button @click="page" class="mobile-arrow">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="transform: rotate(180deg)">
              <path
                d="M5 1L12 7.5L5 14"
                stroke="#F9AB50"
                stroke-width="2"
                stroke-linecap="round" />
            </svg>
          </button>
          <h1 class="mobile-h1">{{ $t("createOrLogin") }}</h1>
        </div>
      </div>
    </div>
    <div class="login container">
      <div class="login-box">
        <div class="login-tab">
          <input
            type="radio"
            id="create"
            name="login"
            :checked="tab === 1 ? true : false"
            style="display: none" />
          <label
            @click="
              {
                tab = 1;
                sms = false;
                error.detail = '';
                passwordFalse = false;
                this.smsFalse = false;
              }
            "
            for="create"
            class="login-tab__label">
            {{ $t("createAccount") }}
          </label>
          <input
            type="radio"
            id="create2"
            name="login"
            style="display: none"
            :checked="
              tab === 2 || tab === 3 || tab === 4 || tab === 5 ? true : false
            " />
          <label
            @click="
              {
                tab = 2;
                passwordFalse = false;
                error.detail = '';
                this.smsFalse = false;
              }
            "
            for="create2"
            class="login-tab__label border-left">
            {{ $t("login") }}
          </label>
        </div>
        <div v-if="tab === 1">
          <div class="login-sms" v-if="!sms">
            <label for="username" class="login-label">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M23.0151 23.8543C22.4834 22.3659 21.3116 21.0506 19.6816 20.1125C18.0516 19.1745 16.0544 18.666 13.9998 18.666C11.9453 18.666 9.94808 19.1745 8.31807 20.1125C6.68805 21.0506 5.5163 22.3658 4.98453 23.8543"
                  stroke="#C0C0C0"
                  stroke-width="2"
                  stroke-linecap="round" />
                <circle
                  cx="14.0002"
                  cy="9.33268"
                  r="4.66667"
                  stroke="#C0C0C0"
                  stroke-width="2"
                  stroke-linecap="round" />
              </svg>

              <input
                type="text"
                id="username"
                :placeholder="$t('yourName')"
                class="login-input"
                v-model="first_name" />
            </label>
            <label for="lastname" class="login-label">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M23.0151 23.8543C22.4834 22.3659 21.3116 21.0506 19.6816 20.1125C18.0516 19.1745 16.0544 18.666 13.9998 18.666C11.9453 18.666 9.94808 19.1745 8.31807 20.1125C6.68805 21.0506 5.5163 22.3658 4.98453 23.8543"
                  stroke="#C0C0C0"
                  stroke-width="2"
                  stroke-linecap="round" />
                <circle
                  cx="14.0002"
                  cy="9.33268"
                  r="4.66667"
                  stroke="#C0C0C0"
                  stroke-width="2"
                  stroke-linecap="round" />
              </svg>

              <input
                type="text"
                id="lastname"
                :placeholder="$t('yourSurname')"
                class="login-input"
                v-model="last_name" />
            </label>
            <label for="phone" class="login-label">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M22.0014 16.9201V19.9201C22.0025 20.1986 21.9455 20.4743 21.8339 20.7294C21.7223 20.9846 21.5587 21.2137 21.3535 21.402C21.1483 21.5902 20.906 21.7336 20.6421 21.8228C20.3783 21.912 20.0988 21.9452 19.8214 21.9201C16.7442 21.5857 13.7884 20.5342 11.1914 18.8501C8.77523 17.3148 6.72673 15.2663 5.1914 12.8501C3.50138 10.2413 2.44964 7.27109 2.1214 4.1801C2.09641 3.90356 2.12927 3.62486 2.2179 3.36172C2.30652 3.09859 2.44897 2.85679 2.63616 2.65172C2.82336 2.44665 3.0512 2.28281 3.30519 2.17062C3.55917 2.05843 3.83374 2.00036 4.1114 2.0001H7.1114C7.5967 1.99532 8.06719 2.16718 8.43516 2.48363C8.80313 2.80008 9.04348 3.23954 9.1114 3.7201C9.23802 4.68016 9.47285 5.62282 9.8114 6.5301C9.94594 6.88802 9.97506 7.27701 9.8953 7.65098C9.81555 8.02494 9.63026 8.36821 9.3614 8.6401L8.0914 9.9101C9.51495 12.4136 11.5879 14.4865 14.0914 15.9101L15.3614 14.6401C15.6333 14.3712 15.9766 14.1859 16.3505 14.1062C16.7245 14.0264 17.1135 14.0556 17.4714 14.1901C18.3787 14.5286 19.3213 14.7635 20.2814 14.8901C20.7672 14.9586 21.2108 15.2033 21.5279 15.5776C21.8451 15.9519 22.0136 16.4297 22.0014 16.9201Z"
                  stroke="#C0C0C0"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>

              <input
                type="text"
                id="phone"
                v-mask="'+7 (###) ###-##-##'"
                placeholder="+7 (777) 777 77 77"
                class="login-input"
                v-model="phoneMask" />
            </label>
            <button @click="handleSms" class="login-sms__btn" v-if="!disabled">
              {{ $t("getSms") }}
            </button>

            <button type="submit" class="login-sms__btn" v-else>
              <div class="loading"></div>
            </button>
            <span
              style="
                font-size: 14px;
                color: red;
                margin-top: 10px;
                font-weight: 500;
                display: block;
                text-align: center;
              "
              v-if="passwordFalse">
              {{ $t("inputError") }}
            </span>
          </div>
          <div class="login-sms" v-else>
            <div class="login-sms__texts">
              <p class="login-sms__text">
                {{ $t("noCode") }}
              </p>
              <button class="login-sms__timer" v-if="timer > 0">
                {{ $t("codeTimer") }}
                {{ timer }}
              </button>
              <button @click="handleSms" class="login-sms__timer" v-else>
                {{ $t("codeAgain") }}
              </button>
            </div>
            <label for="sms" class="login-label mt-20">
              <input
                type="text"
                id="sms"
                :placeholder="$t('sms')"
                class="login-input"
                v-model="smsInput" />
            </label>
            <label for="pass" class="login-label mt-20">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                  stroke="#C0C0C0"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                  stroke="#C0C0C0"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <input
                type="password"
                id="pass"
                :placeholder="$t('new_password')"
                v-model="password"
                class="login-input" />
            </label>
            <label for="password" class="login-label mt-20">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                  stroke="#C0C0C0"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                  stroke="#C0C0C0"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <input
                type="password"
                id="password"
                v-model="password2"
                :placeholder="$t('repeatPass')"
                class="login-input" />
            </label>
            <button @click="handleRegister" class="login-sms__btn">
              {{ $t("create") }}
            </button>
            <span
              style="
                font-size: 14px;

                color: red;
                margin-top: 10px;
                display: block;
                text-align: center;
                font-weight: 500;
              "
              v-if="passwordFalse">
              {{ $t("inputError") }}
            </span>
            <span
              style="
                font-size: 14px;

                color: red;
                margin-top: 10px;
                display: block;
                text-align: center;
                font-weight: 500;
              "
              v-if="error.username.length > 0"
              >{{ error.username }}</span
            >
            <span
              style="
                font-size: 14px;

                color: red;
                margin-top: 10px;
                display: block;
                text-align: center;
                font-weight: 500;
              "
              v-if="error.password.length > 0"
              >{{ error.password }}</span
            >
          </div>
        </div>
        <form @submit.prevent="handleLogin" class="login-sms" v-if="tab === 2">
          <label for="phone" class="login-label">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M22.0014 16.9201V19.9201C22.0025 20.1986 21.9455 20.4743 21.8339 20.7294C21.7223 20.9846 21.5587 21.2137 21.3535 21.402C21.1483 21.5902 20.906 21.7336 20.6421 21.8228C20.3783 21.912 20.0988 21.9452 19.8214 21.9201C16.7442 21.5857 13.7884 20.5342 11.1914 18.8501C8.77523 17.3148 6.72673 15.2663 5.1914 12.8501C3.50138 10.2413 2.44964 7.27109 2.1214 4.1801C2.09641 3.90356 2.12927 3.62486 2.2179 3.36172C2.30652 3.09859 2.44897 2.85679 2.63616 2.65172C2.82336 2.44665 3.0512 2.28281 3.30519 2.17062C3.55917 2.05843 3.83374 2.00036 4.1114 2.0001H7.1114C7.5967 1.99532 8.06719 2.16718 8.43516 2.48363C8.80313 2.80008 9.04348 3.23954 9.1114 3.7201C9.23802 4.68016 9.47285 5.62282 9.8114 6.5301C9.94594 6.88802 9.97506 7.27701 9.8953 7.65098C9.81555 8.02494 9.63026 8.36821 9.3614 8.6401L8.0914 9.9101C9.51495 12.4136 11.5879 14.4865 14.0914 15.9101L15.3614 14.6401C15.6333 14.3712 15.9766 14.1859 16.3505 14.1062C16.7245 14.0264 17.1135 14.0556 17.4714 14.1901C18.3787 14.5286 19.3213 14.7635 20.2814 14.8901C20.7672 14.9586 21.2108 15.2033 21.5279 15.5776C21.8451 15.9519 22.0136 16.4297 22.0014 16.9201Z"
                stroke="#C0C0C0"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>

            <input
              type="text"
              id="phone"
              v-mask="'+7 (###) ###-##-##'"
              placeholder="+7 (777) 777 77 77"
              class="login-input"
              v-model="phoneMask" />
          </label>
          <label for="passLogin" class="login-label mt-20">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                stroke="#C0C0C0"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
              <path
                d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                stroke="#C0C0C0"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            <input
              type="password"
              id="passLogin"
              :placeholder="$t('password')"
              class="login-input"
              v-model="password" />
          </label>
          <button type="button" @click="tab = 3" class="login-forget">
            {{ $t("forget") }}
          </button>
          <button type="submit" class="login-sms__btn" v-if="!disabled">
            {{ $t("enter") }}
          </button>
          <button type="submit" class="login-sms__btn" v-else>
            <div class="loading"></div>
          </button>
          <span
            style="
              font-size: 14px;

              color: red;
              margin-top: 10px;
              display: block;
              text-align: center;
              font-weight: 500;
            "
            v-if="error.detail"
            >{{ error.detail }}</span
          >
          <span
            style="
              font-size: 14px;
              color: red;
              margin-top: 10px;
              font-weight: 500;
              display: block;
              text-align: center;
            "
            v-if="passwordFalse">
            {{ $t("inputError") }}
          </span>
          <span
            style="
              font-size: 14px;
              color: red;
              margin-top: 10px;
              font-weight: 500;
              display: block;
              text-align: center;
            "
            v-if="smsFalse">
            {{ $t("smsError") }}
          </span>
        </form>
        <div v-if="tab === 3" class="login-forget mt-50">
          <label for="phone" class="login-label">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M22.0014 16.9201V19.9201C22.0025 20.1986 21.9455 20.4743 21.8339 20.7294C21.7223 20.9846 21.5587 21.2137 21.3535 21.402C21.1483 21.5902 20.906 21.7336 20.6421 21.8228C20.3783 21.912 20.0988 21.9452 19.8214 21.9201C16.7442 21.5857 13.7884 20.5342 11.1914 18.8501C8.77523 17.3148 6.72673 15.2663 5.1914 12.8501C3.50138 10.2413 2.44964 7.27109 2.1214 4.1801C2.09641 3.90356 2.12927 3.62486 2.2179 3.36172C2.30652 3.09859 2.44897 2.85679 2.63616 2.65172C2.82336 2.44665 3.0512 2.28281 3.30519 2.17062C3.55917 2.05843 3.83374 2.00036 4.1114 2.0001H7.1114C7.5967 1.99532 8.06719 2.16718 8.43516 2.48363C8.80313 2.80008 9.04348 3.23954 9.1114 3.7201C9.23802 4.68016 9.47285 5.62282 9.8114 6.5301C9.94594 6.88802 9.97506 7.27701 9.8953 7.65098C9.81555 8.02494 9.63026 8.36821 9.3614 8.6401L8.0914 9.9101C9.51495 12.4136 11.5879 14.4865 14.0914 15.9101L15.3614 14.6401C15.6333 14.3712 15.9766 14.1859 16.3505 14.1062C16.7245 14.0264 17.1135 14.0556 17.4714 14.1901C18.3787 14.5286 19.3213 14.7635 20.2814 14.8901C20.7672 14.9586 21.2108 15.2033 21.5279 15.5776C21.8451 15.9519 22.0136 16.4297 22.0014 16.9201Z"
                stroke="#C0C0C0"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>

            <input
              type="text"
              id="phone"
              v-mask="'+7 (###) ###-##-##'"
              placeholder="+7 (777) 777 77 77"
              class="login-input"
              v-model="phoneMask" />
          </label>
          <button @click="handleSMSForget" class="login-sms__btn">
            {{ $t("getCode") }}
          </button>
        </div>
        <div v-if="tab === 4" class="login-forget">
          <div class="login-forget__texts">
            <p class="login-sms__text">
              {{ $t("noCode") }}
            </p>
            <button class="login-sms__timer" v-if="timer > 0">
              {{ $t("codeTimer") }}
              {{ timer }}
            </button>
            <button @click="handleSms" class="login-sms__timer" v-else>
              {{ $t("codeAgain") }}
            </button>
          </div>
          <label for="sms" class="login-label login-forget__label">
            <input
              type="text"
              id="sms"
              :placeholder="$t('sms')"
              class="login-input"
              v-model="smsInput" />
          </label>
          <button @click="handleVerifyForget" class="login-sms__btn">
            {{ $t("confirm") }}
          </button>
        </div>
        <div v-if="tab === 5" class="login-forget__pass">
          <label for="pass" class="login-label mt-20">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                stroke="#C0C0C0"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
              <path
                d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                stroke="#C0C0C0"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            <input
              type="password"
              id="pass"
              :placeholder="$t('new_password')"
              v-model="password"
              class="login-input" />
          </label>
          <label for="password" class="login-label mt-20">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                stroke="#C0C0C0"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
              <path
                d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                stroke="#C0C0C0"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            <input
              type="password"
              id="password"
              v-model="password2"
              :placeholder="$t('repeatPass')"
              class="login-input" />
          </label>
          <button @click="handleChangePass" class="login-sms__btn">
            {{ $t("change") }}
          </button>
          <span
            style="
              font-size: 14px;
              color: red;
              margin-top: 10px;
              font-weight: 500;
              display: block;
              text-align: center;
            "
            v-if="passwordFalse">
            {{ $t("inputError") }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";

export default {
  name: "AppLogin",
  data() {
    return {
      disabled: false,
      first_name: "",
      last_name: "",
      password: "",
      password2: "",
      passwordFalse: false,
      phoneMask: "",
      error: {
        username: "",
        password: "",
        detail: "",
      },
      smsInput: "",
      errMessage: "",
      sms: false,
      timer: 0,
      tab: 1,
      phone: "",
      smsFalse: false,
    };
  },
  watch: {
    password: {
      handler() {
        this.passwordFalse = false;
      },
    },
    password2: {
      handler() {
        this.passwordFalse = false;
      },
    },
    timer: {
      handler() {
        if (
          (this.timer > 0 && this.tab === 1) ||
          (this.timer > 0 && this.tab === 4)
        ) {
          setTimeout(() => {
            this.timer--;
          }, 1000);
        }
      },
    },
  },
  methods: {
    page() {
      if (this.$cookie.get("refresh")) {
        this.$router.go(-1);
      } else {
        this.$router.push("/");
      }
    },
    handleSMSForget() {
      if (this.phoneMask) {
        api.sendSMS(this.phoneMask).then(() => {
          this.timer = 60;
          this.tab = 4;
        });
      }
    },
    handleVerifyForget() {
      api.verifySMS({ phone: this.phoneMask, code: this.smsInput }).then(() => {
        this.tab = 5;
        this.smsInput = "";
      });
    },
    handleChangePass() {
      if (this.password === this.password2) {
        api
          .changePass({ phone: this.phoneMask, new_password: this.password })
          .then(() => {
            this.tab = 2;
            this.password = "";
            this.password = "";
          });
      } else {
        this.passwordFalse = true;
      }
    },
    handleSms() {
      if (this.first_name.length > 0 && this.phoneMask.length > 0) {
        this.smsFalse = false;
        this.disabled = true;
        api
          .sendSMS(this.phoneMask)
          .then((res) => {
            this.sms = true;
            this.disabled = false;

            this.timer = 60;
            this.error.username = "";
            this.error.password = "";
            this.passwordFalse = false;
            console.log(res);
          })
          .catch(() => {
            this.disabled = false;
          });
      } else {
        this.passwordFalse = true;
      }
    },
    handleRegister() {
      if (this.password === this.password2 && this.smsInput) {
        this.smsFalse = false;
        api
          .verifySMS({ phone: this.phoneMask, code: this.smsInput })
          .then((res) => {
            if (res.data.success) {
              api
                .Register({
                  first_name: this.first_name,
                  last_name: this.last_name,
                  username: this.phoneMask,
                  password: this.password,
                })
                .then((res) => {
                  if (res.status === 201) {
                    this.tab = 2;
                    this.password = "";
                    this.password2 = "";
                  }
                })
                .catch((err) => {
                  this.error.username = err.response.data.username
                    ? err.response.data.username[0]
                    : "";
                  this.error.password = err.response.data.password
                    ? err.response.data.password[0]
                    : "";
                });
            }
          })
          .catch(() => (this.smsFalse = true));
      } else {
        this.passwordFalse = true;
      }
    },
    handleLogin() {
      if (this.phoneMask.length > 0 && this.password.length > 0) {
        this.disabled = true;
        api
          .Login({ username: this.phoneMask, password: this.password })
          .then((res) => {
            this.disabled = false;
            this.$cookie.set("access", res.data.access);
            this.$cookie.set("refresh", res.data.refresh);
            this.$cookie.set("chat_token", res.data.chat_token);
            localStorage.setItem("userId", res.data.id);
            api.getCart().then((res) => {
              localStorage.setItem("count", res.data.cartitems.length);
              this.$store.dispatch("increment");
              this.$router.push("/");
            });
          })
          .catch(() => {
            this.disabled = false;
            this.error.detail = this.$t("loginError");
          });
      } else {
        this.passwordFalse = true;
      }
    },
  },
};
</script>

<style>
.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.login-box {
  max-width: 543px;
  width: 100%;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 1px 5px 19px -3px rgba(0, 0, 0, 0.2);
  padding: 28px 50px;
}

.login-tab {
  max-width: 362px;
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid #ebf1f8;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.login-tab__label {
  width: 47%;
  text-align: center;
  cursor: pointer;
  padding: 8px;
  transition: all;
  transition-duration: 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-tab__label:first-child {
  border: 1px solid #ebf1f8;
  color: green;
}

.border-left {
  border-left: 1px solid #ebf1f8;
}

.login-tab input[type="radio"] + .login-tab__label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #39424b;
}

.login-tab input[type="radio"]:checked + .login-tab__label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3ecd79;
}

.login-tab__label::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 50%;
  left: 50%;
  transform: translateX(-50%);
  height: 0px;
  background: transparent;
  border-radius: 15px 15px 0px 0px;
  transition: all;
  transition-duration: 400ms;
}

.login-tab input[type="radio"]:checked + .login-tab__label::after {
  height: 3px;
  background: #3ecd79;
}

.login-sms {
  margin-top: 60px;
}

.login-label {
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 15px;
  background: #f8f8f9;
  border-radius: 15px;
  height: 48px;
}

.login-input::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #c0c0c0;
}

.login-input {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #39424b;
}

.login-input {
  outline: none;
  background: transparent;
}

.login-label:first-child {
  margin-bottom: 22px;
}

.login-label:nth-child(2) {
  margin-bottom: 22px;
}

.login-sms__btn {
  margin: 0 auto;
  margin-top: 30px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  width: 180px;
  text-align: center;
  padding: 15px 0;
  background: #f7931e;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-sms__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #39424b;
}

.login-sms__texts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  gap: 5px;
  max-width: max-content;
  margin: 0 auto;
}

.login-sms__timer {
  font-family: "Montserrat";
  font-style: normal;
  border-bottom: 1px dashed #3ecd79;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3ecd79;
}

.login-forget {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: end;
  margin-left: auto;
  letter-spacing: -0.08px;
  color: #3ecd79;
}

.login-forget__texts {
  width: fit-content;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.login-forget__label {
  margin-top: 20px;
}

.login-forget__pass {
  margin-top: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.loading {
  width: 15px;
  height: 15px;
}

.loading:after {
  content: " ";
  display: block;
  margin-top: -3px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-color: #fff #f7931e #fff #f7931e;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .login {
    height: 70vh;
  }
  .login-box {
    width: 100%;
    padding: 15px 1.5rem;
  }
  .login-tab {
    width: 100%;
  }
  .login-tab__label {
    width: 50%;
    font-size: 15px;
  }
  .login-sms__texts {
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    justify-content: center;
  }
  .login-sms {
    margin-top: 25px;
  }
}
</style>
