<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 5C1 2.37479 1.02811 1.5 4.5 1.5C7.97189 1.5 8 2.37479 8 5C8 7.62521 8.01107 8.5 4.5 8.5C0.988927 8.5 1 7.62521 1 5Z"
      stroke="#3ECD79"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 5C12 2.37479 12.0281 1.5 15.5 1.5C18.9719 1.5 19 2.37479 19 5C19 7.62521 19.0111 8.5 15.5 8.5C11.9889 8.5 12 7.62521 12 5Z"
      stroke="#3ECD79"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 16C1 13.3748 1.02811 12.5 4.5 12.5C7.97189 12.5 8 13.3748 8 16C8 18.6252 8.01107 19.5 4.5 19.5C0.988927 19.5 1 18.6252 1 16Z"
      stroke="#3ECD79"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 16C12 13.3748 12.0281 12.5 15.5 12.5C18.9719 12.5 19 13.3748 19 16C19 18.6252 19.0111 19.5 15.5 19.5C11.9889 19.5 12 18.6252 12 16Z"
      stroke="#3ECD79"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "AppCategoryIcon",
};
</script>

<style></style>
