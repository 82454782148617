<!-- @format -->

<template>
  <div class="cabinet-address">
    <div class="cabinet-address__flex">
      <Address
        v-for="(address, index) in addresses"
        :key="address.id"
        :address="address"
        :index="index"
        @changeInfo="change" />
    </div>
    <button @click="AdressModal" class="cabinet-address__btn">
      {{ $t("addAddress") }}
    </button>
  </div>
</template>

<script>
import Address from "../address/Address.vue";
export default {
  components: { Address },
  name: "AppCabinetAddress",
  props: ["addresses"],
  methods: {
    AdressModal() {
      this.$emit("isModal", {
        modal: true,
        type: this.$t("addAddress"),
        address: "",
      });
    },
    change(data) {
      this.$emit("isModal", {
        modal: true,
        type: this.$t("deleteAddress"),
        address: data,
      });
    },
  },
};
</script>

<style>
.cabinet-address__flex {
  display: flex;
  align-items: center;
  gap: 28px;
  flex-wrap: wrap;
}

.cabinet-address__btn {
  padding: 13px 45px;
  background: #3ecd79;
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 60px;
}
</style>
