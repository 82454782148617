<!-- @format -->

<template>
  <div>
    <div
      v-if="isMobile"
      class="modal-back"
      style="background: rgba(33, 38, 45, 0.73)"
      :style="isModal ? 'display:block' : 'display:none'"
      @click="isModal = !isModal"></div>
    <div
      v-if="isMobile"
      class="modal-down"
      @click.stop
      :style="
        isModal ? 'transform:translate(0)' : 'transform:translateY(100%)'
      ">
      <div class="modal-down__scroll">
        <h3 class="modal-h3">Какие продукты показывать?</h3>
        <div class="modal-checbox__content" v-for="i in 6" :key="i">
          День заказа и ассортимент
          <div class="modal-checkbox__flex">
            <input
              type="checkbox"
              :id="'checkbox' + i"
              checked
              style="display: none" />
            <label :for="'checkbox' + i"></label>
            Можно заказать сегодня
          </div>
          <div class="modal-checkbox__flex">
            <input
              type="checkbox"
              :id="'checkbox2' + i"
              style="display: none" />
            <label :for="'checkbox2' + i"></label>
            Только продукты damdala
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <p class="path">{{ $t("mainPage") }}/ Каталог / {{ $t("favorite") }}</p>
      <div class="mobile-cart__header">
        <h1 class="mobile-h1">{{ $t("favorite") }}</h1>
        <button @click="page" class="mobile-close">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.5 1.5L13.5 13.5M1.5 13.5L13.5 1.5"
              stroke="#F9AB50"
              stroke-width="2px" />
          </svg>
        </button>
      </div>
      <div class="box">
        <h2 class="h2">{{ $t("favorite") }}</h2>
        <div class="mobile-wishlist" v-if="isMobile">
          <div class="mobile-wishlist__header2">
            {{ $t("positionText") }}
            <input
              type="text"
              class="mobile-wishlist__header2__input"
              :placeholder="$t('writeAddress')" />
          </div>
          <div class="mobile-wishlist__flex">
            <input
              type="text"
              :placeholder="$t('findProducts')"
              class="mobile-wishlist__flex__input" />
            <button
              @click="isModal = !isModal"
              class="mobile-wishlist__flex__btn">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect y="2" width="18" height="2" rx="1" fill="#F7931E" />
                <rect y="8" width="18" height="2" rx="1" fill="#F7931E" />
                <rect y="14" width="18" height="2" rx="1" fill="#F7931E" />
                <rect x="12" y="2" width="2" height="2" fill="white" />
                <circle
                  cx="13"
                  cy="3"
                  r="2"
                  stroke="#F7931E"
                  stroke-width="2" />
                <rect x="4" y="8" width="2" height="2" fill="white" />
                <circle cx="5" cy="9" r="2" stroke="#F7931E" stroke-width="2" />
                <rect x="12" y="14" width="2" height="2" fill="white" />
                <circle
                  cx="13"
                  cy="15"
                  r="2"
                  stroke="#F7931E"
                  stroke-width="2" />
              </svg>
            </button>
            <div class="mobile-wishlist__flex__btn">
              <bookmark :fill="'#F9AB50'" />
            </div>
          </div>
        </div>
        <div v-if="menu.length >= 1">
          <div class="category-item">
            <one-of-list-2
              v-for="itemMenu in menu"
              :key="itemMenu.id"
              :itemMenu="itemMenu"
              @reload="reload" />
          </div>
        </div>
        <h2 class="wishlist__h2" v-else>
          {{ $t("emptyFavorite") }}
        </h2>
        <!-- <recomendation /> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Recomendation from "@/components/Recomendation.vue";
import OneOfList2 from "@/components/OneOfList2.vue";
import Bookmark from "@/icon_components/Bookmark.vue";
import api from "@/api/api";
export default {
  name: "AppWishList",
  components: { OneOfList2, Bookmark },
  data() {
    return {
      isMobile: false,
      isModal: false,
      menu: [],
    };
  },
  methods: {
    page() {
      this.$router.go(-1);
    },
    resize() {
      if (window.innerWidth < 640) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    reload() {
      api.getFavourite().then((res) => {
        this.menu = res.data.product;
        let temp = [];
        if (res) {
          res.data.product.forEach((element) => {
            temp.push(element.id);
          });
        }
        console.log(res);
        this.$cookie.set("favourite", JSON.stringify(temp));
      });
    },
  },
  mounted() {
    this.reload();
    this.resize();
    window.addEventListener("resize", this.resize, { passive: true });
  },
  created() {
    if (!this.$cookie.get("access")) {
      this.$router.push("/login");
    }
  },
};
</script>

<style>
.wishlist__h2 {
  max-width: 500px;
  margin: 0 auto;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.24px;
  color: rgba(58, 71, 83, 0.8);
}

.mobile-wishlist__header2 {
  max-width: 315px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 22px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.24px;
  color: #3a4753;
}

.mobile-wishlist__header2__input {
  max-width: 170px;
  width: 100%;
  background: #f2f2f2;
  padding: 8px 14px;
  border-radius: 10px;
  margin: 0 auto;
  display: block;
  height: 38px;
}

.mobile-wishlist__header2__input::placeholder {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: rgba(58, 71, 83, 0.8);
}

.mobile-wishlist__flex {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-top: 50px;
  margin-bottom: 30px;
}

.mobile-wishlist__flex__input {
  width: 70%;
  padding: 8px 12px;
  background: #f2f2f2;
  border-radius: 10px;
  height: 38px;
}

.mobile-wishlist__flex__input::placeholder {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 147% */

  color: rgba(58, 71, 83, 0.8);
}

.mobile-wishlist__flex__btn {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f2;
  border-radius: 5px;
}

.modal-h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #3a4753;
  margin-bottom: 12px;
}

.modal-checbox__content {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 35px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(58, 71, 83, 0.8);
}

.modal-checbox__content:last-child {
  margin-bottom: 0;
}

.modal-checkbox__flex {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.24px;
  color: #3a4753;
}

.modal-checkbox__flex input[type="checkbox"] + label {
  width: 24px;
  height: 24px;
  border: 1px solid #f9ab50;
  transition: all;
  transition-duration: 300ms;
}

.modal-checkbox__flex input[type="checkbox"]:checked + label {
  background: #f9ab50;
}

.modal-down__scroll {
  overflow: scroll;
  height: 100%;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .wishlist__h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.24px;
    color: rgba(58, 71, 83, 0.8);
  }
}
</style>
