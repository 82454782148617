<!-- @format -->

<template>
  <div>
    <div class="card-info">
      <div class="card-info__left">
        <h2 class="card-info__h2">
          {{ $t("lang") === "kz" ? product.name_kaz : product.name_rus }}
        </h2>
        <span class="card-info__mass"
          >{{ product.weight > 1000 ? product.weight / 1000 : product.weight }}
          {{ product.weight > 1000 ? "Кг" : "гр" }}</span
        >
        <p class="card-info__price">{{ product.price }} ₸ /шт</p>
        <div class="card-info__flex">
          <div class="card-info__btn" v-if="count > 0">
            <button @click="handleSet('minus')">
              <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M1 8H14" stroke="#3A4753" />
              </svg>
            </button>
            <p class="card-info__count">{{ count }}</p>
            <button @click="handleSet('plus')">
              <svg
                width="13"
                height="14"
                viewBox="0 0 13 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 0.5V13.5M0 7H13" stroke="#3A4753" />
              </svg>
            </button>
          </div>
          <button
            @click="handleCart"
            class="card-info__basket"
            v-if="count === 0">
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4 5L4.77143 7.57143M4.77143 7.57143L7.64335 17.1445C7.77024 17.5675 8.15957 17.8571 8.60117 17.8571H20C21.1046 17.8571 22 16.9617 22 15.8571V10.1429C22 8.7227 20.8487 7.57143 19.4286 7.57143H4.77143ZM19.4286 23C18.7185 23 18.1429 22.4244 18.1429 21.7143C18.1429 21.0042 18.7185 20.4286 19.4286 20.4286C20.1387 20.4286 20.7143 21.0042 20.7143 21.7143C20.7143 22.4244 20.1387 23 19.4286 23ZM9.14286 21.7143C9.14286 21.0042 9.71849 20.4286 10.4286 20.4286C11.1387 20.4286 11.7143 21.0042 11.7143 21.7143C11.7143 22.4244 11.1387 23 10.4286 23C9.71849 23 9.14286 22.4244 9.14286 21.7143Z"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round" />
            </svg>

            {{ $t("toCart") }}
          </button>
        </div>
        <span v-if="countErr" class="error">
          {{ countErr }}
        </span>
        <h3 class="card-info__h3">{{ $t("description") }}</h3>
        <p class="card-info__description">
          {{
            $t("lang") === "kz"
              ? product.description_kaz
              : product.description_rus
          }}
        </p>
      </div>
      <img :src="product.product_image" alt="" class="card-info__img" />
    </div>
    <p
      class="card-info__p"
      v-for="item in product.product_characteristic"
      :key="item.id">
      {{ $t("lang") === "kz" ? item.name_kaz : item.name_rus }}:
      <span class="card-info__span"
        >{{ $t("lang") === "kz" ? item.value_kaz : item.value_rus }}
        {{ $t("lang") === "kz" ? item.measure_kaz : item.measure_rus }}</span
      >
    </p>
    <h3 class="card-info__h3" v-if="product.product_reviews.length >= 1">
      {{ $t("review") }}
    </h3>
    <button
      class="card-info__comment__btn"
      v-if="buttonHidden && !reviewUsed"
      @click="$emit('modal')">
      {{ $t("addReview") }}
    </button>
    <div
      class="card-info__comments"
      v-for="(item, index) in product.product_reviews"
      :key="item.id">
      <div v-if="reviewsCount >= index">
        <p class="card-info__name">{{ item.name }}</p>
        <p class="card-info__comment__date">
          {{ item.date_of_publish.toLocaleString() }}
        </p>
        <p class="card-info__comment">{{ item.content_review }}</p>
      </div>
      <div class="card-info__stars">
        <svg
          v-for="i in 5"
          :key="i"
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.59375 0L10.004 4.27627L14.8158 5.24715L11.4937 8.86091L12.0572 13.7372L7.59375 11.6944L3.13026 13.7372L3.69382 8.86091L0.371665 5.24715L5.18346 4.27627L7.59375 0Z"
            :fill="item.rating >= i ? '#F9AB50' : '#979EA6'" />
        </svg>
      </div>
    </div>
    <button
      v-if="product.product_reviews.length >= 5"
      @click="reviewsCount += 5"
      class="comment-show">
      {{ $t("showMore") }}
    </button>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  components: {},
  name: "AppCardDesktop",
  props: ["product", "modal", "reviewUsed"],
  data() {
    return {
      count: 0,
      countErr: "",
      buttonHidden: false,
      reviewsCount: 5,
      cartId: -1,
    };
  },
  watch: {
    count: {
      handler() {
        this.countErr = "";
      },
    },
  },
  methods: {
    async checkCart() {
      const temp = await JSON.parse(localStorage.getItem("cart"));
      if (temp) {
        temp.forEach((item) => {
          if (item.product.id === this.product.id) {
            this.count = item.quantity;
            this.cartId = item.id;
          }
        });
      }
    },
    handleCart() {
      api.addCart({ quantity: 1, product: this.product.id }).then(() => {
        this.isCart = true;
        api.getCart().then((res) => {
          localStorage.setItem("cart", JSON.stringify(res.data.cartitems));
          localStorage.setItem("count", res.data.cartitems.length);
          this.$store.dispatch("increment");
          this.checkCart();
        });
      });
    },
    handleSet(type) {
      if (type === "plus") {
        api.putCart(this.cartId, { quantity: this.count + 1 }).then(() => {
          this.count++;
          api.getCart().then((res) => {
            localStorage.setItem("cart", JSON.stringify(res.data.cartitems));
            localStorage.setItem("count", res.data.cartitems.length);
            this.$store.dispatch("increment");
          });
        });
      } else if (type === "minus" && this.count > 1) {
        api.putCart(this.cartId, { quantity: this.count - 1 }).then(() => {
          this.count--;
          api.getCart().then((res) => {
            localStorage.setItem("cart", JSON.stringify(res.data.cartitems));
            localStorage.setItem("count", res.data.cartitems.length);
            this.$store.dispatch("increment");
          });
        });
      }
    },
  },
  created() {
    if (this.$cookie.get("access")) {
      this.buttonHidden = true;
    }
    this.checkCart();
  },
};
</script>

<style scoped></style>
