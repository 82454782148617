<template>
  <section>
    <div class="container">
      <div class="menu">
        <div class="menu-box__text">
          <div class="menu-box__h3__loader"></div>
          <!-- <router-link
            :to="
              '/catalog/' +
              item.category_id +
              '/' +
              item.products[0].subcategory_link
            "
            class="menu-box__btn"
            >Смотреть все</router-link
          > -->
        </div>
        <div class="menu-box">
          <div v-for="i in 5" :key="i" class="menu-box__item__loader"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppListLoader",
};
</script>

<style>
.menu-box__h3__loader {
  max-width: 350px;
  height: 37px;
  width: 100%;
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.1);
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 2s loading ease-in-out infinite;
  border-radius: 8px;
}

.menu-box__item__loader {
  max-width: 219px;
  height: 248px;
  width: 100%;
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.1);
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
  border-radius: 20px;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .menu-box__item__loader {
    width: 100%;
    max-width: 100%;
    height: 222px;
  }
  .menu-box__h3__loader {
    max-width: 60%;
  }
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
</style>
