var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('p',{staticClass:"path"},[_vm._v(_vm._s(_vm.$t("mainPage"))+"/"+_vm._s(_vm.$t("cabinet")))]),_c('div',{staticClass:"mobile-cart__header"},[_c('button',{staticClass:"mobile-arrow",on:{"click":_vm.page}},[_c('svg',{staticStyle:{"transform":"rotate(180deg)"},attrs:{"width":"15","height":"15","viewBox":"0 0 15 15","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M5 1L12 7.5L5 14","stroke":"#F9AB50","stroke-width":"2","stroke-linecap":"round"}})])]),_c('h1',{staticClass:"mobile-h1"},[_vm._v("ЧАТ")])]),_vm._m(0),_c('div',{staticClass:"chat"},[_c('div',{staticClass:"chat-overflow"},[_c('div',{staticClass:"chat-box",attrs:{"id":"inner-scroll"}},_vm._l((_vm.messages),function(item){return _c('div',{key:item.date_sent,class:item.user === 'admin'
              ? 'chat-left'
              : item.user === 'bot'
              ? 'chat-left'
              : 'chat-right'},[_c('div',{class:item.user === 'admin'
                ? 'chat-left__block'
                : item.user === 'bot'
                ? 'chat-left__block'
                : 'chat-right__block'},[(item.user === 'admin' || item.user === 'bot')?_c('p',{staticClass:"chat-role"},[_vm._v(" Администратор ")]):_vm._e(),_c('p',{staticClass:"chat-text"},[_vm._v(_vm._s(item.message))])]),_c('p',{staticClass:"chat-time"},[_vm._v(_vm._s(item.date_sent))])])}),0)]),_c('form',{staticClass:"chat-form",on:{"submit":function($event){$event.preventDefault();return _vm.messagePush.apply(null, arguments)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],staticClass:"chat-form__input",attrs:{"type":"text","placeholder":_vm.$t('writeText')},domProps:{"value":(_vm.text)},on:{"input":function($event){if($event.target.composing)return;_vm.text=$event.target.value}}}),_c('button',{staticClass:"chat-form__btn",attrs:{"type":"submit"}},[_c('svg',{staticClass:"chat-form__svg",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"33","height":"33","viewBox":"0 0 33 33","fill":"none"}},[_c('g',{attrs:{"clip-path":"url(#clip0_8734_193987)"}},[_c('mask',{staticStyle:{"mask-type":"luminance"},attrs:{"id":"mask0_8734_193987","maskUnits":"userSpaceOnUse","x":"0","y":"0","width":"33","height":"33"}},[_c('path',{attrs:{"d":"M32.1313 0.677429H0.679688V32.129H32.1313V0.677429Z","fill":"white"}})]),_c('g',{attrs:{"mask":"url(#mask0_8734_193987)"}},[_c('path',{attrs:{"d":"M4.71888 18.5351L13.8934 16.7001C14.7126 16.5364 14.7126 16.2701 13.8934 16.1064L4.71888 14.2714C4.17241 14.1623 3.64019 13.6296 3.53109 13.0836L1.69608 3.90909C1.53195 3.08938 2.01502 2.70852 2.77428 3.05891L30.7706 15.9801C31.2763 16.2135 31.2763 16.5929 30.7706 16.8264L2.77428 29.7476C2.01502 30.098 1.53195 29.7171 1.69608 28.8974L3.53109 19.7229C3.64019 19.1769 4.17241 18.6442 4.71888 18.5351Z","fill":"white"}})])]),_c('defs',[_c('clipPath',{attrs:{"id":"clip0_8734_193987"}},[_c('rect',{attrs:{"width":"31.4516","height":"31.4516","fill":"white","transform":"translate(0.679688 0.677429)"}})])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('h2',{staticClass:"h2"},[_vm._v("Чат")])])
}]

export { render, staticRenderFns }