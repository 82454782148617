<!-- @format -->

<template>
  <div id="App" class="main">
    <PaymentModal />

    <div class="content">
      <Header v-if="!isMobile" />
      <router-view />
    </div>
    <Footer
      v-if="!isMobile"
      :style="
        path === '/shops' || path === '/contacts' ? 'margin-top: 0px' : ''
      "
      :path="path" />
    <mobile-footer v-if="isMobile" />
  </div>
</template>

<script>
import api from "./api/api";
import Footer from "./components/layout/Footer.vue";
import Header from "./components/layout/Header.vue";
import MobileFooter from "./components/layout/MobileFooter.vue";
import PaymentModal from "@/components/PaymentModal";

export default {
  components: { Header, Footer, MobileFooter, PaymentModal },
  name: "App",
  data() {
    return {
      isMobile: false,
      path: this.$route.fullPath,
    };
  },
  methods: {
    resize() {
      if (window.innerWidth < 640) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  created() {
    if (!this.$cookie.get("lang")) {
      this.$cookie.set("lang", "ru");
    }
    // console.log(this.$store.state.cartCount, "states");
    if (this.$cookie.get("favourite")) {
      api.getFavourite().then((res) => {
        let temp = [];
        if (res) {
          res.data.product.forEach((element) => {
            temp.push(element.id);
          });
        }
        console.log(res);
        this.$cookie.set("favourite", JSON.stringify(temp));
        console.log(JSON.parse(this.$cookie.get("favourite")), "favourite");
      });
    }
  },
  updated() {
    this.path = this.$route.path;
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize, { passive: true });
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inder&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  transition: all;
  transition-duration: 300ms;
}

html {
  scroll-behavior: smooth;
}

body {
  height: 100%;
}
.container {
  max-width: 1216px;
  margin: 0 auto;
  position: relative;
  width: 100%;
}

input {
  outline: none;
  border: none;
}
button {
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  cursor: pointer;
}
a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
section {
  width: 100%;
  padding: 0px;
}
.footer {
  margin-top: 60px;
}
.main {
  max-width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1 0 auto;
  min-height: 100vh;
}

.footer {
  flex: 0 0 auto;
}

.path {
  margin-top: 18px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #979ea6;
}

textarea {
  max-width: 100%;
}

.error {
  color: red;
  font-weight: 500;
  margin-top: 10px;
  display: block;
}

input {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #39424b;
}

ul {
  list-style: none;
}

button,
a {
  transition: all;
  transition-duration: 300ms;
}

a:hover {
  opacity: 0.8;
}

button:disabled {
  opacity: 0.6;
}

h2 {
  color: var(--font, #3a4753);
  font-family: Montserrat;
  font-size: 17px;
  font-style: normal;
  font-weight: 800;
  line-height: 170%; /* 28.9px */
  text-transform: uppercase;
}

@media screen and (min-width: 0px) and (max-width: 1260px) {
  .container {
    padding: 0px 15px;
  }
}

@media screen and (max-width: 640px) {
  .mobile-hidden {
    display: none;
  }
  .main {
    min-height: 100vh;
  }
  .content {
    min-height: 10vh;
    padding-bottom: 10px;
  }
}
</style>
