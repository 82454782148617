import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cartCount: 0,
  },
  mutations: {
    increment(state) {
      state.cartCount = localStorage.getItem("count");
    },
  },
  actions: {
    increment(context) {
      context.commit("increment");
    },
  },
});
