<!-- @format -->

<template>
  <footer id="footer" class="footer">
    <div class="container">
      <div class="footer-info">
        <img src="@/assets/icons/LogoFooter.svg" alt="" />
        <div class="footer-btns">
          <router-link to="/catalog" class="footer-btn">Каталог</router-link>
          <button class="footer-btn">{{ $t("footer.news") }}</button>
          <router-link to="/shops" class="footer-btn">{{
            $t("footer.shops")
          }}</router-link>
        </div>
        <div class="footer-btns">
          <router-link to="/about" class="footer-btn">
            {{ $t("footer.about") }}
          </router-link>
          <button class="footer-btn">{{ $t("footer.help") }}</button>
          <button class="footer-btn">Меню</button>
        </div>
        <div class="footer-btns">
          <router-link to="/oferta" class="footer-btn">{{
            $t("footer.public")
          }}</router-link>
          <router-link to="/politics" class="footer-btn">{{
            $t("footer.politics")
          }}</router-link>
          <router-link to="/contacts" class="footer-btn">
            {{ $t("footer.contacts") }}
          </router-link>
        </div>
        <div class="footer-btns">
          <button class="footer-btn__question">
            {{ $t("footer.question") }}
          </button>
          <button class="footer-btn">
            +7 (777) 777-77-77 | 8 (800) 777-77-77
          </button>
          <button class="footer-btn__chat">{{ $t("footer.chat") }}</button>
        </div>
      </div>
    </div>
    <div class="footer-border"></div>
    <div class="container">
      <div class="footer-info">
        <p class="footer-info__protect">2023.{{ $t("footer.reserved") }}.</p>
        <div class="footer-info__right">
          <a
            :href="links.appstore_link"
            target="_blank"
            class="footer-info__store">
            <img src="@/assets/images/appStore.png" alt="" />
            App Store
          </a>
          <a
            :href="links.google_link"
            target="_blank"
            class="footer-info__store">
            <img src="@/assets/images/googlePlay.png" alt="" />
            Google Play
          </a>
          <a
            :href="links.instagram_link"
            target="_blank"
            class="footer-info__social">
            <img src="@/assets/icons/Instagram.svg" alt="" />
          </a>
          <a
            :href="links.telegram_link"
            target="_blank"
            class="footer-info__social">
            <img src="@/assets/icons/telegram.svg" alt="" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AppFooter",
  data() {
    return {
      links: "",
    };
  },
  created() {
    api.getLinks().then((res) => {
      this.links = res.data.results[0];
    });
  },
};
</script>

<style>
.footer {
  background: #f2f2f2;
}
.footer-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 10px;
  padding-top: 43px;
  padding-bottom: 49px;
}
.footer-info:last-child {
  padding-top: 24px;
  padding-bottom: 34px;
}
.footer-btns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;
}
.footer-btn {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #3a4753;
}
.footer-btn__question {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #3a4753;
}
.footer-btn__chat {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #3a4753;
}
.footer-border {
  width: 100%;
  height: 1px;
  background-color: rgba(151, 158, 166, 0.4);
}
.footer-info__protect {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #3a4753;
}
.footer-info__right {
  display: flex;
  align-items: center;
  column-gap: 25px;
}
.footer-info__store {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3a4753;
}
.footer-info__social {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 50%;
}
</style>
