<!-- @format -->

<template>
  <div
    class="address-block"
    :style="!address.is_main ? 'background: #f2f2f2' : ''">
    <div class="address-info">
      <p class="address-info__name">Адрес {{ index + 1 }}</p>
      <div class="address-info__btns">
        <button
          @click="handleDel(address.id)"
          class="address-info__btn"
          :style="!address.is_main ? 'background: #D9D9D9;' : ''">
          <svg
            width="15"
            height="17"
            viewBox="0 0 15 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.5 4V2.5C4.5 1.94772 4.94772 1.5 5.5 1.5H9.5C10.0523 1.5 10.5 1.94772 10.5 2.5V4M0 4.5H15M1.5 4.5V14.5C1.5 15.0523 1.94772 15.5 2.5 15.5H12.5C13.0523 15.5 13.5 15.0523 13.5 14.5V4.5M7.5 8V13M4.5 10V13M10.5 10V13"
              stroke="#F9AB50"
              stroke-width="1.6" />
          </svg>
        </button>
        <button
          class="address-info__btn"
          :style="!address.is_main ? 'background: #D9D9D9;' : ''"
          @click="changeInfo">
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.5 11.5L0.792893 10.7929L0.5 11.0858V11.5H1.5ZM11.5 1.5L12.2071 0.792893C11.8166 0.402369 11.1834 0.402369 10.7929 0.792893L11.5 1.5ZM15.5 5.5L16.2071 6.20711C16.5976 5.81658 16.5976 5.18342 16.2071 4.79289L15.5 5.5ZM5.5 15.5V16.5H5.91421L6.20711 16.2071L5.5 15.5ZM1.5 15.5H0.5C0.5 16.0523 0.947715 16.5 1.5 16.5L1.5 15.5ZM2.20711 12.2071L12.2071 2.20711L10.7929 0.792893L0.792893 10.7929L2.20711 12.2071ZM10.7929 2.20711L14.7929 6.20711L16.2071 4.79289L12.2071 0.792893L10.7929 2.20711ZM14.7929 4.79289L4.79289 14.7929L6.20711 16.2071L16.2071 6.20711L14.7929 4.79289ZM5.5 14.5H1.5V16.5H5.5V14.5ZM2.5 15.5V11.5H0.5V15.5H2.5Z"
              fill="#3ECD79" />
          </svg>
        </button>
      </div>
    </div>
    <div class="address-content">
      <div class="address-content__text">
        <p class="">г. {{ address.city }}</p>
        <p>ул. {{ address.street }}, д.{{ address.building }}</p>
        <p>{{ address.floor }} {{ $t("floor") }}</p>
        <p>{{ address.intrance }} {{ $t("building") }}</p>
      </div>
      <div v-if="address.is_main" class="address-main">{{ $t("main") }}</div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AppAddress",
  props: ["address", "index"],
  methods: {
    changeInfo() {
      this.$emit("changeInfo", this.address);
    },
    handleDel(id) {
      if (confirm(this.$t("deleteOk"))) {
        api
          .deleteAddress(id)
          .then(() => {
            location.reload();
          })
          .catch(() => {
            alert(this.$t("delete"));
          });
      }
    },
  },
};
</script>

<style></style>
