<!-- @format -->

<template>
  <div class="container">
    <p class="path">{{ $t("mainPage") }}/ {{ $t("footer.about") }}</p>
    <div class="mobile-cart__header">
      <button @click="page" class="mobile-arrow">
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style="transform: rotate(180deg)">
          <path
            d="M5 1L12 7.5L5 14"
            stroke="#F9AB50"
            stroke-width="2"
            stroke-linecap="round" />
        </svg>
      </button>
      <h1 class="mobile-h1">{{ $t("footer.about") }}</h1>
    </div>
    <div class="box">
      <h2 class="h2">{{ $t("footer.about") }}</h2>
      <div class="about">
        <div class="about-flex" v-for="item in items" :key="item.id">
          <img :src="item.image" alt="asd" class="about-flex__img" />
          <p class="about-flex__text" v-html="item.text"></p>
        </div>
        <div class="about-info">
          <h3 class="about-info__h3">{{ $t("important") }}</h3>
          <p class="about-info__p">
            {{ $t("aLotText") }}
          </p>
        </div>
        <p class="about-support">
          {{ $t("toWorkUs") }}

          <a href="#" class="about-support__btn"> zakup@arbuz.kz. </a><br />
          {{ $t("aLotText2") }}

          <a href="#" class="about-support__btn"> job.arbuz.kz </a>.
          {{ $t("toWorkUs2") }}

          <a href="#" class="about-support__btn"> marketing@arbuz.kz </a>.
        </p>
      </div>
      <div class="vacancy">
        <h2 class="h2">Вакансии</h2>
        <div class="vacancy__flex">
          <div class="vacancy__left">
            <p class="vacancy__left-title">Город:</p>
            <div class="vacancy__select">
              <button class="vacancy__select-btn" @click="isSelect = !isSelect">
                {{ choiceCity.city_name }}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    d="M6 9L12 15L18 9"
                    stroke="#3A4753"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </button>
              <div class="vacancy__options" v-if="isSelect">
                <button
                  @click="(choiceCity = city), (isSelect = !isSelect)"
                  class="vacancy__option"
                  v-for="city in cities"
                  :key="city.id">
                  {{ city.city_name }}
                </button>
              </div>
            </div>
          </div>
          <div class="vacancy__right">
            <div class="vacancy__right-titles">
              <p class="vacancy__right-title">Должность:</p>
              <p class="vacancy__right-title">Опыт:</p>
            </div>
            <div
              class="vacancy__right-one"
              v-for="vacancy in vacancies"
              :key="vacancy">
              <p class="vacancy__right-text">{{ vacancy.position }}</p>
              <p class="vacancy__right-text">{{ vacancy.experience }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AppAboutUs",
  data() {
    return {
      items: "",
      isSelect: false,
      cities: "",
      choiceCity: "",
      vacancies: "",
    };
  },
  methods: {
    page() {
      this.$router.go(-1);
    },
  },
  created() {
    api.getCity().then((res) => {
      this.cities = res.data.results;
      this.choiceCity = res.data.results[0];
      api.getVacancies(res.data.results[0]).then((res) => {
        this.vacancies = res.data.results;
      });
    });
    api.getAbout(this.choiceCity).then((res) => {
      this.items = res.data.results;
    });
  },
  watch: {
    choiceCity: {
      handler() {
        api.getVacancies(this.choiceCity).then((res) => {
          this.vacancies = res.data.results;
        });
      },
    },
  },
};
</script>

<style>
.about {
  margin-top: 40px;
}
.about-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 60px 0;
  column-gap: 50px;
}

.about-flex__img {
  width: 100%;
  max-width: 392px;
  border-radius: 20px;
}

.about-flex__text {
  width: 65%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 195%;
  color: #3a4753;
}

.about-flex:nth-child(2n) {
  flex-direction: row-reverse;
}

.about-info {
  background: #dbf5e6;
  border-radius: 20px;
  padding: 40px 35px;
  margin: 60px 0;
}

.about-info__h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 148.4%;
  color: #3ecd79;
}

.about-info__p {
  margin-top: 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 148.4%;
  color: #3a4753;
}

.about-support {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 148.4%;
  color: #3a4753;
}

.about-support__btn {
  color: #75c2e7;
  text-decoration: underline;
}

.vacancy {
  margin-top: 30px;
}

.vacancy__flex {
  margin-top: 25px;
  display: flex;

  gap: 50px;
}

.vacancy__left-title {
  color: var(--font, #3a4753);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.vacancy__right {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 350px;
  width: 100%;
}

.vacancy__right-titles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  width: 100%;
}

.vacancy__right-title {
  color: #8f9daa;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.vacancy__right-one {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid var(--grey-line, #d9d9d9);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vacancy__right-text {
  color: var(--font, #3a4753);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  width: fit-content;
}

.vacancy__select {
  position: relative;
  margin-top: 15px;
}

.vacancy__select-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 15px;
  background: var(--light, #f2f2f2);
  width: 150px;
  color: var(--font, #3a4753);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
}

.vacancy__options {
  position: absolute;
  top: 100%;
  width: 100%;
  border-radius: 8px;
  z-index: 10;
  max-height: 150px;
  overflow-y: auto;
  overflow-x: visible;
}

.vacancy__option {
  padding: 7px;
  background: var(--light, #f2f2f2);
  width: 100%;
  color: var(--font, #3a4753);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.vacancy__option:first-child {
  border-radius: 10px 10px 0px 0px;
}

.vacancy__option:last-child {
  border-radius: 0px 0px 10px 10px;
}

@media screen and (min-width: 640px) and (max-width: 1024px) {
  .about-flex {
    flex-direction: column-reverse;
    row-gap: 20px;
    margin: 20px 0;
  }
  .about-flex:nth-child(2n) {
    flex-direction: column-reverse;
    row-gap: 20px;
    margin: 20px 0;
  }
  .about-flex__text {
    width: 100%;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .about-flex {
    flex-direction: column-reverse;
    row-gap: 20px;
    margin: 20px 0;
  }
  .about-flex:nth-child(2n) {
    flex-direction: column-reverse;
    row-gap: 20px;
    margin: 20px 0;
  }
  .about-flex__text {
    width: 100%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #3a4753;
  }
  .about-info {
    padding: 20px 11px;
    margin: 20px 0;
  }
  .about-info__h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #3ece79;
  }
  .about-info__p {
    margin-top: 18px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #3a4753;
  }
  .about-support {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #3a4753;
  }
}
</style>
