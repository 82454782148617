<!-- @format -->

<template>
  <div class="container" style="display: none">
    <div class="rec">
      <h2 class="h2">{{ $t("recomendation") }}</h2>
      <div class="category-item" v-for="item in menu" :key="item.id">
        <one-of-list-2
          v-for="itemMenu in item.menus"
          :key="itemMenu.id"
          v-bind:itemMenu="itemMenu" />
      </div>
    </div>
  </div>
</template>

<script>
import OneOfList2 from "./OneOfList2.vue";
export default {
  components: { OneOfList2 },
  name: "AppRecomendation",
  props: ["recomendation"],
  data() {
    return {
      menu: [
        {
          id: 1,
          name: "Damdala Бестселлер",
          menus: [
            {
              id: 1,
              name: "Ассорти маринованных томатов и огурцов",
              status: "new",
              weight: "400г",
              price: "307 ₸ за кг",
              oldPrice: "800 ₸",
              newPrice: "325 ₸",
            },
            {
              id: 2,
              name: "Ассорти маринованных томатов и огурцов",
              status: "new",
              weight: "600г",
              price: "307 ₸ за кг",
              oldPrice: "800 ₸",
              newPrice: "325 ₸",
            },
            {
              id: 3,
              name: "Ассорти маринованных томатов и огурцов",
              status: "new",
              weight: "500г",
              price: "307 ₸ за кг",
              oldPrice: "800 ₸",
              newPrice: "325 ₸",
            },
            {
              id: 4,
              name: "Ассорти маринованных томатов и огурцов",
              status: "bio",
              weight: "400г",
              price: "",
              oldPrice: "800 ₸",
              newPrice: "325 ₸",
            },
            {
              id: 5,
              name: "Ассорти маринованных томатов и огурцов",
              status: "bio",
              weight: "400г",
              price: "",
              oldPrice: "800 ₸",
              newPrice: "325 ₸",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style>
.rec {
  margin-top: 60px;
}
.h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 170%;
  text-transform: uppercase;
  color: #3a4753;
  margin-bottom: 32px;
}
@media screen and (min-width: 0px) and (max-width: 640px) {
  .rec {
    display: none;
  }
}
</style>
