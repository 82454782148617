<!-- @format -->

<template>
  <div>
    <div class="modal-back" @click="isModal = !isModal" v-if="isModal">
      <div class="modal-small" @click.stop>
        <h2 class="modal-small__h2">
          {{ $t("areYouOkOtmena") }}
        </h2>
        <p class="modal-small__p"></p>
        <button
          @click="handleChangeOrderStatus(history.id)"
          class="modal-small__btn bg-red">
          {{ $t("cancel") }}
        </button>
        <button @click="isModal = !isModal" class="modal-close">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 1.5L13.5 13.5M1.5 13.5L13.5 1.5" stroke="black" />
          </svg>
        </button>
      </div>
    </div>
    <div class="container">
      <p class="path">
        {{ $t("mainPage") }}/{{ $t("cabinet") }} / {{ $t("aboutZakaz") }}
      </p>
      <div class="mobile-cart__header">
        <button @click="page" class="mobile-arrow">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: rotate(180deg)">
            <path
              d="M5 1L12 7.5L5 14"
              stroke="#F9AB50"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </button>
        <h1 class="mobile-h1">{{ $t("aboutZakaz") }}</h1>
      </div>
      <div class="box">
        <h2 class="h2">{{ $t("aboutZakaz") }}</h2>
        <div class="history-title" v-if="history">
          <p class="history-title__left">
            Заказ № {{ history.number }} от
            {{ history.create_date.split("T")[0] }}
            {{ history.create_date.split("T")[1].split(":")[0] }}:{{
              history.create_date.split("T")[1].split(":")[1].split(":")[0]
            }}
          </p>
          <p class="history-title__right">
            {{ $t("delivery_address") }}: ул
            {{ history.delivery_address.street }}, д.
            {{ history.delivery_address.building }}
          </p>
        </div>
        <div class="history-title__loader" v-else></div>
        <div class="history-support">
          <p
            class="history-support__name"
            v-if="history.status === 'IN_CANCEL'">
            {{ $t("yourZakazOtmena") }}
          </p>
          <p
            class="history-support__name"
            v-if="history.status === 'COMPLETED'">
            {{ $t("yourZakazDid") }}
          </p>
          <p
            class="history-support__name"
            v-if="
              history.status === 'REFUND' ||
              history.status === 'PROBLEM' ||
              history.status === 'COURIER_RESERVER_REFUND' ||
              history.status === 'COURIER_WAREHOUSE_REFUND' ||
              history.status === 'RETURNED'
            ">
            {{ $t("yourZakazDid") }}
          </p>
          <div class="history-support__btns" v-if="!isMobile">
            <button
              v-if="
                history.status === 'CREATED' ||
                history.status === 'IN WORK' ||
                history.status === 'COLLECTED' ||
                history.status === 'COURIER_RESERVED' ||
                history.status === 'ON DELIVERY'
              "
              @click="isModal = !isModal"
              class="history-support__btn"
              style="background: #e76c6e">
              {{ $t("otmenaZakaz") }}
            </button>
            <button
              v-else-if="history.status === 'COMPLETED'"
              @click="$router.push('/return/' + $route.params.id)"
              class="history-support__btn">
              {{ $t("doVozvrat") }}
            </button>
            <button
              :disabled="disabled"
              @click="handleRepeatOrder"
              class="history-support__btn"
              style="background: #3ecd79">
              {{ $t("repeatOrder") }}
            </button>
            <router-link
              :to="'/profile/chat/' + $route.params.id"
              class="cabinet-support__chat__btn">
              Чат
            </router-link>
            <router-link
              :to="'/profile/chat/' + $route.params.id"
              class="history-support__btn"
              style="background: transparent">
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.8328 16.8333C18.2319 16.8333 19.3661 15.6991 19.3661 14.3V11.7666C19.3661 10.3675 18.2319 9.2333 16.8328 9.2333C16.1333 9.2333 15.5661 9.80041 15.5661 10.5V15.5666C15.5661 16.2662 16.1333 16.8333 16.8328 16.8333ZM16.8328 16.8333C16.8328 18.2324 15.6986 19.3666 14.2995 19.3666H11.1328M19.3661 13.6666V10.5C19.3661 5.60304 15.3964 1.6333 10.4995 1.6333C5.60255 1.6333 1.63281 5.60304 1.63281 10.5V13.6666M4.16615 16.8333C4.86571 16.8333 5.43281 16.2662 5.43281 15.5666V10.5C5.43281 9.80041 4.86571 9.2333 4.16615 9.2333C2.76702 9.2333 1.63281 10.3675 1.63281 11.7666V14.3C1.63281 15.6991 2.76702 16.8333 4.16615 16.8333Z"
                  stroke="#F9AB50"
                  stroke-width="2" />
              </svg>
              {{ $t("chatSupport") }}
            </router-link>
          </div>
        </div>
        <div class="history-order__loader" v-if="!history"></div>
        <div class="history-order" v-else>
          <div class="history-order__items">
            <div
              class="history-order__item"
              v-for="item in history.order_details"
              :key="item.id">
              <div class="history-order__left">
                <img
                  :src="item.product_image_url"
                  alt=""
                  class="cart-left__img" />
                <div class="mobile__width">
                  <p class="cart-left__name">
                    {{
                      $t("lang") === "ru"
                        ? item.product.name_rus
                        : item.product.name_kaz
                    }}
                  </p>
                  <p class="cart-left__mass">
                    {{
                      item.product.weight >= 1000
                        ? item.product.weight / 1000
                        : item.product.weight
                    }}
                    {{ item.product.weight >= 1000 ? "кг" : "г" }}
                  </p>
                  <div class="history-left__mobile" v-if="isMobile">
                    <div class="history-order__prices">
                      <p
                        class="history-left__old__price"
                        v-if="
                          item.product.discount_price !== item.product.price
                        ">
                        {{ item.product.price }} ₸
                      </p>
                      <p class="history-left__new__price">
                        {{ item.product.price }} ₸
                      </p>
                    </div>
                    <p class="history-left__count">{{ item.quantity }} шт.</p>
                    <p class="history-left__price">{{ item.summ }} ₸</p>
                  </div>
                </div>
              </div>
              <div class="history-order__right" v-if="!isMobile">
                <div
                  class="history-order__prices"
                  v-if="item.product.discount_price">
                  <p class="cart-left__old__price">
                    {{ item.product.price }} ₸ /шт
                  </p>
                  <p class="cart-left__new__price">
                    {{ item.product.discount_price }} ₸ /шт
                  </p>
                </div>
                <div class="history-order__prices" v-else>
                  <p class="cart-left__old__price">
                    {{ item.product.price }} ₸ /шт
                  </p>
                  <p class="cart-left__new__price">
                    {{ item.product.price }} ₸ /шт
                  </p>
                </div>
                <p class="history-order__count">{{ item.quantity }} шт</p>
                <p class="history-order__price">{{ item.summ }} ₸</p>
              </div>
            </div>
          </div>
          <div class="history-order__info" v-if="!isMobile">
            <p class="history-order__weight">
              {{ $t("orderWeight") }}

              :
              {{
                history.total_weight >= 1000
                  ? history.total_weight / 1000
                  : history.total_weight
              }}
              {{ history.total_weight >= 1000 ? "кг" : "г" }}
            </p>
            <p class="history-order__price">
              {{ $t("total_sum") }}: {{ history.total_sum }} ₸
            </p>
          </div>
          <div class="history-order__info__mobile" v-if="isMobile">
            <div class="history-order__text__mobile">
              <p>{{ $t("orderWeight") }}</p>
              <p>800 г</p>
            </div>
            <div class="history-order__text__mobile">
              <p>{{ $t("sumZakaz") }}</p>
              <p>{{ history.total_sum }} ₸</p>
            </div>
          </div>
        </div>
        <button
          @click="handleRepeatOrder"
          class="history-repeat__btn"
          v-if="isMobile">
          {{ $t("repeatOrder") }}
        </button>
      </div>
    </div>
    <router-link
      :to="'/profile/chat/' + $route.params.id"
      class="history-support__btn__mobile"
      v-if="isMobile">
      {{ $t("chatSupport") }}

      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5 1L12 7.5L5 14"
          stroke="#F7931E"
          stroke-width="2"
          stroke-linecap="round" />
      </svg>
    </router-link>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AppHistory",
  data() {
    return {
      progress: true,
      isMobile: false,
      disabled: false,
      isModal: false,
      history: "",
      lang: "Ru",
    };
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize, { passive: true });
  },
  methods: {
    page() {
      this.$router.go(-1);
    },
    resize() {
      if (window.innerWidth < 640) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    handleRepeatOrder() {
      this.disabled = true;
      api
        .resetToNew({ id: this.history.id })
        .then(() => {
          this.$router.push("/cart");
          this.disabled = false;
        })
        .catch((err) => {
          this.disabled = false;
          console.log(err);
        });
    },
    handleChangeOrderStatus(id) {
      api.changeOrderStatus({ id: id, status: "IN_CANCEL" }).then(() => {
        this.$router.push("/cabinet?tab=2");
      });
    },
  },
  created() {
    if (this.$route.params.id) {
      const id = parseInt(this.$route.params.id);
      api
        .getOrderById(id)
        .then((res) => {
          this.history = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>

<style>
.bg-red {
  background: #e76c6e !important;
}
.history-title {
  margin-top: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: #f2f2f2;
  border-radius: 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #3a4753;
}

.history-title__loader {
  margin: 10px 0;
  height: 50px;
  width: 100%;
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.1);
  background-color: #f2f2f2;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #f2f2f2;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
  border-radius: 15px;
}

.history-support {
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.history-support__btns {
  display: flex;
  align-items: center;
  column-gap: 28px;
}

.history-support__name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 170%;
  text-transform: uppercase;
  color: #3a4753;
}

.history-support__btn:first-child {
  padding: 9px 16px;
  background: #f9ab50;
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.history-support__btn:nth-child(2) {
  padding: 9px 16px;
  background: #3ecd79;
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.history-support__btn:last-child {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #f9ab50;
}

.history-order {
  padding: 20px 15px;
  background: #f7f7f7;
  border-radius: 6px;
}

.history-order__loader {
  margin: 10px 0;
  height: 170px;
  width: 100%;
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.1);
  background-color: #f2f2f2;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #f2f2f2;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
  border-radius: 6px;
}

.history-order__items {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-bottom: 18px;
  border-bottom: 2px solid #d9d9d9;
}

.history-order__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.history-order__left {
  display: flex;
  align-items: center;
  column-gap: 18px;
}

.history-order__right {
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: space-between;
}

.history-order__prices {
  display: flex;
  align-items: center;
  column-gap: 26px;
}

.history-order__count {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a4753;
}

.history-order__price {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a4753;
}

.history-order__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
}

.history-order__weight {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a4753;
}

.history-left__old__price {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  text-decoration-line: line-through;
  color: #979ea6;
}

.history-left__new__price {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: hsl(209, 18%, 28%);
}

.history-left__count {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #3a4753;
}

.history-left__price {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #3a4753;
}

.history-left__mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.modal-small {
  position: relative;
  width: 100%;
  max-width: 443px;
  margin: 0 auto;
  background: white;
  background: #ffffff;
  border-radius: 20px;
  padding: 66px 48px;
}

.modal-small__h2 {
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 170%;
  text-transform: uppercase;
  color: #3a4753;
}

.modal-small__p {
  margin: 24px 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 172.4%;
  text-align: center;
  color: #3a4753;
}

.modal-small__btn {
  margin: 0 auto;
  padding: 17px 63px;
  background: #3ecd79;
  border-radius: 100px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

@media screen and (min-width: 640px) and (max-width: 1024px) {
  .cart-right {
    width: 50%;
    margin: 0 auto;
  }
  .pl-50 {
    padding-left: 50px;
  }
}
@media screen and (min-width: 0px) and (max-width: 640px) {
  .modal-small {
    max-width: 100%;
    border-radius: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .cart-right {
    width: 100%;
    margin: 0 auto;
  }
  .pl-50 {
    padding-left: 25px;
  }
  .return-text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 148.4%;
    color: #979ea6;
  }
  .return-text__info {
    margin-top: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 148.4%;
    color: #3ecd79;
  }
}

@media screen and (min-width: 640px) and (max-width: 1024px) {
  .history-support__btns {
    column-gap: 10px;
  }
  .history-support {
    flex-wrap: wrap;
    gap: 15px;
  }
  .history-title {
    flex-wrap: wrap;
    gap: 5px;
  }
}
@media screen and (min-width: 0px) and (max-width: 640px) {
  .history-title {
    padding: 10px 16px;
    flex-wrap: wrap;
    gap: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    color: #37454e;
  }
  .history-support {
    margin: 16px 0;
  }
  .history-support__name {
    font-size: 17px;
  }
  .history-order {
    padding: 16px;
  }
  .history-order__prices {
    gap: 14px;
  }
  .history-order__left {
    width: 100%;
  }
  .mobile__width {
    width: 100%;
  }
  .cart-left__name {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 170%;
    text-transform: uppercase;
    color: #3a4753;
  }
  .history-left__mobile {
    margin-top: 7px;
  }
  .history-order__info__mobile {
    margin-top: 16px;
    margin-bottom: 18px;
  }
  .history-order__text__mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 148.4%;
    text-align: right;
    color: #3a4753;
  }
  .history-repeat__btn {
    width: fit-content;
    margin: 0 auto;
    margin-top: 32px;

    padding: 15px 30px;
    background: #3ecd79;
    border-radius: 25px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }

  .history-support__btn__mobile {
    margin-bottom: 50px;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    width: 100%;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #f7931e;
  }
}
</style>
