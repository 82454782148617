<!-- @format -->

<template>
  <div class="">
    <header class="header-faq">
      <div class="mobile-cart__header">
        <button @click="page" class="mobile-arrow">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: rotate(180deg)">
            <path
              d="M5 1L12 7.5L5 14"
              stroke="#F9AB50"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </button>
        <h1 class="mobile-h1">{{ $t("settings") }}</h1>
      </div>
    </header>
    <div class="container">
      <form @submit.prevent="handleSms" class="change" v-if="!isSms">
        <label for="phone" class="change-label">
          {{ $t("nowPhone") }}

          <input
            type="text"
            id="phone"
            class="change-input"
            placeholder="+7 | _ _ _  -  _ _  -  _ _"
            v-mask="'+7 (###) ###-##-##'"
            v-model="old_phone" />
        </label>
        <label for="phone" class="change-label">
          {{ $t("newPhone") }}

          <input
            type="text"
            id="phone"
            class="change-input"
            placeholder="+7 | _ _ _  -  _ _  -  _ _"
            v-mask="'+7 (###) ###-##-##'"
            v-model="new_phone" />
        </label>
        <button type="submit" class="change-btn">
          {{ $t("apply") }}
        </button>
        <span class="error" v-if="errorEqual" style="text-align: end">{{
          errorEqual
        }}</span>
        <span class="error" v-if="errorNumber" style="text-align: end">{{
          errorNumber
        }}</span>
      </form>
      <div class="cabinet-change__phone" v-if="isSms">
        <div class="login-forget">
          <div class="login-forget__texts">
            <p class="login-sms__text">{{ $t("noCode") }}</p>
            <button class="login-sms__timer" v-if="timer > 0">
              {{ $t("codeTimer") }}
              {{ timer }}
            </button>
            <button @click="handleSms" class="login-sms__timer" v-else>
              {{ $t("codeAgain") }}

              Отправить повторно
            </button>
          </div>
          <label for="sms" class="login-label login-forget__label">
            <input
              type="text"
              id="sms"
              :placeholder="$t('sms')"
              class="login-input"
              v-model="smsInput" />
          </label>
          <button @click="handleChange" class="login-sms__btn">
            {{ $t("confirm") }}
          </button>
          <span class="error" style="text-align: center">
            {{ smsFalse }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AppCabinetChange",
  data() {
    return {
      old_phone: "",
      new_phone: "",
      isSms: false,
      errorEqual: "",
      smsInput: "",
      smsFalse: "",
      timer: 0,
      errorNumber: "",
    };
  },
  created() {
    api.getUser().then((res) => {
      this.old_phone = res.data.username;
    });
  },
  watch: {
    new_phone: {
      handler() {
        this.errorEqual = "";
        this.errorNumber = "";
        this.smsFalse = "";
      },
    },
    timer: {
      handler() {
        if (this.timer > 0) {
          setTimeout(() => {
            this.timer--;
          }, 1000);
        }
      },
    },
  },
  methods: {
    page() {
      this.$router.go(-1);
    },
    handleSms() {
      if (this.old_phone === this.new_phone) {
        this.errorEqual = this.$t("newPhoneError");
      } else if (this.new_phone) {
        api.sendSMS(this.old_phone).then(() => {
          this.isSms = !this.isSms;
          this.timer = 60;
        });
      } else {
        this.errorEqual = this.$t("newPhoneError2");
      }
    },
    handleChange() {
      api
        .verifySMS({ phone: this.old_phone, code: this.smsInput })
        .then((res) => {
          if (res.data.success) {
            api
              .changeUsername({ new_phone: this.new_phone })
              .then(() => {
                this.$emit("reload");
                this.isSms = false;
              })
              .catch((err) => {
                this.isSms = false;
                if (err.response.data.error) {
                  this.errorNumber = err.response.data.error;
                }
              });
          } else {
            this.smsFalse = this.$t("codeError");
          }
        });
    },
  },
};
</script>

<style scoped>
.header-faq {
  background: #e2e7ee;
  padding: 15px;
  padding-top: 0px;
}

.change {
  padding: 13px 15px;
  border-radius: 10px;
  background: var(--light, #f2f2f2);
  margin-top: 30px;
}

.change-label {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--font, #3a4753);
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-top: 15px;
}

.change-label:first-child {
  margin-top: 0px;
}

.change-input {
  padding: 7px 10px;
  border-radius: 50px;
  background: #fff;
  color: #3a4753;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.change-input::placeholder {
  color: #8b8c92;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.change-btn {
  margin: 20px auto;
  display: block;
  border-radius: 50px;
  background: #f9ab50;
  padding: 4px 13px;
  color: #fff;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
</style>
