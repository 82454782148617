/** @format */

import Vue from "vue";
import VueRouter from "vue-router";
import Main from "./pages/Main.vue";
import Catalog from "./pages/Catalog.vue";
import Card from "./pages/Card.vue";
import Cart from "./pages/Cart.vue";
import Order from "./pages/Order.vue";
import Cabinet from "./pages/Cabinet.vue";
import History from "./pages/History.vue";
import Return from "./pages/Return.vue";
import AboutUs from "./pages/AboutUs.vue";
import Shops from "./pages/Shops.vue";
import Contacts from "./pages/Contacts.vue";
import AddCard from "./pages/AddCard.vue";
import Wishlist from "./pages/Wishlist.vue";
import Login from "./pages/Login.vue";
import Politics from "./pages/Politics.vue";
import Oferta from "./pages/Oferta.vue";
import ProfileInfo from "./pages/profile/Info.vue";
import ProfileReturn from "./pages/profile/Return.vue";
import ProfileAddress from "./pages/profile/Addresses.vue";
import ProfileHistory from "./pages/profile/History.vue";
import Chat from "./pages/Chat.vue";
import Support from "./pages/support/Index.vue";
import Faq from "./pages/support/Faq.vue";
import SubFaq from "./pages/support/Subfaq.vue";
import Settings from "./pages/support/Settings.vue";
import ChangeName from "@/pages/support/ChangeName";
import ChangePhone from "@/pages/support/ChangePhone";

Vue.use(VueRouter);

const routes = [
  { path: "/", component: Main },
  { path: "/catalog/:categoryId/:sub_categoryId", component: Catalog },
  { path: "/card/:id", component: Card },
  { path: "/cart", component: Cart },
  { path: "/order", component: Order },
  { path: "/cabinet", component: Cabinet },
  { path: "/history/:id", component: History },
  { path: "/return/:id", component: Return },
  { path: "/about", component: AboutUs },
  { path: "/shops", component: Shops },
  { path: "/contacts", component: Contacts },
  { path: "/addcard", component: AddCard },
  { path: "/wishlist", component: Wishlist },
  { path: "/login", component: Login },
  { path: "/politics", component: Politics },
  { path: "/oferta", component: Oferta },
  { path: "/profile/info", component: ProfileInfo },
  { path: "/profile/return", component: ProfileReturn },
  { path: "/profile/address", component: ProfileAddress },
  { path: "/profile/history", component: ProfileHistory },
  { path: "/profile/chat/:id", component: Chat },
  { path: "/support", component: Support },
  { path: "/support/faq/:id", component: Faq },
  { path: "/support/subfaq", component: SubFaq },
  { path: "/support/settings", component: Settings },
  { path: "/support/changename", component: ChangeName },
  { path: "/support/changephone", component: ChangePhone },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
