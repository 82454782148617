<!-- @format -->

<template>
  <div class="">
    <header class="header-faq">
      <p class="path">{{ $t("faq") }}</p>
      <div class="mobile-cart__header">
        <button @click="page" class="mobile-arrow">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: rotate(180deg)">
            <path
              d="M5 1L12 7.5L5 14"
              stroke="#F9AB50"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </button>
        <h1 class="mobile-h1">{{ $t("settings") }}</h1>
      </div>
    </header>
    <div class="container">
      <div class="settings-btns">
        <router-link to="/support/changename" class="settings-btn">
          {{ $t("yourName") }}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none">
            <path
              d="M9.70312 18L15.7493 12L9.70312 6"
              stroke="#3A4753"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </router-link>
        <router-link to="/support/changephone" class="settings-btn">
          {{ $t("mobPhone") }}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none">
            <path
              d="M9.70312 18L15.7493 12L9.70312 6"
              stroke="#3A4753"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </router-link>
        <button class="settings-btn">
          Email
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none">
            <path
              d="M9.70312 18L15.7493 12L9.70312 6"
              stroke="#3A4753"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </button>
      </div>
      <div class="settings-btns">
        <button @click="handleLeave" class="settings-btn font-600">
          {{ $t("leaveAccount") }}
        </button>
        <button class="settings-btn red">
          {{ $t("deleteAccount") }}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none">
            <path
              d="M9.70312 18L15.7493 12L9.70312 6"
              stroke="#3A4753"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppSubfaq",
  data() {
    return {};
  },
  created() {
    if (!this.$cookie.get("access")) {
      this.$router.push("/login");
    }
  },
  methods: {
    page() {
      this.$router.go(-1);
    },
    handleLeave() {
      if (confirm(this.$t("areYouLeave"))) {
        this.$cookie.delete("access");
        this.$cookie.delete("refresh");
        this.$router.push("/");
      }
    },
  },
};
</script>

<style scoped>
.header-faq {
  background: #e2e7ee;
  padding: 15px;
  padding-top: 0px;
}

.settings-btns {
  margin: 30px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.settings-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background: var(--light, #f2f2f2);
  padding: 10px 15px;
  color: var(--font, #3a4753);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.font-600 {
  font-weight: 600;
}

.red {
  color: #ff5943;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
</style>
