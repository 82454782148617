<!-- @format -->

<template>
  <div class="cabinet-support">
    <div class="cabinet-support__chat">
      <p class="cabinet-support__text">
        {{ $t("support_text") }}
      </p>
      <div class="cabinet-support__btns">
        <a :href="WhatsApp" class="cabinet-support__btn">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.003 0.75H11.997C5.7945 0.75 0.75 5.796 0.75 12C0.75 14.46 1.5435 16.7415 2.892 18.594L1.4895 22.7745L5.814 21.393C7.64905 22.6081 9.80212 23.2541 12.003 23.25C18.2055 23.25 23.25 18.204 23.25 12C23.25 5.796 18.2055 0.75 12.003 0.75Z"
              stroke="white"
              stroke-miterlimit="10" />
            <path
              d="M18.2657 16.3965C18.0077 17.1225 16.9788 17.7225 16.1583 17.898C15.5973 18.0165 14.8652 18.111 12.3977 17.097C9.24325 15.8025 7.21225 12.624 7.05475 12.4185C6.90475 12.2115 5.78125 10.7355 5.78125 9.20999C5.78125 7.68449 6.56425 6.93899 6.87925 6.62099C7.13725 6.35849 7.56625 6.23999 7.97725 6.23999C8.10925 6.23999 8.22925 6.24599 8.33725 6.25199C8.65225 6.26399 8.80975 6.28349 9.01825 6.77699C9.27625 7.39649 9.90775 8.92199 9.98275 9.07799C10.0577 9.23399 10.1343 9.44699 10.0263 9.65399C9.92575 9.86699 9.83725 9.95999 9.67975 10.1415C9.52225 10.323 9.37075 10.461 9.21325 10.6545C9.06775 10.824 8.90425 11.0055 9.08725 11.3175C9.27025 11.6235 9.90025 12.6435 10.8288 13.4625C12.0273 14.52 12.9993 14.8575 13.3458 15.0015C13.6038 15.108 13.9127 15.0825 14.1032 14.883C14.3432 14.6265 14.6402 14.202 14.9417 13.782C15.1562 13.482 15.4277 13.4445 15.7112 13.551C16.0007 13.6515 17.5337 14.4015 17.8502 14.5575C18.1652 14.7135 18.3738 14.7885 18.4503 14.9205C18.5238 15.051 18.5237 15.6705 18.2657 16.3965Z"
              stroke="white"
              stroke-miterlimit="10" />
          </svg>

          WhatsApp чат
        </a>
        <a :href="Telegram" class="cabinet-support__btn">
          <svg
            width="24"
            height="24"
            viewBox="0 0 15 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.5669 1.2998L1.43359 5.63314L4.90026 7.36647L10.1003 3.8998L6.63359 8.23314L11.8336 11.6998L13.5669 1.2998Z"
              stroke="white"
              stroke-linejoin="round" />
          </svg>

          Telegram чат
        </a>
      </div>
    </div>
    <button
      v-if="support.length > 0"
      @click="support = []"
      class="cabinet-support__back">
      <div class="cabinet-support__back__icon">
        <svg
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.24246 6.94787L11.5237 6.93864"
            stroke="#3A4753"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M6.59335 10.2702L3.24952 6.9482L6.58594 3.61816"
            stroke="#3A4753"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </div>
      {{ $t("back") }}
    </button>
    <div v-if="support.length === 0">
      <div
        class="cabinet-support__info"
        v-for="item in supports"
        :key="item.id">
        <div>
          <h2 class="cabinet-support__h2">{{ item.title }}</h2>
          <p class="cabinet-support__p" v-html="item.text"></p>
        </div>
        <button @click="support = item.subfaqs">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5 1L12 7.5L5 14"
              stroke="#3A4753"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </button>
      </div>
    </div>
    <div v-if="loader">
      <div v-for="i in 5" :key="i" class="cabinet-support__loader"></div>
    </div>
    <div>
      <div class="cabinet-support__info" v-for="one in support" :key="one.id">
        <div>
          <h2 class="cabinet-support__h2">{{ one.subtitle }}</h2>
          <p class="cabinet-support__p" v-html="one.text"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AppCabinetSupport",
  data() {
    return {
      id: 0,
      supports: [],
      support: [],
      WhatsApp: "",
      Telegram: "",
      loader: false,
    };
  },
  created() {
    api.getSupport().then((res) => {
      this.WhatsApp = res.data.results[0].whatsapp_link;
      this.Telegram = res.data.results[0].telegram_link;
    });
    this.loader = true;
    api.getQuestion().then((res) => {
      this.supports = res.data.results;
      this.loader = false;
    });
  },
};
</script>

<style>
.cabinet-support__chat {
  padding: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f2f2f2;
  border-radius: 20px;
}

.cabinet-support__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #3a4753;
}

.cabinet-support__btns {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.cabinet-support__btn {
  padding: 10px 37px;
  background: #3ecd79;
  border-radius: 10px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.cabinet-support__chat__btn {
  height: 100%;
  padding: 9px 37px;
  border-radius: 10px;
  border: 1px solid #3ecd79;
  color: #3ecd79;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all 150ms;
}

.cabinet-support__chat__btn:hover {
  background: #3ecd79;
  color: white;
}

.cabinet-support__btn:last-child {
  background: #75c2e7;
}

.cabinet-support__info {
  margin: 20px 0;
  width: 100%;
  padding: 22px 27px;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation: opacity 500ms ease-in-out;
}

.cabinet-support__h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3ecd79;
}

.cabinet-support__p {
  margin-top: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 172.4%;
  color: #3a4753;
}

.cabinet-support__back {
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-top: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 170%;
  color: #3a4753;
}

.cabinet-support__back__icon {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.15);
  border-radius: 10px;
}

.cabinet-support__loader {
  margin: 20px 0;
  height: 110px;
  width: 100%;
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.1);
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
  border-radius: 20px;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
