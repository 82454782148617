var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"mobile-footer"},[_c('router-link',{class:_vm.$route.path === '/cabinet'
        ? 'mobile-footer__btn__active'
        : _vm.$route.path.includes('profile')
        ? 'mobile-footer__btn__active'
        : _vm.$route.path === '/login'
        ? 'mobile-footer__btn__active'
        : 'mobile-footer__btn',attrs:{"to":"/cabinet"}},[_c('svg',{attrs:{"width":"26","height":"20","viewBox":"0 0 26 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M22.8182 1H3.18182C1.97683 1 1 1.97683 1 3.18182V16.2727C1 17.4777 1.97683 18.4545 3.18182 18.4545H22.8182C24.0232 18.4545 25 17.4777 25 16.2727V3.18182C25 1.97683 24.0232 1 22.8182 1Z","stroke":_vm.$route.path === '/cabinet'
            ? '#3ECD79'
            : _vm.$route.path.includes('profile')
            ? '#3ECD79'
            : _vm.$route.path === '/login'
            ? '#3ECD79'
            : '#3A4753',"stroke-width":"1.75","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M1 7H25","stroke":_vm.$route.path === '/cabinet'
            ? '#3ECD79'
            : _vm.$route.path.includes('profile')
            ? '#3ECD79'
            : _vm.$route.path === '/login'
            ? '#3ECD79'
            : '#3A4753',"stroke-width":"1.75","stroke-linecap":"round","stroke-linejoin":"round"}})]),_vm._v(" Профиль ")]),_c('router-link',{class:_vm.$route.path === '/'
        ? 'mobile-footer__btn__active'
        : 'mobile-footer__btn',attrs:{"to":"/"}},[_c('svg',{attrs:{"width":"20","height":"21","viewBox":"0 0 20 21","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M1 5C1 2.37479 1.02811 1.5 4.5 1.5C7.97189 1.5 8 2.37479 8 5C8 7.62521 8.01107 8.5 4.5 8.5C0.988927 8.5 1 7.62521 1 5Z","stroke":_vm.$route.path === '/' ? '#3ECD79' : '#3A4753',"stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M12 5C12 2.37479 12.0281 1.5 15.5 1.5C18.9719 1.5 19 2.37479 19 5C19 7.62521 19.0111 8.5 15.5 8.5C11.9889 8.5 12 7.62521 12 5Z","stroke":_vm.$route.path === '/' ? '#3ECD79' : '#3A4753',"stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M1 16C1 13.3748 1.02811 12.5 4.5 12.5C7.97189 12.5 8 13.3748 8 16C8 18.6252 8.01107 19.5 4.5 19.5C0.988927 19.5 1 18.6252 1 16Z","stroke":_vm.$route.path === '/' ? '#3ECD79' : '#3A4753',"stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M12 16C12 13.3748 12.0281 12.5 15.5 12.5C18.9719 12.5 19 13.3748 19 16C19 18.6252 19.0111 19.5 15.5 19.5C11.9889 19.5 12 18.6252 12 16Z","stroke":_vm.$route.path === '/' ? '#3ECD79' : '#3A4753',"stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_vm._v(" Каталог ")]),_c('router-link',{class:_vm.$route.path === '/shops'
        ? 'mobile-footer__btn__active'
        : 'mobile-footer__btn',attrs:{"to":"/shops"}},[_c('svg',{attrs:{"width":"29","height":"24","viewBox":"0 0 29 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M2.27381 23H25.8452M4.63095 12V23M23.4881 12V23M3.84524 1H24.2738M7.77381 12V14.7143C7.77381 15.8189 8.66924 16.7143 9.77381 16.7143H18.3452C19.4498 16.7143 20.3452 15.8189 20.3452 14.7143V12M4.63095 4.14286H23.4881L27.1743 8.75061C28.2219 10.0601 27.2896 12 25.6126 12H2.89391C1.26573 12 0.319828 10.1584 1.26828 8.83496L4.63095 4.14286Z","stroke":_vm.$route.path === '/shops' ? '#3ECD79' : '#3A4753',"stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})]),_vm._v(" "+_vm._s(_vm.$t("footer.shops"))+" ")]),_c('router-link',{class:_vm.$route.path.includes('support')
        ? 'mobile-footer__btn__active'
        : 'mobile-footer__btn',attrs:{"to":"/support"}},[_c('svg',{attrs:{"width":"28","height":"28","viewBox":"0 0 28 28","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M23.0151 23.8543C22.4834 22.3659 21.3116 21.0506 19.6816 20.1125C18.0516 19.1745 16.0544 18.666 13.9998 18.666C11.9453 18.666 9.94808 19.1745 8.31807 20.1125C6.68805 21.0506 5.5163 22.3658 4.98453 23.8543","stroke":_vm.$route.path.includes('support') ? '#3ECD79' : '#3A4753',"stroke-width":"2","stroke-linecap":"round"}}),_c('circle',{attrs:{"cx":"14.0002","cy":"9.33268","r":"4.66667","stroke":_vm.$route.path.includes('support') ? '#3ECD79' : '#3A4753',"stroke-width":"2","stroke-linecap":"round"}})]),_vm._v(" "+_vm._s(_vm.$t("support"))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }