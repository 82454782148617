<template>
  <section class="container">
    <div class="category" v-if="!items">
      <div v-for="i in 4" :key="i" class="category-card__loader"></div>
    </div>

    <div class="category" v-else>
      <router-link
        :to="'/catalog/' + item.id + '/1'"
        class="category-card"
        v-for="item in items"
        :key="item.id"
      >
        <p class="category-card__name">
          {{ $t("lang") === "kz" ? item.name_kaz : item.name_rus }}
        </p>

        <img :src="item.category_image" alt="" class="category-card__img" />
      </router-link>
    </div>
  </section>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AppMobileCategory",
  data() {
    return {
      items: "",
    };
  },
  created() {
    api.getCategory().then((res) => {
      this.items = res.data.results;
    });
  },
};
</script>

<style>
.category {
  margin-top: 24px;
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}
.category-card {
  grid-column: span 1;
  height: 100px;
  position: relative;
}
.category-card__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}
.category-card__name {
  position: absolute;
  top: 8px;
  left: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3a4753;
  width: 50%;
  text-align: start;
}

.category-card__loader {
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.1);
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
  border-radius: 6px;
  grid-column: span 1;
  height: 100px;
  position: relative;
}

@media screen and (max-width: 640px) {
  .category-card__name {
    width: 79%;
  }
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
</style>
