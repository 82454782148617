<!-- @format -->

<template>
  <div>
    <div class="container">
      <div class="mobile-cart__header">
        <button @click="page" class="mobile-arrow">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: rotate(180deg)">
            <path
              d="M5 1L12 7.5L5 14"
              stroke="#F9AB50"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </button>
        <h1 class="mobile-h1">{{ $t("footer.shops") }}</h1>
      </div>
      <div class="mobile-shops" v-if="isMobile">
        <div class="mobile-shops__tabs">
          <input
            type="radio"
            name="tabs"
            id="list"
            style="display: none; width: 0" />
          <label @click="tab = 1" for="list" class="mobile-shops__tab">
            {{ $t("spisok") }}
          </label>
          <input
            type="radio"
            name="tabs"
            id="map"
            style="display: none; width: 0"
            checked />
          <label @click="tab = 2" for="map" class="mobile-shops__tab">
            {{ $t("onMap") }}
          </label>
        </div>
        <input
          v-if="tab === 1"
          type="text"
          :placeholder="$t('findAddress')"
          class="mobile-shops__input"
          v-model="search" />
      </div>
      <div v-if="isMobile && tab === 1">
        <div class="mobile-shops__address" v-for="shop in shops" :key="shop.id">
          <h4 class="mobile-shops__name">{{ shop.name }}</h4>
          <div class="mobile-shops__text">
            <svg
              width="16"
              height="16"
              viewBox="0 0 19 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.5 11.8571C11.064 11.8571 12.3333 10.6411 12.3333 9.14282C12.3333 7.64454 11.064 6.42855 9.5 6.42855C7.936 6.42855 6.66667 7.64454 6.66667 9.14282C6.66667 10.6411 7.936 11.8571 9.5 11.8571Z"
                stroke="#F9AB50"
                stroke-width="2"
                stroke-linecap="square" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18 9.14282C18 15.9285 10.9167 20 9.5 20C8.08333 20 1 15.9285 1 9.14282C1 4.64661 4.80658 1 9.5 1C14.1934 1 18 4.64661 18 9.14282Z"
                stroke="#F9AB50"
                stroke-width="2"
                stroke-linecap="square" />
            </svg>

            ул. {{ shop.address.street }}, д. {{ shop.address.building }}
          </div>
          <div class="mobile-shops__text">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.5 4.14286V8L11.2857 10.5714M8.5 14C4.91015 14 2 11.3137 2 8C2 4.68629 4.91015 2 8.5 2C12.0899 2 15 4.68629 15 8C15 11.3137 12.0899 14 8.5 14Z"
                stroke="#3ECD79"
                stroke-width="2"
                stroke-linejoin="round" />
            </svg>

            {{ shop.start_time.split("T")[1].split("+")[0].split(":")[0] }}:{{
              shop.start_time.split("T")[1].split("+")[0].split(":")[1]
            }}
            - {{ shop.end_time.split("T")[1].split("+")[0].split(":")[0] }}:{{
              shop.end_time.split("T")[1].split("+")[0].split(":")[1]
            }}
          </div>
        </div>
      </div>
    </div>
    <div
      :style="
        tab === 1 ? 'display:none' : 'position: relative; overflow: hidden;'
      ">
      <Map :shops="shops" @search="handleSearch" />
    </div>
  </div>
</template>

<script>
import Map from "@/components/map/Map.vue";
import api from "@/api/api";

export default {
  components: { Map },
  name: "AppShops",
  data() {
    return {
      search: "",
      isMobile: false,
      coords: [43.2567, 76.9286],
      tab: 2,
      icon: {
        layout: "default#imageWithContent",
        imageHref: "http://192.168.0.135:8080/Pin_fill.svg",
        imageSize: [43, 43],
        imageOffset: [0, 0],
      },
      shops: "",
    };
  },
  created() {
    api.getMarket().then((res) => {
      this.shops = res.data.results;
    });
  },
  watch: {
    search: {
      handler() {
        this.handleSearch(this.search);
      },
    },
  },
  methods: {
    handleSearch(e) {
      api.getMarketSearch(e).then((res) => {
        this.shops = res.data.results;
      });
    },
    page() {
      this.$router.go(-1);
    },
    resize() {
      if (window.innerWidth < 640) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize, { passive: true });
  },
};
</script>

<style>
.ymap-container {
  width: 100%;
  height: 80vh;
}

.ymaps-2-1-79-map-copyrights-promo {
  display: none !important;
}

.ymaps-2-1-79-controls__control {
  display: none;
}

.ymaps-2-1-79-controls__toolbar_right {
  display: none;
}

.shops {
  position: absolute;
  top: 0;
  left: 10%;
  width: 100%;
  max-width: 386px;
  padding: 20px 16px;
  overflow-y: scroll;
  background: white;
  height: 100%;
}

.shops-search {
  background: #f2f2f2;
  border-radius: 30px;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  padding: 0px 20px;
}

.shops-search__input {
  width: 100%;
  background: #f2f2f2;
  padding: 13px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
}

.shops-search__input::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #979ea6;
}

.shops-select {
  padding: 13px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f2f2f2;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 16px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
}

.shops-address {
  margin: 28px 0;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.shops-address__city {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
}

.shops-address__info {
  display: flex;
  align-items: center;
  column-gap: 10px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
}

.shops::-webkit-scrollbar {
  width: 4px;
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.1);
}

/* Track */
.shops::-webkit-scrollbar-track {
  background: #d9d9d9;
}

/* Handle */
.shops::-webkit-scrollbar-thumb {
  background: #3ecd79;
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.1);
  border-radius: 3px;
  transition: all;
  transition-duration: 200ms;
}

/* Handle on hover */
.shops::-webkit-scrollbar-thumb:hover {
  background: #3ecd79;
  box-shadow: 0px 4px 20px rgba(105, 129, 134, 0.1);
  border-radius: 3px;
}

.ymaps-2-1-79-controls-pane {
  display: none;
}

.mobile-shops__tabs {
  display: flex;
  align-items: center;
  width: 100%;
  background: #eeeef0;
  border-radius: 10px;
  padding: 2px;
}

.mobile-shops__tab {
  width: 50%;
  text-align: center;
  padding: 6px 0;
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #3a4753;
  transition: all;
  transition-duration: 500ms;
}

.mobile-shops__tabs input[type="radio"]:checked + label {
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.mobile-shops {
  margin-top: 35px;
  margin-bottom: 12px;
}

.mobile-shops__tabs {
  margin-bottom: 20px;
}

.mobile-shops__input {
  width: 100%;
  text-align: start;
  padding: 12px 15px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3a4753;
}

.mobile-shops__input::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(58, 71, 83, 0.5);
}

.mobile-shops-select {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d9d9d9;
  padding: 12px 15px;

  border-radius: 10px;
}

.mobile-shops__address {
  margin-top: 16px;
  padding-bottom: 18px;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.mobile-shops__status {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #f7931e;
}

.mobile-shops__text {
  display: flex;
  align-items: center;
  column-gap: 7px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #979ea6;
}

.mobile-shops__name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 170%;
  text-transform: uppercase;
  color: #3a4753;
}

@media screen and (min-width: 640px) and (max-width: 1024px) {
  .shops {
    max-width: fit-content;
    left: 0;
  }
}
</style>
